import React, { useState } from "react";
import { Button, Dropdown, Tooltip, message } from "antd";
import axios from "axios";
import { CopyOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { copyToClipboard } from "../Components/Functions";
import { TbFileExport } from "react-icons/tb";
import { exportAsFile } from "../../Components/ExportToExcel";

const CoptToClipBoardInward = ({ stockTransferId, box_no, showExtraTag }) => {
    const [loading, setLoading] = useState(false);

    const CopyData = async (type="copy") => {
        setLoading(true);
        axios
            .get(`/reports/copy_product_code_found_qty_data`, {
                params: {
                    st_id: stockTransferId,
                    box_no: box_no,
                    to_show_extra_tags: showExtraTag
                },
            })
            .then((res) => {
                var ToCopy = "";
                res?.data?.data?.forEach((item) => {
                    var line = item.product_code + "," + item.found_qty + "\n";
                    ToCopy += line;
                });

                if(type === "copy") {
                    copyToClipboard(ToCopy);
                    message.success("Copied to clipboard");
                }
                else if(type === "csv") {
                    exportAsFile({
                        fileName: `export-${box_no}`,
                        text: ToCopy,
                        extension: "csv",
                    })
                    message.success("Exported to CSV");
                }
                else if(type === "txt") {
                    exportAsFile({
                        fileName: `export-${box_no}`,
                        text: ToCopy,
                        extension: "txt",
                    })
                    message.success("Exported to TXT");
                }
            })
            .catch((error) => {
                console.log(error);
            });
        setLoading(false);
    };

    return (
        <Dropdown
            placement="bottomLeft"
            menu={{
                items: [
                    {
                        key: '1',
                        label: <span> <CopyOutlined /> Copy</span>,
                        onClick: () => { CopyData("copy")}
                    },
                    {
                        key: '2',
                        label: <span> <TbFileExport /> Export CSV</span>,
                        onClick: () => { CopyData("csv")}
                    },
                    {
                        key: '3',
                        label: <span> <TbFileExport /> Export TXT</span>,
                        onClick: () => { CopyData("txt")}
                    }
                ]
            }}
        >
            <Button type='primary' size='small' loading={loading} disabled={loading}>
                Copy
            </Button>
        </Dropdown>
    );
};
export default CoptToClipBoardInward;
