import React, { useEffect, useMemo, useState } from 'react'
import useFilterOptions from '../../Components/useFilterOptions';
import axios from 'axios';
import { Table, Tabs, Tag, Tooltip, message } from 'antd';
import { MdContentCopy } from 'react-icons/md';
import { copyToClipboard } from '../Components/Functions';
import SearchComponent from '../../Masters/GeneralComponents/SearchComponent';
import { ExportToExcelButton } from '../../Components/ExportToExcel';
import { Pie } from '@ant-design/charts';
import { ProductCodeComponent } from '../Components/ProductCodeDetailsModal';
import ReplenishmentReportV2 from './ReplenishmentReportV2';
import DisplayInformation, { ReplenishmentHelpContent } from './DisplayInformation';
const { TabPane } = Tabs;

export default function ReplenishmentReport({
    selectedOrg={},
    sessionId
}) {
    // Below is the main state that will hold all the data from API
    const [masterState, setMasterState] = useState([]);

    // Below satate will hold the data filtered after `Our Search Component (SearchComponent)`
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Below state is for the current data shown after filtering and sorting (Exported from Antd Table)
    // (for excel export)
    const [currentTableData, setCurrentTableData] = useState([]);

    const filterableColumns = useMemo(() =>
        // TODO: Change this
        ['section', 'department', 'option', 'color', 'size', 'status']
    ,[]);
    const [filterOptions, setFilterOptions] = useFilterOptions(masterState, filterableColumns);

    const getData = async () => {
        setLoading(true);
        await axios.get(`/admin-api/reports/stock_take/broken_report`, {
            params: {
                session_id: sessionId,
                o_id: selectedOrg?.orgId,
            }
        })
            .then(res => {
                const data = res.data.data;

                setData(data);
                setMasterState(data);
                setCurrentTableData(data);        // Initialising the current table data to the data we got from the API
            })
            .catch(err => {
                // setFilterOptions({});
                setData([]);
                setCurrentTableData([]);

                console.log(err);
                message.error('Error Replenishment data');
            })
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, [sessionId]);

    const columns = useMemo(() => [
        {
            title: "Section",
            dataIndex: "section",
            key: "section",
            filters: filterOptions.section,
            onFilter: (value, record) => record.section === value,
            filterSearch: true,
        },
        {
            title: "Department",
            dataIndex: "department",
            key: "department",
            filters: filterOptions.department,
            onFilter: (value, record) => record.department === value,
            filterSearch: true,
        },
        {
            title: "Option",
            dataIndex: "option",
            key: "option",
            filters: filterOptions.option,
            onFilter: (value, record) => record.option === value,
            filterSearch: true,
        },
        {
            title: "Color",
            dataIndex: "color",
            key: "color",
            filters: filterOptions.color,
            onFilter: (value, record) => record.color === value,
            filterSearch: true,
        },
        {
            title: "Product Code",
            dataIndex: "product_code",
            key: "product_code",
            render: (text, record) => <ProductCodeComponent productCode={text} />,
        },
        {
            title: "Size",
            dataIndex: "size",
            key: "size",
            filters: filterOptions.size,
            onFilter: (value, record) => record.size === value,
            filterSearch: true,
        },
        {
            title: "Size Curve",
            dataIndex: "size_curve",
            key: "size_curve",
        },
        {
            title: "Found Sizes",
            dataIndex: "found_sizes",
            key: "found_sizes",
        },
        {
            title: "Qty",
            dataIndex: "qty",
            key: "qty",
        },
        {
            title: "Ratio",
            dataIndex: "ratio",
            key: "ratio",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            filters: filterOptions.status,
            onFilter: (value, record) => record.status === value,
            filterSearch: true,
            render: (text, record) => {


                return (
                    <Tag color={colorMap[text]} key={text}>
                        {text?.toUpperCase()}
                    </Tag>
                )
            }
        },
    ], [filterOptions]);

    // Makeing a pie chart with status as the key and count as the value
    const pieChartData = useMemo(() => {
        const statusCount = {
            "Shop Display": {name: "Shop Display", value: 0},
            "Shop Broken": {name: "Shop Broken", value: 0},
            "Back Display": {name: "Back Display", value: 0},
            "Back Broken": {name: "Back Broken", value: 0},
        };
        masterState.forEach(item => {
            if (!statusCount[item.status]) {
                statusCount[item.status] = {
                    name: item.status,
                    value: 0,
                }
            }
            statusCount[item.status].value += 1;
        })

        return Object.values(statusCount);
    }, [masterState]);

    return (
        <div>
            <Tabs
                tabPosition='left'
                size='small'
                style={{padding: "0px"}}
            >
                <TabPane tab="Advice" key="1">
                    <ReplenishmentReportV2
                        selectedOrg={selectedOrg}
                        sessionId={sessionId}
                    />
                </TabPane>
                <TabPane tab="Table" key="2">
                    <div className='my-form-outer'>
                        <div className='my-form-header'>
                            <ExportToExcelButton
                                buttonLoading={loading}
                                buttonDisabled={data.length === 0 || loading}
                                fileName={`Replenishment-Report-${sessionId}`}
                                sheets={[
                                    {sheetName: 'Replenishment Report', data: currentTableData, columns: columns},
                                ]}
                            />
                            <span className="actions-outer-div">
                                <SearchComponent
                                    masterState={masterState}
                                    state={data}
                                    setState={setData}
                                    searchOptions={[
                                        {keyName: 'product_code', label: 'Product Code'},
                                    ]}
                                    searchDebounceTime={500}
                                />
                                <DisplayInformation>
                                    <ReplenishmentHelpContent />
                                </DisplayInformation>
                            </span>

                        </div>
                        <Table
                            dataSource={data}
                            columns={columns}
                            loading={loading}
                            size="small"
                            pagination={{
                                position: ['bottomRight'],
                                // position: ['topRight'],
                                showSizeChanger: true,
                                defaultPageSize: 10,
                            }}
                            onChange={(pagination, filters, sorter, extra) => {
                                // console.log('params', pagination, filters, sorter, extra);
                                setCurrentTableData(extra.currentDataSource);
                            }}
                        />
                    </div>
                </TabPane>
                <TabPane tab="Chart" key="3">
                    <div style={{position: 'relative'}}>
                        <div style={{position: 'absolute', top: '0%', right: '2%', zIndex: 100}}>
                            <DisplayInformation>
                                <ReplenishmentHelpContent />
                            </DisplayInformation>
                        </div>
                        <Pie
                            data={pieChartData || []}
                            angleField="value"
                            colorField="name"
                            loading={loading}
                            label={{
                                content: ({ name, percent, value }) => `${name} (${value})             ${(percent * 100)?.toFixed(2)}%`,
                                type: 'spider',
                                labelHeight: 40,
                                labelLineHeight: 30,
                            }}
                            appendPadding={30}
                            interactions={[{ type: 'element-selected' }, { type: 'element-active' }, { type: 'pie-legend-active' }]}

                            color={(d) => {
                                console.log(d);
                                return colorMap[d.name];
                            }}
                        />
                    </div>
                </TabPane>
            </Tabs>
        </div>
    )
}

const colorMap = {
    "Shop Display": "green",
    "Shop Broken": "red",
    "Back Display": "green",
    "Back Broken": "blue",
}
