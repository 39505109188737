import React, { useEffect, useMemo, useState } from 'react'
import { InputWithLabel } from '../../Components/Components';
import SiteTreeSelectAuto from '../Components/SiteTreeSelectAuto';
import { Button, Checkbox, DatePicker, Descriptions, Divider, Input, Modal, Popconfirm, Select, Space, Table, Tag, Typography, message } from 'antd';
import SearchComponent from '../../Masters/GeneralComponents/SearchComponent';
import useFilterOptions from '../../Components/useFilterOptions';
import moment from 'moment';
import { capitalizeFirstLetter } from '../Components/Functions';
import axios from 'axios';
import FileSaver, { saveAs } from 'file-saver';
import SiteTreeMultiSelect from '../Components/SiteTreeMultiSelect';
import { MdDeleteOutline, MdFileDownloadOff, MdOutlineDownload } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";
import { HiOutlineRefresh } from "react-icons/hi";
import { useSelector } from 'react-redux';

export default function AsynchronousReports({ selectedOrg = {}, permissions = {} }) {

    const [selected, setSelected] = useState({
        date: moment(),
        site_id: 0,
        sites: []
    });

    console.log("Permisson : ", permissions);

    const filterableColumns = useMemo(() =>
        ['status'], []);

    const [visible, setVisible] = useState(false)
    const [reportType, setReportType] = useState([])
    const [reportName, setReportName] = useState(null)
    const [reportData, setReportData] = useState([])
    const [reportCode, setReportCode] = useState(null)
    const [asyReportId, setAsyReportId] = useState(null)
    const [data, setData] = useState({});
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(true);
    const [dateFilter, setDateFilter] = useState(false);
    const [singleSiteFilter, setSingleSiteFilter] = useState(false);
    const [multiSiteFilter, setMultiSiteFilter] = useState(false);
    const [masterState, setMasterState] = useState([]);
    const [filterOptions, setFilterOptions] = useFilterOptions(masterState, filterableColumns);
    const authReducer = useSelector((state) => state.authReducer);
    const [error, setError] = useState({
        reportName: false,
        date: false,
    })

    const setSelectedKey = (key, value) => {
        setSelected(s => ({
            ...s,
            [key]: value,
        }));
    }


    const getData = async () => {
        await axios.get('/async_worker/get_report_codes')
            .then(res => {
                const data = res.data.data;
                setData(data);
                let reportMap = {};
                for (var report of data) {
                    reportMap[report.asy_report_id] = {
                        "value": report.report_code,
                        "label": report.report_code
                    }
                }
                setReportType(Object.values(reportMap))
            })
            .catch(err => {
                setData({});
                console.log(err);
                message.error('Error fetching data');
            })
    }

    async function getAllReportData() {
        setLoading(true);
        await axios.get('/async_worker/get_all_report_txn').then(res => {
            const data = res.data.data
            setReportData(data)
            setMasterState(data);
        }).catch(err => {
            setData({});
            setMasterState([]);
            console.log(err);
            message.error('Error  occuring to create report');
        })
        setLoading(false);
    }

    async function deleteAsynReport(id) {
        setLoading(true);
        await axios.get(`/async_worker/delete_async_report_by_txn_id?asy_txn_id=${id}`)
            .then(res => {
                message.success('Report delete successfully');
                getAllReportData()
            }).catch(err => {
                if (err.response) {
                    message.error(err.response?.data?.detail);
                } else {
                    message.error(err?.message);
                }
            })
    }

    async function renameAndRecreateReport(updateData) {
        setLoading(true);
        await axios.post(`/async_worker/edit_report`, { ...updateData })
            .then(res => {
                message.success('Edit report successfully');
                getAllReportData()
            }).catch(err => {
                if (err.response) {
                    message.error(err.response?.data?.detail);
                } else {
                    message.error(err?.message);
                }
            })
    }

    async function createReport() {

        if (reportName === null || reportName === undefined) {
            message.error("Please provide a report name !!")
            setError({
                reportName: true,
                date: false
            })
            return
        }

        if (singleSiteFilter) {
            if (filters?.site_id === undefined) {
                message.error("Please select a site !!")
                return
            }
        }

        if (dateFilter) {
            if (filters?.date === undefined) {
                message.error("Please select date !!")
                setError({
                    reportName: false,
                    date: true
                })
                return
            }
        }

        if (multiSiteFilter) {
            if (filters?.sites === undefined) {
                message.error("Please select a site !!")
                return
            }
        }

        setError({
            reportName: false,
            date: false
        })

        const dataJSON = {
            report_name: reportName,
            report_code: reportCode,
            asy_report_id: asyReportId,
            filters: filters
        }

        await axios.post('/async_worker/create_report', { ...dataJSON }).then(res => {
            message.success("Report Created !!")
            showReport()
            getAllReportData()
            setFilters({})
            setReportName(null)
            setReportCode(null)
            setAsyReportId(null)
        }).catch(err => {
            message.error('Error  occuring to create report');
        })
    }

    useEffect(() => {
        getData()
        getAllReportData()
    }, [])

    async function downloadFile(id) {

        try {
            const encodedId = encodeURIComponent(id);
            const url = `/async_worker/get_report_txn/${encodedId}`;
            const response = await axios.get(url, {
                responseType: 'blob',
            });

            const contentDisposition = response.headers['content-disposition'];
            let fileName = 'file.txt';
            if (contentDisposition) {
                const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    fileName = matches[1].replace(/['"]/g, '');
                }
            }
            saveAs(response.data, fileName);
        } catch (err) {
            console.log(err);
            message.error('Failed to download');
        }

    }

    function showReport() {
        setVisible(!visible)
        setDateFilter(false)
        setSingleSiteFilter(false)
        setMultiSiteFilter(false)
        setFilters({})
        setError({
            reportName: false,
            date: false
        })
        setReportCode(null)
    }

    const searchReportCode = (reportCode) => {
        setDateFilter(false)
        setSingleSiteFilter(false)
        setMultiSiteFilter(false)
        const report = data.find((item) => item.report_code === reportCode);
        if (report) {
            const filterRequired = report.filter_required;
            const keys = Object.keys(filterRequired);
            setReportCode(reportCode)
            setAsyReportId(report.asy_report_id)
            const newObj = {};
            for (const key of keys) {
                newObj[key] = undefined;
            }
            setFilters(newObj)
            for (var key of keys) {
                if (key === "date") setDateFilter(true)
                else if (key === "site_id") setSingleSiteFilter(true)
                else if (key === "sites") setMultiSiteFilter(true)
            }
        }
    }

    const [modal, contextHolder] = Modal.useModal();

    const renameReport = (record) => {
        const updateData = {
            "report_name": record.report_name,
            "asy_txn_id": record.asy_txn_id,
            "is_recreate": false
        }
        modal.confirm({
            title: 'Rename Report',
            content: (
                <>
                    <Space direction='vertical'>
                        <Input placeholder="Report name"
                            onError={"error"}
                            defaultValue={record.report_name}
                            onChange={(e) => { updateData.report_name = e.target.value }}
                            allowClear />
                        <Checkbox
                            onChange={(e) => { updateData.is_recreate = e.target.checked }}
                        >Recreate Report</Checkbox>
                    </Space>
                    <br />
                </>
            ),
            okText: 'Confirm',
            onOk: () => {
                if (updateData?.report_name.length <= 0) {
                    message.error("Please provide a report name")
                    return
                }
                renameAndRecreateReport(updateData)
            },
        });
    };

    const statusColorMap = { pending: "#cf1322", processing: "#faad14", completed: "#389e0d", error: 'red' }

    const columns = useMemo(() => [
        {
            title: 'Report ID',
            dataIndex: 'asy_txn_id',
            key: 'asy_txn_id',
        },
        {
            title: 'Report Name',
            dataIndex: 'report_name',
            key: 'report_name'
        },
        {
            title: 'Report Type',
            dataIndex: 'report_code',
            key: 'report_code'
        },
        {
            title: 'Created By',
            dataIndex: 'username',
            key: 'username'
        },
        {
            title: 'Created Date',
            dataIndex: 'created_time',
            key: 'created_time',
            render: (text, record) => moment(text).format('Do MMM YYYY, HH:mm A'),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            filters: filterOptions.status,
            render: (text, record) => (
                <Tag
                    color={statusColorMap[text] || "#000000"}
                    key={record.status}
                    style={{ fontWeight: '700' }}
                >
                    {text?.toUpperCase()}
                </Tag>
            ),
            onFilter: (value, record) => record.status == value,
            filterSearch: true,
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'action',
            render: (text, record) => (
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-evenly'
                }}>
                    {permissions?.edit && <AiOutlineEdit
                        onClick={() => {
                            renameReport(record)
                        }}
                        style={{ cursor: 'pointer' }}
                        color='#232D3F' size={24} />}

                    {permissions?.export && <div>
                        {record.status !== "completed" ?
                            <MdFileDownloadOff style={{ cursor: 'not-allowed' }} color='grey' size={24} /> :
                            <MdOutlineDownload style={{ cursor: 'pointer' }} onClick={() => downloadFile(record.asy_txn_id)} color='#232D3F' size={24} />}
                    </div>}

                    {permissions?.delete ? <Popconfirm
                        placement='left'
                        title="Delete the report"
                        description="Are you sure to delete this report?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteAsynReport(record.asy_txn_id)}
                    >
                        <MdDeleteOutline style={{ cursor: 'pointer' }} color='#B31312' size={24} />
                    </Popconfirm> : null}
                </div>
            )
        }
    ].filter((column) => !column.hidden)
    )

    return (
        <div className='my-form-outer'>
            <div className='my-form-header'>
                <span className='my-form-title'>Reports</span>
                <div>
                    <SearchComponent
                        masterState={masterState}
                        state={reportData}
                        setState={setReportData}
                        searchOptions={[
                            { keyName: 'asy_txn_id', label: 'Report ID' },
                            { keyName: 'report_name', label: 'Report Name' },
                            { keyName: 'report_code', label: 'Report Type' },
                            { keyName: 'status', label: 'Status' },
                        ]}
                        isLabelInline={false}
                    />
                </div>
            </div>
            {permissions?.add && <div style={{ display: 'flex', alignSelf: 'center' }}>
                {!visible && <Button type={visible ? "danger" : 'primary'} onClick={() => showReport()}>New Report</Button>}
                {
                    visible && <div>
                        <div style={{ display: 'flex' }}>
                            <Select
                                onSelect={(value) => searchReportCode(value)}
                                style={{ width: '350px' }}
                                defaultValue={"Select Report Type"}
                                options={reportType}
                            />

                        </div>
                    </div>
                }
                {visible && <Button style={{ marginLeft: '20px' }} type="danger" onClick={() => showReport()}>Cancel</Button>}
            </div>}
            {contextHolder}
            {visible && <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
                <Space>
                    {
                        (singleSiteFilter || dateFilter) &&
                        <InputWithLabel label="Report Name" reqMark>
                            <Input placeholder='Report Name' status={error.reportName && 'error'} onChange={(e) => setReportName(e.target.value)} />
                        </InputWithLabel>
                    }
                    {
                        singleSiteFilter &&
                        <Space>
                            <SiteTreeSelectAuto
                                selectedOrg={selectedOrg}
                                value={selected.site_id}
                                // onChange={(value) => {  }}
                                setValue={(value) => {
                                    setSelectedKey('site_id', value)
                                    setFilters({ ...filters, site_id: value })
                                }}
                            />
                        </Space>
                    }
                    {
                        multiSiteFilter &&
                        <Space>
                            <SiteTreeMultiSelect
                                selectedOrg={selectedOrg}
                                value={selected.sites}
                                setValue={(value) => {
                                    setSelectedKey('sites', value)
                                    setFilters({ ...filters, sites: value })
                                }}
                            />
                        </Space>
                    }
                    {
                        dateFilter &&
                        <Space>
                            <InputWithLabel label={'Date'} reqMark>
                                <DatePicker
                                    className="my-form-input"
                                    format={"YYYY-MM-DD"}
                                    // value={selected.date}
                                    // onChange={(value) => { setSelectedKey('date', value) }}
                                    onChange={(value) => { setFilters({ ...filters, date: value.format("YYYY-MM-DD") }) }}
                                    allowClear={false}
                                    status={error.date && 'error'}
                                    disabledDate={(current) => { return current && current > moment().endOf('day'); }}
                                />
                            </InputWithLabel>
                        </Space>

                    }
                    <InputWithLabel label={' '}>
                        {reportCode && <Button onClick={() => createReport()} type='primary'>Submit</Button>}
                    </InputWithLabel>

                </Space>
            </div>}
            <Divider />
            <Table
                columns={columns}
                dataSource={reportData}
                loading={loading}
                rowKey={record => record.asy_txn_id}
                expandable={{
                    rowExpandable: record => Object.values(record?.filters)?.length,
                    expandedRowRender: record => (
                        <div style={{ marginLeft: '25px', display: 'flex', flexDirection: 'row', gap: '10px' }}>
                            {Object.entries(record?.filters).map(([key, value]) => (
                                <div style={{}} key={key}>
                                    {key === "date" &&
                                        <InputWithLabel label="Date">
                                            <Input placeholder='Date' disabled defaultValue={value} />
                                        </InputWithLabel>
                                    }
                                    {
                                        key === "report_name" &&
                                        <InputWithLabel label="Report Name">
                                            <Input placeholder='Report Name' disabled defaultValue={value} />
                                        </InputWithLabel>
                                    }
                                    {
                                        key == "site_id" &&
                                        <SiteTreeSelectAuto
                                            selectedOrg={selectedOrg}
                                            value={value}
                                            disabled
                                            setValue={(value) => { }}
                                            reqMark={false}
                                        />
                                    }
                                    {
                                        key == "sites" &&
                                        <SiteTreeMultiSelect
                                            selectedOrg={selectedOrg}
                                            value={value}
                                            setValue={(value) => { }}
                                            reqMark={false}
                                        />
                                    }
                                </div>
                            ))}
                        </div>
                    )
                }}
                pagination={{
                    position: ['bottomRight'],
                    showSizeChanger: true,
                    defaultPageSize: 15,
                }}
                size='small'
            />
        </div >
    )
}
