import { Button, message, Popconfirm, Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import SearchComponent from '../GeneralComponents/SearchComponent';
import { deleteButtonTitle, deleteButtonType, editButtonTitle, editButtonType, ApiKeyDeleteMessage } from '../../Constants';
import moment from 'moment';

export default function AllApiKeys({
    selectedOrg,
    permissions={},
    
    editPath="/masters/api_keys/edit",
    addPath="/masters/api_keys/add",
}) {
    const [masterState, setMasterState] = useState([]);

    // The below state is after the search is done
    const [apiKeysTemplates, setApiKeysTemplates] = useState([]);
    const [loading, setLoading] = useState(true);

    const getTemplates = async () => {
        setLoading(true);
        /*
            This api return format:
            res.data => api_keys
            [
                {
                    "key_id": 1,
                    "key_name": "TEST",
                    "comment": "str",
                    "access_key": "TAGIDACCESSKEY",
                    "secret_key": "TAGIDSECRETKEY",
                    "created_time": "2023-02-09T11:03:58",
                    "expiry_time": "2024-02-09T11:04:01",
                    "updated_time": "2023-02-09T11:04:11",
                    "created_by": 1,
                    "last_access_time": "2023-02-09T15:52:50.441948",
                    "is_active": 1,
                }
            ]

        
        */
        await axios.get('/api_keys/all_details')
            .then(res => {
                const data = res.data.data;
                setMasterState(data);
                setApiKeysTemplates(data);
            })
            .catch(err => {
                console.log(err);
                setApiKeysTemplates([]);
                message.error('Error getting API Keys');
            })
                
        setLoading(false);
    }
    
    const handleDelete = async (keyID) => {
        setLoading(true);
        await axios.delete('/api_keys/delete', {
            params: {
                key_id: keyID,
            }
        })
            .then(res => {
                message.success('API key deleted successfully');
                getTemplates();
            })
            .catch(err => {
                console.log(err);
                try {
                    message.error(err.response.data.detail);
                } catch (error) {
                    message.error('Error deleting Api key');
                }
                setLoading(false);
            })
        // setLoading(false);
    }

    const columns = useMemo(() => [
        {
            title: 'Id',
            dataIndex: 'key_id',
            key: 'key_id',
            width: '50px',
            hidden: window.location.hostname !== 'localhost',
        },
        {
            title: 'Key Name',
            dataIndex: 'key_name',
            key: 'key_name',
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
        },
        // {
        //     title: 'Access Key',
        //     dataIndex: 'access_key',
        //     key: 'access_key',
        // },
        // {
        //     title: 'Secret Key',
        //     dataIndex: 'secret_key',
        //     key: 'secret_key',
        // },
        // {
        //     title: 'Created Time',
        //     dataIndex: 'created_time',
        //     key: 'created_time',
        // },
        {
            title: 'Expiry Time',
            dataIndex: 'expiry_time',
            key: 'expiry_time',
            render: (text, record) =>  moment(text).format('Do MMM YYYY, HH:mm A'),
            sorter: (a, b) => moment(a.expiry_time) - moment(b.expiry_time),
        },
        // {
        //     title: 'Updated Time',
        //     dataIndex: 'updated_time',
        //     key: 'updated_time',
        // },
        // {
        //     title: 'Created By',
        //     dataIndex: 'created_by',
        //     key: 'created_by',
        // },
        {
            title: 'Last Access Time',
            dataIndex: 'last_access_time',
            key: 'last_access_time',
            render: (text, record) =>  moment(text).format('Do MMM YYYY, HH:mm A'),
            sorter: (a, b) => moment(a.last_access_time) - moment(b.last_access_time),
        },
        {
            title: 'Is Active',
            dataIndex: 'is_active',
            key: 'is_active',
            render: (text, record) => { return text ? 'Yes' : 'No' }
        },
        {
            width: '80px',
            title: 'Action',
            render: (text, record) => (
                <div className='actions-outer-div'>
                    <Button type={editButtonType} className='actions-button' title={`Edit Api Key | ${record.key_name}`}>
                        <Link to={`${editPath}/${record.key_id}`}>
                            {editButtonTitle}
                        </Link>
                    </Button>
                    <Popconfirm 
                        overlayClassName='delete-popconfirm' 
                        title={<>{record.key_name} <br/> {ApiKeyDeleteMessage}</>} 
                        onConfirm={() => handleDelete(record.key_id)} 
                        okText="Yes" 
                        cancelText="No" 
                        disabled={!permissions.delete}
                    >
                        <Button danger type={deleteButtonType} disabled={!permissions.delete} className='actions-button' title={`Delete Api Key | ${record.key_name}`}>
                            {deleteButtonTitle}
                        </Button>
                    </Popconfirm>
                </div>
            ),
            width: '150px'

        },
    ], [selectedOrg]).filter(column => !column.hidden);

    useEffect(() => {
        getTemplates();
    }, [selectedOrg.orgId]);




    return (
        <div className='my-form-outer'>
            <div className='my-form-header'>
                <span className='my-form-title'>Api Keys</span>
                <Button type="primary" disabled={!permissions.add}>
                    <Link to={addPath}>
                        Add Api Key
                    </Link>
                </Button>

            </div>
            <div style={{display: 'inline-flex', justifyContent: 'space-between', width: '100%'}}>
                <div></div>
                <SearchComponent
                    masterState={masterState}
                    state={apiKeysTemplates}
                    setState={setApiKeysTemplates}
                    searchOptions={[
                        {keyName: 'key_name', label: 'Name'},
                        {keyName: 'comment', label: 'Comment'},
                    ]}
                    // defaultSearchKeys={['key_name']}
                />
            </div>
            <Table
                columns={columns}
                dataSource={apiKeysTemplates}
                loading={loading}
                size="small"
                pagination={{
                    position: ['bottomRight'],
                    // position: ['topRight'],
                    showSizeChanger: true,
                }}
            />
        </div>
    )
}
