export const gr_setOrgsLoading = (loading) => {
    return {
        type: "GR_SET_ORGS_LOADING",
        payload: loading,
    };
}

export const gr_setOrgOptions = (orgs) => {
    return {
        type: "GR_SET_ORG_OPTIONS",
        payload: orgs,
    };
}

export const gr_setSelectedOrg = (org) => {
    return {
        type: "GR_SET_SELECTED_ORG",
        payload: org,
    }
}

export const gr_setSitesLoading = (loading) => {
    return {
        type: "GR_SET_SITES_LOADING",
        payload: loading,
    };
}

export const gr_setSiteOptions = (sites) => {
    return {
        type: "GR_SET_SITE_OPTIONS",
        payload: sites,
    };
}

export const gr_setSelectedSite = (site) => {
    return {
        type: "GR_SET_SELECTED_SITE",
        payload: site,
    }
}

export const gr_showSiteSelector = () => {
    return {
        type: "GR_SET_SHOW_SITE_SELECTOR",
        payload: true,
    }
}
export const gr_hideSiteSelector = () => {
    return {
        type: "GR_SET_SHOW_SITE_SELECTOR",
        payload: false,
    }
}
