import { Button, Input, Modal, Select, Space, Table, Tag, message } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import SearchComponent from '../../Masters/GeneralComponents/SearchComponent'
import axios from 'axios';
import moment from 'moment';
import useFilterOptions from '../../Components/useFilterOptions';
import { InputWithLabel } from '../../Components/Components';
import SiteTreeSelectAuto from '../Components/SiteTreeSelectAuto';
import DateRange from '../../Reports/Components/DateRange';
import SiteTreeMultiSelect from '../Components/SiteTreeMultiSelect';
import { Link, redirect, useNavigate } from 'react-router-dom';
import { ExportToExcelButton } from '../../Components/ExportToExcel';

const CentralPrintingList = ({ selectedOrg = {}, permissions = {} }) => {

    const statusColorMap = {
        pending_approval: "#ff9900",
        printed: "#3765d4",
        delivered: "#75a922",
        dispatch: "#75a922",
        received: "#75a922",
        approved: "#22a944",
        created: "#f76020",
    };


    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [masterState, setMasterState] = useState([]);
    const [data, setData] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [requestBody, setRequestBody] = useState({
        job_name: null,
        reference_number: null,
        site_id: 0,
        job_type_id: 0
    })

    const [selected, setSelected] = useState({
        dateRange: [
            sessionStorage.getItem("dateRange") ? moment(JSON.parse(sessionStorage.getItem("dateRange"))?.[0]) : moment().subtract(1, 'day'),
            moment(JSON.parse(sessionStorage.getItem("dateRange"))?.[1]),   // It will be undefined if no value is present in local storage and moment will take it as today's date
        ],

        sites: sessionStorage.getItem('siteIds') ? JSON.parse(sessionStorage.getItem('siteIds')) : [],
    });

    const setSelectedKey = (key, value) => {
        setSelected(prev => ({
            ...prev,
            [key]: value,
        }));
    }

    const filterableColumns = useMemo(() => ["status"], []);
    const [filterOptions, setFilterOptions] = useFilterOptions(masterState, filterableColumns);

    const showModal = () => {
        setIsModalOpen(true);
    };


    const handleCancel = () => {
        setIsModalOpen(false);
        setRequestBody({
            job_name: null,
            reference_number: null,
            site_id: 0,
            job_type_id: 0
        });
        getData();
    };


    const getDropdownData = async () => {
        setLoading(true);
        await axios
            .get(`/admin-api/job_type_list`)
            .then((res) => {
                setTypeList([])
                res.data?.data?.forEach((item) => {
                    setTypeList(prev => [
                        ...prev,
                        {
                            label: item.type_name,
                            value: item.job_type_id
                        }
                    ]);
                });
            }).catch((error) => {
                if (error.response) {
                    console.log(error);
                    message.error(`${error.response?.status} Error ${error.response?.data?.detail}`)
                } else {
                    console.log(error);
                    message.error(error?.message);
                }
            });
        setLoading(false);
    };

    const getData = async () => {
        setLoading(true);
        await axios
            .post(`/admin-api/job_list`, {
                from_date: selected.dateRange[0].startOf('day').format(),
                to_date: selected.dateRange[1].endOf('day').format(),
                site_ids: selected.sites,
            })
            .then((res) => {
                const data = res.data.data
                if (!permissions?.pending_approval) {
                    //when store user get data then remove pending approved data from list
                    const filterData = data.filter(record => record.is_approved !== 0)
                    setData(filterData);
                    setMasterState(filterData);
                } else {
                    setData(data);
                    setMasterState(data);
                }
            }).catch((error) => {
                setMasterState([]);
                setData([]);
                if (error.response) {
                    console.log(error);
                    message.error(`${error.response?.status} Error ${error.response?.data?.detail}`)
                } else {
                    console.log(error);
                    message.error(error?.message);
                }
            });
        setLoading(false);
    };

    const createJob = async () => {

        if (requestBody.job_type_id === 0) {
            message.error("Please select a job type");
            return;
        }

        if (requestBody.site_id === 0) {
            message.error("Please select site");
            return;
        }

        if (requestBody.job_name == "" || requestBody.job_name == null) {
            message.error("Please input job name");
            return;
        }

        if (requestBody.reference_number == "" || requestBody.reference_number == null) {
            message.error("Please input Reference Number");
            return;
        }

        setLoading(true);
        await axios
            .post(`/admin-api/create_job`, requestBody)
            .then((res) => {
                message.success("Job Create successfully");
                const job_id = res?.data?.data.job_id;
                navigate(`${"details"}?${"job_id=" + job_id}`)
                handleCancel();
            })
            .catch((error) => {
                if (error.response) {
                    message.error(error.response?.data?.detail);
                } else {
                    console.log(error);
                    message.error(error?.message);
                }
            });
        setLoading(false);
    };


    useEffect(() => {
        getDropdownData();

        if (!selected?.sites?.length) {
            setMasterState([]);
            setLoading(false);
            return;
        }

        getData();
        selected?.sites && sessionStorage.setItem('siteIds', JSON.stringify(selected.sites));
        selected?.dateRange && sessionStorage.setItem("dateRange", JSON.stringify(selected.dateRange));
    }, [selected.sites, selected.dateRange])


    const columns = [
        {
            title: "Job ID",
            dataIndex: "job_id",
            key: "job_id",
            width: 90,
        },
        {
            title: "Job Name",
            dataIndex: "job_name",
            key: "job_name",
        },
        {
            title: "Reference Number",
            dataIndex: "reference_number",
            key: "reference_number",
            render: (text, record) => <div>{removeUnderscore(text)}</div>,
        },
        {
            title: "Created Date",
            dataIndex: "created_time",
            key: "created_time",
            render: (text, record) => moment(text).format('Do MMM YYYY, HH:mm A'),
            sorter: (a, b) => moment(a.created_time) - moment(b.created_time),
        },
        {
            title: "Job Type",
            dataIndex: "type_name",
            key: "type_name",
        },
        {
            title: "Site Name",
            dataIndex: "site_name",
            key: "site_name",
        },
        {
            title: "Job Qty",
            dataIndex: "job_qty",
            key: "job_qty",
        },
        {
            title: "Print Qty",
            dataIndex: "print_qty",
            key: "print_qty",
        },
        {
            title: "Created By",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "Approved By",
            dataIndex: "approved_username",
            key: "approved_username",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            filters: filterOptions.status,
            onFilter: (value, record) => record.status == value,
            render: (text, record) => (
                <Tag color={statusColorMap[text] || "gray"} key={record.status} style={{ fontWeight: "700" }}>
                    {removeUnderscore(text)}
                </Tag>
            ),
        },
        {
            title: "Is Approved",
            dataIndex: "is_approved",
            key: "is_approved",
            render: (text, record) => (
                <Tag color={statusColorMap[text === 0 ? "pending_approval" : "approved"] || "gray"}
                    key={record.status}
                    style={{ fontWeight: "700" }}>
                    {text === 0 ? "Pending Approval" : "Approved"}
                </Tag>
            ),
            sorter: (a, b) => a.is_approved - b.is_approved,
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend'
        },
        {
            title: 'Action',
            render: (text, record) => (
                <div className='actions-outer-div'>
                    <Button type="primary" className='actions-button' >
                        <Link to={`${"details"}?${"job_id=" + record.job_id}`}>
                            View
                        </Link>
                    </Button>
                </div>
            ),
            width: '80px',
        },
    ];

    const removeUnderscore = (text) => {
        const stringWithSpaces = text.replaceAll("_", " ");
        return stringWithSpaces
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    return (
        <div className="my-form-outer">
            <div className="my-form-header">
                <span style={{ display: "inline-flex", alignItems: "center" }}>
                    <span className="my-form-title">Central Printing</span>
                </span>
            </div>

            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between', gap: 20, marginBottom: 10 }}>
                <Space>
                    <DateRange
                        selectedOrg={selectedOrg}
                        value={selected.dateRange}
                        setValue={(value) => { setSelectedKey('dateRange', value) }}
                    />
                    <SiteTreeMultiSelect
                        selectedOrg={selectedOrg}
                        value={selected.sites}
                        setValue={(value) => { setSelectedKey('sites', value) }}
                    />
                    <InputWithLabel>
                        <ExportToExcelButton
                            fileName={"Central Printing"}
                            sheets=
                            {[
                                {
                                    sheetName: "Central Printing List",
                                    data: data.map(item => ({
                                        ...item,
                                        created_time: moment(item.created_time).format('Do MMM YYYY, HH:mm:ss A'),
                                        is_approved: item.is_approved === 1 ? "Approved" : "Pending Approval" ,
                                        // Mapping is_approved to "Approved" or "Pending Approval", 
                                    })), // Filtering out "Approved" and "Pending Approval" rows
                                    columns: columns.filter(column => column.title !== "Action") // Excluding "Action" columns
                                }
                            ]}
                            buttonName="Export Data"
                        />
                    </InputWithLabel>
                </Space>
                <Space>
                    <SearchComponent
                        masterState={masterState}
                        state={data}
                        setState={setData}
                        searchOptions={[
                            { keyName: "job_name", label: "Job Name" },
                            { keyName: "type_name", label: "Type Name" },
                            { keyName: "reference_number", label: "Upload Time" },
                            { keyName: "username", label: "Created By" },
                            { keyName: "status", label: "Status" },
                        ]}
                        isLabelInline={false}
                    />
                    {permissions?.create_job && <Button style={{ marginTop: 13 }} type='primary' onClick={showModal}>
                        Create Job
                    </Button>}
                </Space>
            </div>

            <Modal title="Create Job"
                maskClosable={false}
                open={isModalOpen}
                okButtonProps={{ hidden: true }}
                cancelButtonProps={{ type: 'danger' }}
                onCancel={handleCancel}>

                <div>
                    <Space direction='vertical'>

                        <Space>
                            <InputWithLabel reqMark label={"Select Type Name"}>
                                <Select
                                    defaultValue="Select Job Type"
                                    options={typeList}
                                    value={requestBody.job_type_id || "Select Job Type"}
                                    style={{ width: '230px' }}
                                    onSelect={(value) => {
                                        setRequestBody(prev => ({ ...prev, job_type_id: value }))
                                    }}
                                />
                            </InputWithLabel>

                            <SiteTreeSelectAuto
                                selectedOrg={selectedOrg}
                                value={requestBody.site_id || "Select Site"}
                                style={{ width: '230px' }}
                                setValue={(value) => {
                                    setRequestBody(prev => ({ ...prev, site_id: value }))
                                }}
                            />
                        </Space>

                        <Space>
                            <InputWithLabel label="Job Name" reqMark={true}>
                                <Input
                                    className="my-form-input"
                                    value={requestBody?.job_name || ""}
                                    style={{ width: '230px' }}
                                    required
                                    onChange={(e) => {
                                        setRequestBody(prev => ({ ...prev, job_name: e.target.value }))
                                    }}
                                />
                            </InputWithLabel>

                            <InputWithLabel label="Reference Number" reqMark={true}>
                                <Input
                                    className="my-form-input"
                                    value={requestBody?.reference_number || ""}
                                    style={{ width: '230px' }}
                                    required
                                    onChange={(e) => {
                                        setRequestBody(prev => ({ ...prev, reference_number: e.target.value }))
                                    }}
                                />
                            </InputWithLabel>
                        </Space>

                        <Button style={{ float: 'right' }} loading={loading} onClick={() => createJob()} type='primary'>Create Job</Button>

                    </Space>

                </div>

            </Modal>

            <Table
                columns={columns}
                dataSource={data}
                loading={loading}
                key={selectedOrg.orgId}
                scroll={{
                    x: 800,
                }}
                pagination={{
                    position: ["bottomRight"],
                    showSizeChanger: true,
                }}
                size="small"
            />
        </div>
    )
}

export default CentralPrintingList