import { Table, message, Button, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import SearchComponent from '../../Masters/GeneralComponents/SearchComponent';
import DateRange from '../../Reports/Components/DateRange';
import SiteTreeMultiSelect from '../Components/SiteTreeMultiSelect';
import axios from 'axios';
import moment from 'moment';
import { editButtonType } from '../../Constants';
import { Link } from 'react-router-dom';
import { ExportToExcelButton } from '../../Components/ExportToExcel';


export default function StockSessionsList({
    selectedOrg = {},
    permissions = {},

    editPath = "/inventory/stock-take/sessions",
}) {
    // Using session storage to save it even if the user refreshes the page, If opened in new tab, then it will be empty
    // setItem is in the useEffect below
    const [selected, setSelected] = useState({
        dateRange: [
            sessionStorage.getItem("stockTakeSessionList-dateRange") ? moment(JSON.parse(sessionStorage.getItem("stockTakeSessionList-dateRange"))?.[0]) : moment().subtract(1, 'month'),
            moment(JSON.parse(sessionStorage.getItem("stockTakeSessionList-dateRange"))?.[1]),   // It will be undefined if no value is present in local storage and moment will take it as today's date
        ],
        sites: sessionStorage.getItem('stockTakeSessionList-siteIds') ? JSON.parse(sessionStorage.getItem('stockTakeSessionList-siteIds')) : [],
    });

    const [masterState, setMasterState] = useState([]);

    // The below state is after the search is done
    const [filteredState, setFilteredState] = useState([]);
    const [loading, setLoading] = useState(true);

    const getData = async () => {
        setLoading(true);
        await axios.post('/admin-api/reports/stock_take/session_list', {
            o_id: selectedOrg.orgId,
            from_date: selected.dateRange[0].startOf('day').format(),
            to_date: selected.dateRange[1].endOf('day').format(),
            site_ids: selected.sites,
        })
            .then(res => {
                const data = res.data.data;

                setMasterState(data);
                setFilteredState(data);

            })
            .catch(err => {
                console.log(err);
                message.error('Error fetching data');
                setMasterState([]);
                setFilteredState([]);
            })
        setLoading(false);
    }

    useEffect(() => {
        // This useEffect dosent have the selectedOrg as a dependency because if
        // new org is selected, then new sites will be loded ... and that will trigger getData
        // If we add that dependency, then it will trigger getData twice and the first time with previous org's sites
        if (selected.sites.length === 0) {
            setMasterState([]);
            setLoading(false);
            return;
        }
        getData();

        // Writing to session storage for persistance
        selected?.sites && sessionStorage.setItem("stockTakeSessionList-siteIds", JSON.stringify(selected.sites));
        selected?.dateRange && sessionStorage.setItem("stockTakeSessionList-dateRange", JSON.stringify(selected.dateRange));

    }, [selected.sites, selected.dateRange])
    // }, [selectedOrg.orgId, selected.sites, selected.dateRange])

    // This is just for UI loading
    useEffect(() => {
        if (selectedOrg.orgId) {
            setLoading(true);
        }
    }, [selectedOrg.orgId]);



    const columns = [
        {
            title: 'Session ID',
            dataIndex: 'session_id',
            key: 'session_id',
            sorter: (a, b) => a.session_id - b.session_id,
            width: 100,
        },
        {
            title: 'Session Name',
            dataIndex: 'session_name',
            key: 'session_name',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Site Name',
            dataIndex: 'site_name',
            key: 'site_name',
        },
        {
            title: 'Scanned Count',
            dataIndex: 'percentage_complete',
            key: 'percentage_complete',

        },
        {
            title: 'Actions',
            render: (text, record) => (
                <div className='actions-outer-div'>
                    <Button type={editButtonType} className='actions-button' >
                        <Link to={`${editPath}/${record.session_id}`}>
                            View
                        </Link>
                    </Button>
                </div>
            ),
            width: '80px',
        },
    ].filter(column => !column.hidden);


    const setSelectedKey = (key, value) => {
        setSelected(prev => ({
            ...prev,
            [key]: value,
        }));
    }


    return (
        <div className='my-form-outer'>
            <div className='my-form-header'>
                <span className='my-form-title'>Session List</span>

            </div>
            <div style={{gap:'10px'}} className="my-table-filter-row">
                <div className="my-form-multiple-inline-input">
                    {/* Read documentation inside DateRange for set methods */}
                    <DateRange
                        selectedOrg={selectedOrg}

                        value={selected.dateRange}
                        setValue={(value) => { setSelectedKey('dateRange', value) }}
                    />
                    <SiteTreeMultiSelect
                        selectedOrg={selectedOrg}

                        value={selected.sites}
                        setValue={(value) => { setSelectedKey('sites', value) }}

                    // style={{width: '450px'}}
                    />
                </div>

                <SearchComponent
                    masterState={masterState}
                    state={filteredState}
                    setState={setFilteredState}

                    searchOptions={[
                        {keyName: 'session_id', label: 'Session ID'},
                        {keyName: 'session_name', label: 'Session Name'},
                        {keyName: 'site_name', label: 'Site Name'},
                        {keyName: 'status', label: 'Status'},
                    ]}
                    isLabelInline={false}
                />

                <ExportToExcelButton
                    fileName={`Session List`}
                    sheets={[
                        {
                            sheetName: 'Session List Data',
                            data: filteredState,
                            columns: columns.slice(0, columns.length - 1),
                        }
                    ]}
                    buttonDisabled={loading}
                    buttonLoading={loading}
                />
            </div>
            <Table
                columns={columns}
                dataSource={filteredState}
                loading={loading}
                size='small'
                key={selectedOrg.orgId}
                pagination={{
                    position: ["bottomRight"],
                    // position: ['topRight'],
                    showSizeChanger: true,
                    defaultPageSize: parseInt(sessionStorage.getItem('stockTakeSessionList-tablePageSize') || 10),
                    defaultCurrent: parseInt(sessionStorage.getItem('stockTakeSessionList-tableCurrentPage') || 1),
                }}
                onChange={(pagination, filters, sorter, extra) => {
                    // console.log('params', pagination, filters, sorter, extra);
                    sessionStorage.setItem('stockTakeSessionList-tablePageSize', pagination.pageSize);
                    sessionStorage.setItem('stockTakeSessionList-tableCurrentPage', pagination.current);
                }}
            />

        </div>
    )
}
