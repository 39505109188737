import { Avatar, Button, Drawer, Form, Input, Layout, Menu, Modal, Popover, Space, Spin, message } from "antd";
import React, { useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Link, Outlet, useLocation, } from "react-router-dom";
import OrgSelector from "../Components/OrgSelector";
import { useSelector } from "react-redux";
import GlobalSearch from "./GlobalSearch";
import { UserOutlined } from '@ant-design/icons';
import { InputWithLabel } from "../Components/Components";
import axios from "axios";

export default function MyNavbar({ collapsed, onCollapse, globalSearchItems }) {
    const globalReducer = useSelector(state => state.globalReducer);
    const authReducer = useSelector(state => state.authReducer);

    const location = useLocation();
    const currentRouteName = location.pathname;

    const [open, setOpen] = useState(false);
    const [visible, setVisible] = useState(false);

    const showChangePasswordInput = () => {
        setOpen(!open);
    };

    const [data, setData] = useState({
        user_id: authReducer?.user?.userId,
        prev_password: "",
        new_password: "",
        re_password: "",
    })

    const [loading, setLoading] = useState(false)


    const text = <span>Profile</span>;
    const content = (
        <div style={{ flexDirection: 'column', display: 'flex' }}>

            {open && <>
                <p><span style={{ fontSize: "14px", }}>User Name :</span> {authReducer?.user?.username}</p>

                <Space>
                    {currentRouteName !== "/profile" && <Link to="/profile"><Button >Edit Profile</Button></Link>}
                    <Button type="primary" onClick={() => { showChangePasswordInput() }}>Change Password</Button>
                </Space>
            </>}

            {!open && <div style={{ flexDirection: 'column', display: 'flex', marginTop: '10px' }}>

                <h4>Update your password</h4>

                <Form layout="vertical" style={{ flexDirection: 'column', display: 'flex', marginTop: '10px' }}>

                    <Form.Item
                        label="Current Password"
                        name="current_password"
                        required
                        onChange={(e) => {
                            setData({ ...data, prev_password: e.target.value })
                        }}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter current password!',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="New Password"
                        name="new_password"
                        required
                        onChange={(e) => {
                            setData({ ...data, new_password: e.target.value })
                        }}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter new password!',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="Re-Enter Password"
                        name="re_enter_password"
                        required
                        onChange={(e) => {
                            setData({ ...data, re_password: e.target.value })
                        }}
                        rules={[
                            {
                                required: true,
                                message: 'Please Re-enter password!',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Space style={{ marginTop: '10px' }}>
                        <Button onClick={() => showChangePasswordInput()} danger>Cancel</Button>
                        <Button htmlType="submit" type="primary" loading={loading} onClick={() => updatePassword()}>Update Password</Button>
                    </Space>

                </Form>
            </div>}

        </div>
    );

    const logoutDialog = () => {
        Modal.success({
            title: 'Password Changed Successfully',
            content: (
                <div>
                    <p>Changing your password will log you out for security purpose.</p>
                </div>
            ),
            onOk() {
                logout()
            },
            okText: "Login",
        });
    };
    const [modal, contextHolder] = Modal.useModal();

    const logout = () => {
        localStorage.removeItem("AdminToken");
        localStorage.removeItem("RoleToken");
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload()
    }

    async function updatePassword() {
        if (data.prev_password !== "" && data.new_password !== "") {
            if (data.new_password !== data.re_password) {
                message.error("Password not matched");
                return
            }
            setLoading(true);
            await axios.put('/admin-api/update_password', { ...data }).then(res => {
                message.success("Password Update successfully !!")
                setOpen(true)
                setData({
                    ...data,
                    new_password: "",
                    prev_password: "",
                    re_password: "",
                })
                setVisible(false)
                logoutDialog()

            }).catch(error => {
                if (error.response) {
                    message.error(error.response?.data?.detail)
                } else {
                    console.log(error);
                    message.error(error?.message)
                }
            })
            setLoading(false)
        }
    }


    return (
        <Layout>
            <Layout.Header>
                <div style={{ display: "inline-flex", width: "100%", justifyContent: "space-between" }}>
                    <span style={{ display: "inline-flex", alignItems: "center", gap: '10px', alignItems: 'center' }}>
                        {collapsed ? (
                            <MenuUnfoldOutlined onClick={() => onCollapse(!collapsed)} />
                        ) : (
                            <MenuFoldOutlined onClick={() => onCollapse(!collapsed)} />
                        )}
                        {/* <img
                            alt="logo"
                            src="/icon-72x72.png"              // '/icon-72x72.png' `/` means public folder
                            style={{ height: '35px', marginLeft: '0px' }}
                        /> */}
                        <div style={{ fontSize: '14px', marginLeft: '0px' }}>
                            <GlobalSearch
                                items={globalSearchItems}
                            />
                        </div>
                        <div id="navbar-page-name-portal" style={{ paddingLeft: '0px' }}>
                        </div>
                    </span>
                    {/* <Routes>
                        <Route path="cms" element={cmsEventsPageContent} />
                        <Route path="companion" element={<CompanionNavbar />} />
                        <Route path="*" element={<>Home</>} />
                    </Routes> */}
                    <div id="navbar-selector-portal" style={{ display:'flex', alignItems:'center',gap:10 ,paddingRight: '15px' }}>
                        <h5 style={{ color:"white"}}>{authReducer?.user?.username}</h5>
                        <Popover placement="leftTop" title={text} content={content} trigger="click">
                            <Avatar style={{
                                backgroundColor: '#87d068',
                                marginBottom: '10px',
                            }} onClick={() => {
                                setOpen(true)
                                setVisible(true)
                            }} shape="circle" size="large" icon={<UserOutlined />} />
                        </Popover>
                    </div>
                </div>
            </Layout.Header>
            <Layout.Content className="main-content-div">
                {contextHolder}
                {globalReducer.orgLoading || !globalReducer.selectedOrg.orgId ? (
                    <div className="App" style={{ textAlign: 'center' }}>
                        <Spin size="large" style={{ marginTop: '50px' }} />
                    </div>
                ) : (
                    <Outlet />
                )}
            </Layout.Content>
        </Layout >
    );
}
