import React, { useEffect, useState } from 'react'
import SiteTreeSelectAuto from '../RFIDReports/Components/SiteTreeSelectAuto'
import moment from 'moment';
import { DatePicker, Divider, Empty, Row, Spin, message } from 'antd';
import { InputWithLabel } from '../Components/Components';
import axios from 'axios';
import { Column, G2 } from '@ant-design/charts';
import ColumnGraph from './ColumnGraph';

export default function StoreReports({ selectedOrg = {} }) {


    const [loading, setLoading] = useState(true);
    const [dataJSON, setDataJSON] = useState([])

    const [selected, setSelected] = useState({
        date: moment(),
        site: 0,
    });

    const setSelectedKey = (key, value) => {
        setSelected(prev => ({
            ...prev,
            [key]: value,
        }));
    }

    async function getData() {
        setLoading(true);
        await axios.get(`/report/erp_vs_rfid_datewise_product_code_summary?site_id=${selected.site}&report_date=${selected.date.startOf('day').format("yyyy-MM-DD")}&limit=20`)
            .then(res => {
                const data = res.data.data;
                console.log(data);
                setDataJSON(data)
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setDataJSON([])
                message.error('Error fetching data');
                setLoading(false);
            })
    }

    useEffect(() => {
        getData()
    }, [selected.site, selected.date])

    G2.registerInteraction("element-link", {
        start: [
            {
                trigger: "interval:mouseenter",
                action: "element-link-by-color:link",
            },
        ],
        end: [
            {
                trigger: "interval:mouseleave",
                action: "element-link-by-color:unlink",
            },
        ],
    });

    return (
        <div className='my-form-outer'>
            <div className="my-table-filter-row" style={{ alignItems: 'flex-end' }}>
                <span className='my-form-title'>Store Report</span>
                <div className="my-form-multiple-inline-input" style={{ width: 'max-content' }}>
                    <SiteTreeSelectAuto
                        selectedOrg={selectedOrg}
                        value={selected.site}
                        setValue={(value) => { setSelectedKey('site', value) }}
                    />
                    <InputWithLabel label={'Date'} reqMark>
                        <DatePicker
                            format={"YYYY-MM-DD"}
                            value={selected.date}
                            onChange={(value) => { setSelectedKey('date', value) }}
                            allowClear={false}
                            disabledDate={(current) => { return current && current > moment().endOf('day'); }}
                        />
                    </InputWithLabel>
                </div>
            </div>
            <Divider />
            {!loading ?<Row style={{position: 'relative', marginTop: '20px', justifyContent:'center'}}>
               {dataJSON.length > 0 ? <ColumnGraph dataJSON={dataJSON} loading={loading}/> : <Empty/>}
            </Row> : <Spin size='large' style={{display:'flex', justifyContent:'center', marginTop:20}}/>}

        </div>
    )
}