import { message, Table, Button, Tag, Space, Select, Input, Modal, Popconfirm, } from "antd";
import axios from "axios";
import React, { useEffect, useState, useMemo } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import DateRange from "../../Reports/Components/DateRange";
import { editButtonType } from "../../Constants";
import useFilterOptions from "../../Components/useFilterOptions";
import SiteTreeMultiSelect from "../Components/SiteTreeMultiSelect";
import { ExportToExcelButton } from "../../Components/ExportToExcel";
import ImportPOFile from "../Components/ImportPOFile";
import { Content } from "antd/lib/layout/layout";
import { convertSitesToTreeV2 } from "../../Masters/GeneralComponents/Functions";
import SearchComponent from "../../Masters/GeneralComponents/SearchComponent";
import { Helmet } from "react-helmet";

export default function InwardOutwardList({ selectedOrg = {}, permissions = {}, editPath = "/inventory/inward-outward" }) {

    const statusColorMap = { pending: "#cf1322", done: "#389e0d", scanned: "#faad14" };

    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [importPoFileKey, setImportPoFileKey] = useState(0);
    const [boxNo, setBoxNo] = useState();
    const [box_data, setBoxData] = useState([]);
    const [masterState, setMasterState] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sites, setSites] = useState([]);

    // Using session storage to save it even if the user refreshes the page, If opened in new tab, then it will be empty
    // setItem is in the useEffect below
    const [selected, setSelected] = useState({
        dateRange: [
            sessionStorage.getItem("inwardOutward-dateRange")
                ? moment(JSON.parse(sessionStorage.getItem("inwardOutward-dateRange"))?.[0])
                : moment().subtract(1, "day"),
            moment(JSON.parse(sessionStorage.getItem("inwardOutward-dateRange"))?.[1]), // It will be undefined if no value is present in local storage and moment will take it as today's date
        ],
        sites: sessionStorage.getItem("inwardOutward-siteIds") ? JSON.parse(sessionStorage.getItem("inwardOutward-siteIds")) : [],
        inwardOrOutward: searchParams.get("report") === "outward" ? "outward" : "inward",
        dateType: "created",
    });

    console.log(permissions);

    const deleteBtnPermissions = searchParams.get("report") === "outward" ? permissions?.delete_outward_invoice : permissions?.delete_inward_invoice;

    const setSelectedKey = (key, value) => {
        setSelected((s) => ({
            ...s,
            [key]: value,
        }));
    };

    useEffect(() => {
        setSelectedKey("inwardOrOutward", searchParams.get("report") === "outward" ? "outward" : "inward");
    }, [searchParams.get("report")]);


    // The below var is memoized to avoid re-rendering of the filters when the data is changed
    // If not, the filters will be calculated again and again infinitely
    const filterableColumns = useMemo(() => ["status"], []);
    const [filterOptions, setFilterOptions] = useFilterOptions(masterState, filterableColumns);


    const getData = async () => {
        setLoading(true);
        const url = selected.inwardOrOutward === "outward" ? "/dashboard/outward_data" : "/dashboard/inward_data";
        await axios
            .post(url, {
                site_ids: selected.sites,
                from_date: selected.dateRange[0].startOf("day").format("YYYY-MM-DD"),
                to_date: selected.dateRange[1].endOf("day").format("YYYY-MM-DD"),
                dateType: selected.dateType,
            })
            .then((res) => {
                const data = res.data.data;
                const customData = data.map((item) => (
                    {
                        ...item,
                        scanned_count: item.percentage.split("/")[0],
                        expected_count: item.percentage.split("/")[1]
                    }
                ))
                setData(customData);
                setMasterState(customData);
            })
            .catch((err) => {
                console.log(err);
                message.error("Error fetching Data");
                setMasterState([]);
                setData([]);
            });
        setLoading(false);
    };


    const getBoxData = async () => {
        setLoading(true);
        await axios
            .get(`/reports/stock_tf_id_against_box?box_no=${boxNo}&inward_outward=${selected.inwardOrOutward}`)
            .then((res) => {
                const box_data = res.data.data;
                // window.location.href = `/inventory/inward-outward/${box_data?.stock_transfer_id}?orgId=${data?.o_id}&tab=dashboard`;
                navigate(`/inventory/inward-outward/${box_data?.stock_transfer_id}?orgId=${data?.o_id}&tab=box-wise`);
            })
            .catch((err) => {
                console.log(err);
                message.error(`Box not Found in ${selected.inwardOrOutward}`);
                // setMasterState([]);
                setBoxData([]);
            });
        setLoading(false);
    };


    const getSiteOptions = async () => {
        await axios.get('/admin-api/all_sites_by_o_id', {
            params: {
                o_id: selectedOrg.orgId,
            }
        }).then(res => {
            const data = res.data.data;
            const { siteIds } = convertSitesToTreeV2(data);
            setSites(siteIds);
        }).catch(err => {
            console.log(err);
            message.error('Error in fetching sites');
        })
    }


    useEffect(() => {
        getSiteOptions();
    }, [])


    useEffect(() => {
        // This useEffect dosent have the selectedOrg as a dependency because if
        // new org is selected, then new sites will be loded ... and that will trigger getData
        // If we add that dependency, then it will trigger getData twice and the first time with previous org's sites
        if (!selected?.sites?.length) {
            setMasterState([]);
            setLoading(false);
            return;
        }
        getData();

        // Writing to session storage for persistance
        selected?.sites && sessionStorage.setItem("inwardOutward-siteIds", JSON.stringify(selected.sites));
        selected?.dateRange && sessionStorage.setItem("inwardOutward-dateRange", JSON.stringify(selected.dateRange));

    }, [selected.sites, selected.dateRange, selected.inwardOrOutward, selected.dateType]);


    // This is just for UI loading
    useEffect(() => {
        if (selectedOrg.orgId) {
            setLoading(true);
        }
    }, [selectedOrg.orgId]);


    useEffect(
        () => {
            if (!boxNo) {
                setLoading(false);
                return;
            }
        },
        [boxNo],
        [selected.inwardOrOutward]
    );

    const deleteSession = async (id) => {
        setLoading(true);
        await axios
            .post(`/stock_transfer/delete_stock_transfer_record?stock_transfer_id=${id}`)
            .then((res) => {
                message.success("Successfully deleted.")
                getData();
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response?.data?.detail);
                } else {
                    message.error(err?.message);
                }
            });
        setLoading(false);
    }

    const handleBoxNoChange = () => {
        getBoxData();
    };

    const handleChange = (value) => {
        setSelectedKey("dateType", value);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setImportPoFileKey(prevKey => prevKey + 1);
    };

    const columns = useMemo(() =>
        [
            {
                title: "ID",
                dataIndex: "stock_transfer_id",
                key: "stock_transfer_id",
                defaultSortOrder: "descend",
                sorter: (a, b) => a.stock_transfer_id - b.stock_transfer_id,
                width: 60,
            },
            {
                title: "Created Date",
                dataIndex: "reference_dt",
                key: "reference_dt",
            },
            {
                title: "Scanned Date",
                dataIndex: "updatedt",
                key: "updatedt",
            },
            {
                title: "Reference No",
                dataIndex: "reference_no",
                key: "reference_no",
            },
            {
                title: "Site",
                dataIndex: "site_name",
                key: "site_name",
            },
            {
                title: "Reference Site",
                dataIndex: "reference_site",
                key: "reference_site",
            },
            {
                title: "Status",
                dataIndex: "status",
                key: "status",
                filters: filterOptions.status,
                render: (text, record) => (
                    <Tag color={statusColorMap[text] || "#000000"} key={record.status} style={{ fontWeight: "700" }}>
                        {text?.toUpperCase()}
                    </Tag>
                ),
                onFilter: (value, record) => record.status == value,
                filterSearch: true,
            },
            {
                title: "Scanned Count",
                dataIndex: "percentage",
                key: "percentage",
            },
            {
                title: "Actions",
                render: (text, record) => (
                    <div className="actions-outer-div">
                        <Button type={editButtonType} className="actions-button">
                            <Link to={`${editPath}/${record.stock_transfer_id}`}>View</Link>
                        </Button>

                        {deleteBtnPermissions &&
                            <Popconfirm
                                title="Are you sure you want to delete session ?"
                                onConfirm={() => deleteSession(record?.stock_transfer_id)}
                                okText="Yes"
                                cancelText="No"
                                placement="rightTop"
                            >
                                <Button type="danger" className='actions-button' >
                                    Delete
                                </Button>
                            </Popconfirm>
                        }
                    </div>
                ),
                width: "80px",
            },
        ].filter((column) => !column.hidden)
    );

    return (
        <div className="my-form-outer">
            <div className="my-form-header">
                <Space style={{ display: 'flex', alignItems: 'center' }}>
                    <span className="my-form-title">
                        Stock {selected.inwardOrOutward === "outward" ? "Outward" : "Inward"} Sessions
                    </span>
                </Space>
                <SiteTreeMultiSelect
                    selectedOrg={selectedOrg}
                    value={selected.sites}
                    setValue={(value) => {
                        setSelectedKey("sites", value);
                    }}
                    pickerLabel="Select Sites"
                    isHidden={selected.sites.length === 1 ? true : false}
                />
            </div>

            <Content
                style={{
                    padding: 10,
                    margin: 0,
                    minHeight: 40,
                    background: "#F5F7F8",
                    borderRadius: "#fff",
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>

                <div style={{ display: 'flex', gap: '10px' }}>
                    <Space.Compact>
                        <Input
                            type="text"
                            value={boxNo}
                            onChange={(e) => setBoxNo(e.target.value)}
                            placeholder="Enter Box Number"
                            style={{ width: "100%" }}
                        />
                        <Button type="primary" onClick={handleBoxNoChange} loading={loading}>
                            Find Box
                        </Button>
                    </Space.Compact>

                    <Space.Compact>
                        <DateRange
                            selectedOrg={selectedOrg}
                            value={selected.dateRange}
                            setValue={(value) => {
                                setSelectedKey("dateRange", value);
                            }}
                            pickerLabel=""
                        />
                        <Select
                            value={selected.dateType}
                            style={{
                                width: 120,
                                float: "left",
                                marginRight: "15px",
                            }}
                            onChange={handleChange}
                            options={[
                                {
                                    value: "created",
                                    label: "Created Date",
                                },
                                {
                                    value: "scanned",
                                    label: "Scanned Date",
                                },
                            ]}
                        />
                    </Space.Compact>

                </div>

                <div style={{ display: 'flex', gap: '10px' }}>
                    <SearchComponent
                        masterState={masterState}
                        state={data}
                        setState={setData}
                        searchOptions={[
                            { keyName: "reference_no", label: "Reference No" },
                            { keyName: "site_name", label: "Site Name" },
                            { keyName: "status", label: "Status" },
                            { keyName: "reference_site", label: "Reference Site" },
                            { keyName: "updatedt", label: "Updated Date" },
                            { keyName: "stock_transfer_id", label: "Stock Transfer ID" },
                        ]}
                        isLabelInline={false}
                    />

                    <ExportToExcelButton
                        fileName={`Stock ${selected.inwardOrOutward === "outward" ? "Outward" : "Inward"
                            } Sessions ${selected.dateRange[0].format("DD")} to ${selected.dateRange[1].format("DD MMM")}`}
                        sheets={[{
                            sheetName: "Stock Sessions",
                            data: data,
                            columns:
                                [...columns.slice(0, -1),
                                {
                                    title: "Scanned Count",
                                    dataIndex: "scanned_count",
                                    key: "scanned_count",
                                },
                                {
                                    title: "Expected Count",
                                    dataIndex: "expected_count",
                                    key: "expected_count",
                                }].filter((column) => column.key !== "percentage")
                        }]}
                    />

                    {permissions?.import_stock_transfer && <Button onClick={showModal} type="danger">Import PO</Button>}
                </div>
            </Content>

            <Modal title="Import PO File"
                maskClosable={false}
                open={isModalOpen}
                okButtonProps={{ hidden: true }}
                cancelButtonProps={{ type: 'danger' }}
                onCancel={handleCancel}>
                <ImportPOFile
                    key={importPoFileKey}
                    handleCancel={handleCancel}
                    inwardOrOutward={selected.inwardOrOutward}
                    getData={getData} />
            </Modal>

            <Table
                style={{ marginTop: '10px' }}
                columns={columns}
                dataSource={data}
                loading={loading}
                size="small"
                pagination={{
                    position: ["bottomRight"],
                    showSizeChanger: true,
                    defaultPageSize: parseInt(sessionStorage.getItem("inwardOutward-tablePageSize") || 10),
                    defaultCurrent: parseInt(sessionStorage.getItem("inwardOutward-tableCurrentPage") || 1),
                }}
                onChange={(pagination, filters, sorter, extra) => {
                    sessionStorage.setItem("inwardOutward-tablePageSize", pagination.pageSize);
                    sessionStorage.setItem("inwardOutward-tableCurrentPage", pagination.current);
                }}
            />

            <Helmet>
                <style>{`
                    .ant-table-tbody>tr.ant-table-row>td {
                        font-size: 13px !important;
                    }

                    .ant-table-thead {
                        font-weight: bold !important;
                        font-size: 14px !important;
                    }
                `}</style>
            </Helmet>

        </div>
    );
}

