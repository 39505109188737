import React, {useState } from "react";
import { Button, Input, message, DatePicker, Tabs, Spin, Select, TimePicker, } from 'antd';
import { useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import { MdOutlineEditOff } from "react-icons/md";

import { InputWithLabel } from '../../Components/Components';
import { validateApiKeys } from "../../Components/Validations";


const { TabPane } = Tabs;
/*
    Edit and Add Api_keys
        if isAddNew is true, then we will post else put

    state:
        api_keysDetails
        {
      key_id,
      key_name,
      comment,
      access_key,
      secret_key,
      created_time,
      expiry_time,
      updated_time,
      created_by,
      last_access_time,
      is_active
        }

    // TODO: Document this (MasterModules)
    master Modules we will get from API

*/

export default function EditApiKeys({
    isAddNew,
    selectedOrg,
    permissions={},

    homePath='/masters/api_keys',
}) {
    let keyId = parseInt(useParams().id);
    const navigate = useNavigate();

    // For having the active tab in the url (for refreshing)
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState(searchParams.get('tab') || 'details');
    const changeActiveTab = (key) => {
        setActiveTab(key);
        searchParams.set('tab', key);
        setSearchParams(searchParams, {replace: true});
    }

    const [loading, setLoading] = useState(true);

    // Here will be the default state of the form (For add new)
    // For documentation, see the comment above function 
    const [details, setDetails] = useState({
        key_id: 0,
        key_name: '',
        comment: '',
        access_key: '',
        secret_key: '',
        expiry_time: moment().add(2, 'days'),
        is_active: 1,
    });

    // This state is for validation Errors
    // Key is the name of the field and value is a json {errors: [{message, type}, {...}], help: ""}
    const [errors, setErrors] = useState({});
    
    
    const getDetails = async () => {
        setLoading(true);
        await axios.get('/api_keys/get_by_id', {
            params: {
                key_id: keyId,
                o_id: selectedOrg.orgId,
            }
        })
            .then(res => {
                const data = res.data.data;

                setDetails(d => ({
                    ...d,

                    key_id: data.key_id,
                    key_name: data.key_name,
                    comment: data.comment,
                    access_key: data.access_key,
                    secret_key: data.secret_key,
                    expiry_time: moment(data.expiry_time),
                    is_active: data.is_active,
                }));
            })
            .catch(err => {
                console.log(err);
                message.error('Error getting API key details');
                navigate(homePath);

            })
        setLoading(false);
    }    


    useEffect(() => {
        if(isAddNew){
            setLoading(false);
        }
        else{
            getDetails();
        }
    }, []);

    const setDetailsKey = (key, value) => {
        setDetails(d => ({
            ...d,
            [key]: value,
        }));
    }


    const handleSubmit = async () => {
        setLoading(true);

        let d, e, errorTab, errStatus, errorsIn;
        ({d, e, errorTab, errStatus, errorsIn} = validateApiKeys(details, errors, isAddNew, "details"));
        setDetails(prev => ({...prev, ...d}));  
        setErrors(prev => ({...prev, ...e}));


        if(errStatus === "error") {
            console.log("Error in form : ", errorsIn);
            setActiveTab(errorTab);

            setLoading(false);
            return;
        }

        if(isAddNew){
            // Send request to create new role
            // /api_keys/create
            // console.log(details);
            await axios.post('/api_keys/create', {
                ...details
            })
                .then(res => {
                    message.success("Api Key Created successfully");
                    navigate(homePath);
                })
                .catch(err => {
                    console.log(err);
                    message.error("Error creating api key !")
                })
        }
        else{
            // Send request to update role
            await axios.post('/api_keys/update', {
                ...details,
                o_id: selectedOrg.orgId,
            })
                .then(res => {
                    message.success("Api Key updated successfully");
                    navigate(homePath);
                })
                .catch(err => {
                    console.log(err);
                    message.error("Error updating api key !")
                })
        }
        setLoading(false);

    }


    return (
        <div className='my-form-outer'>
            <div className='my-form-header'>
                <span className='my-form-title'>
                    {isAddNew ? "Add" : "Edit"} Api Key 
                </span>
            </div>
            <div>
                <Tabs
                    tabBarExtraContent={
                        <div>
                            <Button type='danger' onClick={() => navigate(homePath)} loading={loading}>
                                Cancel
                            </Button>
                            <Button style={{marginLeft: '5px'}} type="primary" onClick={handleSubmit} loading={loading} disabled={!permissions.edit}>
                                {isAddNew ? "Add" : "Save"}
                            </Button>
                        </div>
                    }
                    activeKey={activeTab}
                    onChange={changeActiveTab}
                >
                    <TabPane className="my-form-tabpane-outer" tab="Settings Details" key="details">
                        <Spin 
                            spinning={loading || !permissions.edit} 
                            tip={(!loading && !permissions.edit) && <>You Cannot Edit</>} 
                            indicator={(!loading && !permissions.edit) && <MdOutlineEditOff />}
                        >
                            <div className="my-form-multiple-inline-input">
                                <InputWithLabel label="Key Name" error={errors.key_name} reqMark={true}>
                                    <Input
                                        value={details.key_name}
                                        className="my-form-input"
                                        onChange={(e) => setDetailsKey("key_name", e.target.value)}
                                        status={errors?.key_name?.errors?.[0]?.msg && "error"}
                                        disabled={!isAddNew}
                                    />
                                </InputWithLabel>
                                <InputWithLabel label="Access_Key" error={errors.access_key} reqMark={true}>
                                    <Input
                                        placeholder="Auto Generated"
                                        value={details.access_key}
                                        className="my-form-input"
                                        onChange={(e) => setDetailsKey("access_key", e.target.value)}
                                        status={errors?.access_key?.errors?.[0]?.msg && "error"}
                                        disabled={true}
                                        style={{width: '300px'}}
                                    />
                                </InputWithLabel>
                                <InputWithLabel label="Secret_Key" error={errors.secret_key} reqMark={true}>
                                    <Input
                                        placeholder="Auto Generated"
                                        value={details.secret_key}
                                        className="my-form-input"
                                        onChange={(e) => setDetailsKey("secret_key", e.target.value)}
                                        status={errors?.secret_key?.errors?.[0]?.msg && "error"}
                                        disabled={true}
                                        style={{width: '300px'}}
                                    />
                                </InputWithLabel>
                            </div>
                            <div className="my-form-multiple-inline-input">
                                <InputWithLabel label="is_active" error={errors.is_active} reqMark={true}>
                                    <Select
                                        value={details.is_active}
                                        className="my-form-input"
                                        onChange={(e) => setDetailsKey("is_active", e)}
                                        status={errors?.is_active?.errors?.[0]?.msg && "error"}
                                    >
                                        <Select.Option value={1}>Yes</Select.Option>
                                        <Select.Option value={0}>No</Select.Option>
                                    </Select>
                                </InputWithLabel>
                                <InputWithLabel label="Expiry Time" reqMark={true}>
                                    <DatePicker
                                        value={details.expiry_time}
                                        className="my-form-input"
                                        onChange={(date, dateString) => { setDetailsKey("expiry_time", date) }}
                                        showTime={{ format: 'HH:mm A' }}
                                        // minuteStep={15}
                                        allowClear={false}
                                        format="Do MMM YYYY, HH:mm A"
                                    />
                                    {/* value={initialLicenseValidity} format="Do MMM, YYYY"  */}
                                </InputWithLabel>
                            </div>
                            <div className="my-form-multiple-inline-input">
                                <InputWithLabel label={`Comment`} error={errors.comment} reqMark={false}>
                                    <Input.TextArea
                                        value={details.comment}
                                        style={{width: '500px'}}
                                        onChange={(e) => setDetailsKey("comment", e.target.value)}
                                        placeholder="Comments"
                                        autosize={{ minRows: 2, maxRows: 6 }}

                                        status={errors?.comment?.errors?.[0]?.msg && "error"}
                                    />
                                </InputWithLabel>
                            </div>

                        </Spin>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )  
}  