import { Button, Checkbox, message } from 'antd';
import axios from 'axios';
import React, { useState } from 'react'
import { InputWithLabel } from '../../Components/Components';
import { ExportToExcelButton } from '../../Components/ExportToExcel';

function ImportPOFile({
    getData,
    inwardOrOutward,
    key,
    handleCancel
}) {

    const [file, setFile] = useState()
    const [isUpdate, setIsUpdate] = useState(0)
    const [fileLoading, setFileLoading] = useState(false);
    const fileInput = document.getElementById("fileInput");

    const handleXlsxxFileChange = async (e) => {
        try {
            const file = e.target.files[0];
            setFile(file)
        } catch (error) {
            console.log("File reading error !!");
        }
    };

    const submitFile = async () => {

        const formData = new FormData();
        formData.append("file", file);
        setFileLoading(true)

        await axios
            .post(`/file_import/order_file/po?inward_outward=${inwardOrOutward}&is_update=${isUpdate}`, formData)
            .then((response) => {
                message.success("File uploaded successfully");
                setIsUpdate(0)
                getData()
                handleCancel()
                setFile(null)
                if (fileInput) {
                    fileInput.value = "";
                }
            }).catch((error) => {
                setFile(null)
                setIsUpdate(0)
                if (fileInput) {
                    fileInput.value = "";
                }
                if (error.response) {
                    console.log(error);
                    message.error(`${error.response?.status} Error ${error.response?.data?.detail}`)
                } else {
                    console.log(error);
                    message.error(error?.message)
                }
            });
        setFileLoading(false)
    }

    const po_file_columns = [
        {
            title: "Scan_Site_Code",
            dataIndex: "Scan_Site_Code",
            key: "Scan_Site_Code",
        },
        {
            title: "Reference_Site_Code",
            dataIndex: "Reference_Site_Code",
            key: "Reference_Site_Code",
        },
        {
            title: "Reference_Number",
            dataIndex: "Reference_Number",
            key: "Reference_Number",
        },
        {
            title: "Box_Number",
            dataIndex: "Box_Number",
            key: "Box_Number",
        },
        {
            title: "Reference_Date",
            dataIndex: "Reference_Date",
            key: "Reference_Date",
        },
        {
            title: "Product_Code",
            dataIndex: "Product_Code",
            key: "Product_Code",
        },
        {
            title: "Quantity",
            dataIndex: "Quantity",
            key: "Quantity",
        },
        {
            title: "Scan_Site_Name",
            dataIndex: "Scan_Site_Name",
            key: "Scan_Site_Name",
        },
        {
            title: "Reference_Site_Name",
            dataIndex: "Reference_Site_Name",
            key: "Reference_Site_Name",
        },

    ]

    const po_file_dummy_data = [
        {
            Scan_Site_Code: "24054",
            Reference_Site_Code: "24054",
            Reference_Number: "PO/00000414/23-24",
            Box_Number: "PO/00000414/23-24",
            Reference_Date: "",
            Product_Code: "8907546381377",
            Quantity: "10",
            Scan_Site_Name: "Crimsoune WH",
            Reference_Site_Name: "Crimsoune_storename"
        },
    ]

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
            <InputWithLabel isInline label="PO Xlsx Template">
                <ExportToExcelButton
                    fileName={"Sample PO Xlsx File"}
                    sheets={[{ sheetName: "Sample PO Xlsx File", data: po_file_dummy_data, columns: po_file_columns }]}
                    buttonName="Download"
                />
            </InputWithLabel>

            <InputWithLabel isInline label="Upload PO File">
                <input
                    style={{ marginLeft: "0px" }}
                    id="fileInput"
                    type="file"
                    accept=".xlsx"
                    onChange={handleXlsxxFileChange}
                />
            </InputWithLabel>

            <InputWithLabel isInline label={"Is file replaced ?"}>
                <Checkbox style={{ marginTop: '2px' }}
                    checked={isUpdate == 1 ? true : false}
                    onChange={(e) => setIsUpdate(e.target.checked ? 1 : 0)}>
                    Replaced
                </Checkbox>
            </InputWithLabel>

            <Button
                style={{ marginTop: "18px", marginRight: '10px' }}
                onClick={submitFile}
                type="primary"
                disabled={!file}
                loading={fileLoading}>
                Import
            </Button>
        </div>
    )
}

export default React.memo(ImportPOFile)