import React, { useMemo, useRef, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Table, message, Tabs, Divider, Statistic, Tag, Space, Descriptions, Checkbox, Radio } from 'antd';
import { useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { ExportToExcelButton, exportToExcel } from '../../Components/ExportToExcel';
import useFilterOptions from '../../Components/useFilterOptions';
import SearchComponent from '../../Masters/GeneralComponents/SearchComponent';
import CoptToClipBoardInward from './CopyInwardOutwardDetails';
import { ProductCodeComponent } from '../Components/ProductCodeDetailsModal';
import { useSelector } from 'react-redux';
import { useReactToPrint } from "react-to-print";
import { Content } from 'antd/lib/layout/layout';

const { TabPane } = Tabs;

export default function InwardOutwardReport({ selectedOrg, permissions = {}, homePath = "/inventory/inward-outward", }) {

    const statusColorMap = { pending: "#cf1322", done: "#389e0d", scanned: "#faad14" };

    let stockTransferId = parseInt(useParams().id);
    const navigate = useNavigate();

    // For having the active tab in the url (for refreshing)
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState(searchParams.get('tab') || 'box-wise');
    const changeActiveTab = (key) => {
        setActiveTab(key);
        searchParams.set('tab', key);
        setSearchParams(searchParams, { replace: true });
    }

    const [sessionData, setSessionData] = useState({});
    const [loading, setLoading] = useState(true);
    const [showExtraTag, setShowExtraTag] = useState(false);

    const getData = async () => {
        setLoading(true);
        await axios.get(`/reports/stock_transfer_info`, {
            params: {
                st_id: stockTransferId,
                to_show_extra_tags: showExtraTag
            }
        })
            .then((res) => {
                const data = res.data.data;
                console.log(data);
                setSessionData(data);
            })
            .catch((err) => {
                console.log(err);
                message.error("Error fetching stock transfer details");
                setSessionData({});
            });
        setLoading(false);
    };

    useEffect(() => {
        getData();
    }, [stockTransferId, showExtraTag]);

    return (
        <div className='my-form-outer'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ textDecoration: 'none', fontSize: '18px', fontWeight: 'unset' }}>
                    <b> {sessionData?.data?.reference_dt}  ({sessionData?.data?.inward_outward})</b>
                </span>
                <Space style={{ gap: 20 }}>
                    <ExportRawScannedProductCodes
                        stockTransferId={stockTransferId}
                        permissions={permissions}
                        fileName={`product-codes-${sessionData?.data?.inward_outward}-${sessionData?.data?.reference_no}`}
                    />

                    <Button type='danger' onClick={() => navigate(homePath + `?report=${sessionData?.data?.inward_outward}`)}>
                        Back
                    </Button>
                </Space>
            </div>

            <Content
                style={{
                    padding: 10,
                    margin: 5,
                    minHeight: 40,
                    background: "#F5F7F8",
                    borderRadius: "#fff",
                }}>

                <Descriptions column={2} style={{ marginLeft: 20 }} >
                    <Descriptions.Item label="Reference Number" labelStyle={{ fontSize: 15, marginTop: -4 }} contentStyle={{ fontWeight: 'bold', fontSize: 18, marginTop: -7 }}>{sessionData?.data?.reference_no}</Descriptions.Item>
                    <Descriptions.Item label="Site" labelStyle={{ fontSize: 15, marginTop: -4 }} contentStyle={{ fontWeight: 'bold', fontSize: 18, marginTop: -7 }}>{sessionData?.data?.site}</Descriptions.Item>
                    <Descriptions.Item label="Reference Site" labelStyle={{ fontSize: 15, marginTop: -4 }} contentStyle={{ fontWeight: 'bold', fontSize: 18, marginTop: -7 }}>{sessionData?.data?.reference_site}</Descriptions.Item>
                    <Descriptions.Item label="Status" labelStyle={{ fontSize: 15, marginTop: -4 }}>
                        <Tag color={statusColorMap[sessionData?.data?.status] || "#000000"} style={{ fontWeight: "700" }}>
                            {sessionData?.data?.status.toUpperCase()}
                        </Tag></Descriptions.Item>
                </Descriptions>
                <Divider />
                <div style={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    marginTop: 10,
                    gap: 50
                }}>
                    <Statistic
                        loading={loading}
                        title={<span style={{ color: 'black', fontSize: 14 }}>Valid Count</span>}
                        valueStyle={{ fontSize: 22, fontWeight: 'bold' }}
                        value={sessionData?.valid_count}
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    />
                    <Statistic
                        loading={loading}
                        title={<span style={{ color: 'black', fontSize: 14 }}>Expected Count</span>}
                        valueStyle={{ fontSize: 22, fontWeight: 'bold' }}
                        value={sessionData?.expected_count}
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    />
                    <Statistic
                        loading={loading}
                        title={<span style={{ color: 'black', fontSize: 14 }}>Total Scanned Count</span>}
                        valueStyle={{ fontSize: 22, fontWeight: 'bold' }}
                        value={sessionData?.total_count}
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    />
                    <Statistic
                        loading={loading}
                        title={<span style={{ color: 'black', fontSize: 14 }}>Extra Count</span>}
                        valueStyle={{ fontSize: 22, fontWeight: 'bold' }}
                        value={sessionData?.extra_count}
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    />
                    <Statistic
                        loading={loading}
                        title={<span style={{ color: 'red', fontSize: 14 }}>Invalid Count</span>}
                        valueStyle={{ fontSize: 22, fontWeight: 'bold', color: 'red' }}
                        value={sessionData?.invalid_count}
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderBottom: "2px solid red" }}
                    />
                    <Statistic
                        loading={loading}
                        title={<span style={{ color: 'black', fontSize: 14 }}>In-Stock Count</span>}
                        valueStyle={{ fontSize: 22, fontWeight: 'bold' }}
                        value={sessionData?.in_stock}
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    />
                    <Statistic
                        loading={loading}
                        title={<span style={{ color: 'black', fontSize: 14 }}>Percentage Scanned</span>}
                        valueStyle={{ fontSize: 22, fontWeight: 'bold' }}
                        suffix={"%"}
                        value={(sessionData?.valid_count / sessionData?.expected_count) * 100 || 0}
                        precision={2}
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    />

                    <Statistic
                        title={<span style={{ color: 'black', fontSize: 14 }}>Extra Tags</span>}
                        valueRender={() => (
                            <Radio.Group onChange={() => setShowExtraTag(!showExtraTag)} value={showExtraTag}>
                                <Radio style={{ fontSize: 18, fontWeight: 'bold' }} value={false}>Exclude Extra</Radio>
                                <Radio style={{ fontSize: 18, fontWeight: 'bold' }} value={true}>Include Extra</Radio>
                            </Radio.Group>
                        )}
                    />
                </div>

            </Content>

            <div>
                <Tabs
                    style={{ margin: 5 }}
                    activeKey={activeTab}
                    onChange={changeActiveTab}
                >
                    <TabPane className="my-form-tabpane-outer" tab="Box Wise" key="box-wise">
                        <BoxWiseComparison
                            sessionData={sessionData}
                            showExtraTag={showExtraTag}
                            stockTransferId={stockTransferId}
                            ref_site={sessionData?.data?.reference_site}
                        />
                    </TabPane>

                    <TabPane tab="Barcode Wise" key="barcode-wise">
                        <BarcodeWiseComparison
                            sessionData={sessionData}
                            showExtraTag={showExtraTag}
                            stockTransferId={stockTransferId}
                        />
                    </TabPane>

                    <TabPane tab="EPC Comparison" key="epc-wise">
                        <EPCWiseComparison
                            sessionData={sessionData}
                            stockTransferId={stockTransferId}
                        />
                    </TabPane>

                </Tabs>
            </div>
            <Helmet>
                <style>{`
                    .error-row {
                        background-color: var(--errorBgColor);
                    }
                    .success-row {
                        background-color: var(--successBgColor);
                    }
                    .warning-row {
                        background-color: var(--warningBgColor);
                    }
                    .info-row {
                        background-color: var(--infoBgColor);
                    }


                    .ant-table-tbody>tr.ant-table-row:hover>td {
                        background-color: inherit !important;
                        /* border: 1px solid black; */
                        cursor: pointer;
                        {/* font-weight: bold; */}
                    }

                    .ant-table-tbody>tr.ant-table-row>td {
                        font-size: 13px !important;
                    }

                    .ant-tabs-tab {
                        font-size: 14px !important;
                    }

                    .ant-table-thead {
                        font-weight: bold !important;
                        font-size: 14px !important;
                    }

                    .ant-table-column-sort{
                        background: none !important;
                    }
                    .ant-table-column-background-none{
                        background: none !important;
                    }
                `}</style>
            </Helmet>
        </div>
    );
}

const ExportRawScannedProductCodes = ({
    stockTransferId,
    permissions = {},
    fileName = `product-codes-${stockTransferId}`,
}) => {
    const [loading, setLoading] = useState(false);

    const getData = async () => {
        setLoading(true);
        await axios.get(`/reports/count_of_product_code`, {
            params: {
                st_id: stockTransferId,
            }
        })
            .then(async (res) => {
                const data = res.data.data;
                const scannedProductCodes = data;

                let productCodeCount = [];
                // we will add the product code the number of times it is scanned
                scannedProductCodes.forEach((item) => {
                    for (let i = 0; i < item.count; i++) {
                        productCodeCount.push({
                            product_code: item.product_code,
                        });
                    }
                });

                exportToExcel({
                    fileName: fileName,
                    sheets: [
                        {
                            sheetName: 'Product Codes and Count',
                            data: scannedProductCodes,
                            columns: [
                                {
                                    title: 'Product Code',
                                    dataIndex: 'product_code',
                                    key: 'product_code',
                                },
                                {
                                    title: 'Count',
                                    dataIndex: 'count',
                                    key: 'count',
                                }
                            ],
                        },
                        {
                            sheetName: 'Product Codes',
                            data: productCodeCount,
                            columns: [
                                {
                                    title: 'Product Code',
                                    dataIndex: 'product_code',
                                    key: 'product_code',
                                }
                            ],
                        }
                    ]
                })

            })
            .catch((err) => {
                console.log(err);
                message.error("Error fetching Data");
            });
        setLoading(false);
    };

    return (
        <Button type='primary' onClick={getData} loading={loading}>
            Export Scanned Product Codes
        </Button>
    );
}

const PDFDocument = ({ data, sessionData }) => {

    const columns = [
        {
            title: 'Sr . No',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Product Code',
            dataIndex: 'product_code',
            key: 'product_code',
        },
        {
            title: 'Expected Quantity',
            dataIndex: 'expected_qty',
            key: 'expected_qty',
        },
        {
            title: 'Found Quantity',
            dataIndex: 'found_qty',
            key: 'found_qty',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        }
    ];

    return (
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', }}>
            <div>
                <h1 style={{ textAlign: 'center' }}>{sessionData?.data?.inward_outward == 'inward' ? 'Inward' : 'Outward'} Report</h1>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <h3>Invoice Number : {sessionData?.data?.reference_no}</h3>
                        <h3>Invoice Date : {sessionData?.data?.reference_dt}</h3>
                        <h3>Scanned Date: {moment(sessionData?.data?.updated_time).format("DD MMM yyyy, hh:mm A")}</h3>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <h3>Stock Transfer ID : {sessionData?.data?.stock_transfer_id}</h3>
                        <h3>Site : {sessionData?.data?.site}</h3>
                    </div>
                </div>
                <Table
                    dataSource={data}
                    columns={columns}
                    pagination={false}
                />
            </div>
        </div>

    )
}

const BarcodeWiseComparison = ({ sessionData, stockTransferId, showExtraTag }) => {
    const [masterState, setMasterState] = useState([]);
    const contentRef = useRef(null);

    // The below state is after the search is done
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    // The below var is memoized to avoid re-rendering of the filters when the data is changed, If not, the filters will be calculated again and again infinitely
    const filterableColumns = useMemo(() =>
        ['status', 'product_code']
        , []);
    const [filterOptions, setFilterOptions] = useFilterOptions(masterState, filterableColumns);

    const [categoryColumns, setCategoryColumns] = useState([]);

    const getData = async () => {
        setLoading(true);
        await axios.get(`/report/inward_outward/erpvsrfid`, {
            params: {
                st_id: stockTransferId,
                to_show_extra_tags: showExtraTag
            }
        })
            .then(res => {
                const data = res.data.data;
                const newColumns = res.data.columns;

                setMasterState(data);
                setCategoryColumns(newColumns);
                setData(data);
            })
            .catch(err => {
                console.log(err);
                message.error('Error fetching Barcode_Wise Comparison Data');
            })
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, [stockTransferId, showExtraTag]);



    const columns = [
        {
            title: 'Index',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Product Code',
            dataIndex: 'product_code',
            key: 'product_code',
            filters: filterOptions.product_code,
            onFilter: (value, record) => record.product_code == value,
            filterSearch: true,
            render: (text, record) => <ProductCodeComponent productCode={text} />,
        },
        // {
        //     title: 'Reference Number',
        //     dataIndex: 'reference_no',
        //     key: 'reference_no',
        // },
        {
            title: 'Expected Quantity',
            dataIndex: 'expected_qty',
            key: 'expected_qty',
            width: 150,
        },
        {
            title: 'Found Quantity',
            dataIndex: 'found_qty',
            key: 'found_qty',
            width: 150,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            filters: filterOptions.status,
            onFilter: (value, record) => record.status == value,
            filterSearch: true,
        }

    ];

    const generatePDF = useReactToPrint({
        content: () => contentRef.current,
        documentTitle: `barcode-wise-${stockTransferId}`,
    });

    return (
        <div className='my-form-outer' style={{ paddingBottom: '20px' }}>
            <div className='my-form-header'>
                <span className="actions-outer-div">
                    <Button onClick={generatePDF}>Export to PDF</Button>
                    <ExportToExcelButton
                        fileName={`barcode-wise-${stockTransferId}`}
                        sheets={[
                            {
                                sheetName: `barcode-wise-${stockTransferId}`,
                                data: data,
                                columns: columns.slice(1, columns.length),
                            },
                        ]}
                        buttonDisabled={loading}
                        buttonLoading={loading}
                    />
                </span>
                <SearchComponent
                    masterState={masterState}
                    state={data}
                    setState={setData}
                    searchOptions={[
                        { keyName: 'product_code', label: 'Product Code' },
                        { keyName: 'status', label: 'Status' },
                    ]}
                />
            </div>

            <div>
                <Table
                    loading={loading}
                    dataSource={data}
                    columns={columns}
                    size='small'
                    bordered
                    pagination={false}
                    rowClassName={(record, index) => {
                        if (record.status === 'LOW') {
                            return 'error-row';
                        }
                        else if (record.status === 'MATCHING') {
                            return 'success-row';
                        }
                        else {
                            return 'warning-row';
                        }
                    }}
                    rowKey={record => record.product_code}
                    expandable={{
                        rowExpandable: record => record?.product_code?.length,
                        expandedRowRender: record => (
                            <ExpandBoxWiseProductCodes
                                stockTransferId={stockTransferId}
                                showExtraTag={showExtraTag}
                                product_code={record.product_code}
                            />
                        )
                    }}
                />

                <div style={{ display: 'none', justifyContent: 'center', }}>
                    <div style={{ width: "100%", alignItems: 'center', borderStyle: 'groove', padding: "20px", borderWidth: '0.5px' }} ref={contentRef}>
                        <PDFDocument data={data} sessionData={sessionData} />
                    </div>
                </div>
            </div>

        </div>
    );
}

const EPCWiseComparison = ({ sessionData, stockTransferId }) => {
    const [masterState, setMasterState] = useState([]);

    // The below state is after the search is done
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    // The below var is memoized to avoid re-rendering of the filters when the data is changed, If not, the filters will be calculated again and again infinitely
    const filterableColumns = useMemo(() =>
        ['stock_status']
        , []);
    const [filterOptions, setFilterOptions] = useFilterOptions(masterState, filterableColumns);



    const getData = async () => {
        setLoading(true);
        await axios.get(`/report/inward_outward/epc_comparison`, {
            params: {
                st_id: stockTransferId,
            }
        })
            .then(res => {
                const data = res.data.data;
                console.log(data);
                setMasterState(data);
                setData(data);
            })
            .catch(err => {
                console.log(err);
                message.error('Error fetching EPC Comparison Data');
            })
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, [stockTransferId]);

    const columns = [
        {
            title: 'Index',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            render: (text, record, index) => index + 1,
        },
        {
            title: 'EPC',
            dataIndex: 'epc',
            key: 'epc',
        },
        {
            title: 'Product Code',
            dataIndex: 'product_code',
            key: 'product_code',
            render: (text, record) => <ProductCodeComponent productCode={text} />,
        },
        {
            title: 'Stock Status',
            dataIndex: 'stock_status',
            key: 'stock_status',
            filters: filterOptions.stock_status,
            onFilter: (value, record) => record.stock_status == value,
            filterSearch: true,
        },
    ];


    return (
        <div className='my-form-outer' style={{ paddingBottom: '20px' }}>
            <div className='my-form-header'>
                <span className="actions-outer-div">
                    <ExportToExcelButton
                        fileName={`EPC-Comparison-${stockTransferId}`}
                        sheets={[
                            {
                                sheetName: `EPC-Comparison-${stockTransferId}`,
                                data: data,
                                columns: columns.slice(1, columns.length),
                            },
                        ]}
                        buttonDisabled={loading}
                        buttonLoading={loading}
                    />
                </span>
                <SearchComponent
                    masterState={masterState}
                    state={data}
                    setState={setData}
                    searchOptions={[
                        { keyName: 'epc', label: 'EPC' },
                        { keyName: 'product_code', label: 'Product Code' },
                        { keyName: 'stock_status', label: 'Stock Status' },
                    ]}
                />
            </div>
            <Table
                loading={loading}
                dataSource={data}
                columns={columns}
                size='small'
                bordered
                pagination={false}
                rowClassName={(record, index) => {
                    if (record.stock_status === 'NOT FOUND') {
                        return 'error-row';
                    }
                    else if (record.stock_status === 'MATCHING') {
                        return 'success-row';
                    }
                    else {
                        return 'warning-row';
                    }
                }}

            />
        </div>
    );
}


const BoxWiseComparison = ({ sessionData, stockTransferId, showExtraTag }) => {
    const [masterState, setMasterState] = useState([]);
    const orgID = useSelector((state) => state.globalReducer.selectedOrg.orgId);
    const navigate = useNavigate();
    // The below state is after the search is done
    const [data, setData] = useState([]);
    const [s_id, setSID] = useState([]);

    const [loading, setLoading] = useState(true);

    // The below var is memoized to avoid re-rendering of the filters when the data is changed, If not, the filters will be calculated again and again infinitely
    const filterableColumns = useMemo(() =>
        ['status', 'box_no']
        , []);
    const [filterOptions, setFilterOptions] = useFilterOptions(masterState, filterableColumns);


    const getData = async () => {
        setLoading(true);
        await axios.get(`/reports/box_wise_data`, {
            params: {
                st_id: stockTransferId,
                ref_site_name: sessionData?.data?.reference_site,
                to_show_extra_tags: showExtraTag
            }
        })
            .then(res => {
                const data = res.data.data;
                console.log(res.data.st_id?.[0].stock_transfer_id);
                setMasterState(data);
                setSID(res.data.st_id?.[0].stock_transfer_id)
                setData(data);
            })
            .catch(err => {
                console.log(err);
                message.error('Error fetching Boxwise Data');
            })
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, [stockTransferId, showExtraTag]);


    function changeID(record) {
        navigate(`/inventory/inward-outward/${s_id}?orgId=${orgID}&tab=box-wise`)
        window.location.reload();
    }


    const columns = [
        {
            title: 'Index',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Box No',
            dataIndex: 'box_no',
            key: 'box_no',
            width: 150,
            filters: filterOptions.box_no,
            onFilter: (value, record) => record.box_no == value,
            filterSearch: true,
        },
        {
            title: 'Expected Quantity',
            dataIndex: 'expected_qty',
            key: 'expected_qty',
            width: 150,
        },
        {
            title: 'Found Quantity',
            dataIndex: 'found_qty',
            key: 'found_qty',
            width: 150,
        },
        {
            title: 'Outward Count',
            dataIndex: 'outward_count',
            key: 'outward_count',
            width: 150,
            render: (text, record) => {
                const color = record.outward_count === record.found_qty ? 'green' : 'red';
                if (record.outward_count === 'Multiple Outward' || record.outward_count === 'No Outward Data') {
                    return (
                        <Tag color={color}>{record.outward_count}</Tag>
                    );
                }
                else {
                    return (
                        <Link to={`/inventory/inward-outward/${s_id}?orgId=${orgID}&tab=box-wise`}>
                            <Tag color={color}>{record.outward_count}</Tag>
                        </Link>
                    );
                }
                // return (
                // <Link to={`/rfid-reports/inward-outward/${s_id}?orgId=${orgID}&tab=box-wise`}>
                //     <Tag color={color}>{record.outward_count}</Tag>
                // </Link>
                // );
            },
            hidden: sessionData?.data?.inward_outward === 'inward' ? false : true,
        },
        {
            title: 'Action',
            key: 'action',
            width: '80px',
            render: (_, record) => (
                <div className='actions-outer-div'>
                    {/* <InwardExportToExcel order_id={record.order_id} invoice_no={record.order_number} /> */}
                    <CoptToClipBoardInward order_id={record.order_id} showExtraTag={showExtraTag} invoice_no={record.order_number} box_no={record.box_no} stockTransferId={stockTransferId} />
                </div>
            ),
        },


    ].filter((item) => !item.hidden);

    return (
        <div className='my-form-outer' style={{ paddingBottom: '20px' }}>
            <div className='my-form-header'>
                <span className="actions-outer-div">
                    <ExportToExcelButton
                        fileName={`box-wise-${stockTransferId}`}
                        sheets={[
                            {
                                sheetName: `box-wise-${stockTransferId}`,
                                data: data,
                                columns: columns.slice(1, columns.length - 1),
                            },
                        ]}
                        buttonDisabled={loading}
                        buttonLoading={loading}
                    />
                </span>
                <SearchComponent
                    masterState={masterState}
                    state={data}
                    setState={setData}
                    searchOptions={[
                        { keyName: 'box_no', label: 'Box No' },
                    ]}
                />
            </div>
            <Table
                loading={loading}
                dataSource={data}
                columns={columns}
                size='small'
                bordered
                pagination={false}
                rowClassName={(record, index) => {
                    if (record.found_qty < record.expected_qty) {
                        return 'error-row';
                    }
                    else if (record.found_qty > record.expected_qty) {
                        return 'warning-row';
                    }
                    else if (record.found_qty == record.expected_qty) {
                        return 'success-row';
                    }
                }}
                rowKey={record => record.box_no}
                expandable={{
                    rowExpandable: record => record?.box_no?.length,
                    expandedRowRender: record => (
                        <ExpandProductCodeDetails
                            stockTransferId={stockTransferId}
                            showExtraTag={showExtraTag}
                            box_no={record.box_no}
                        />
                    ),
                }}
            />
        </div>
    );
}


const ExpandProductCodeDetails = ({ stockTransferId, box_no, showExtraTag }) => {
    const [masterState, setMasterState] = useState([]);


    // The below state is after the search is done
    const [data, setData] = useState([]);
    const [inward_outward, setinward_outwarddata] = useState([]);
    const [loading, setLoading] = useState(true);

    // The below var is memoized to avoid re-rendering of the filters when the data is changed, If not, the filters will be calculated again and again infinitely
    const filterableColumns = useMemo(() =>
        []
        , []);
    const [filterOptions, setFilterOptions] = useFilterOptions(masterState, filterableColumns);

    const [categoryColumns, setCategoryColumns] = useState([]);

    const getData = async () => {
        setLoading(true);
        await axios.get(`/reports/expand_product_code_details`, {
            params: {
                st_id: stockTransferId,
                box_no: box_no,
                to_show_extra_tags: showExtraTag
            }
        })
            .then(res => {
                const data = res.data.data;
                const inward_outward = res.data.inward_outward;
                const newColumns = res.data.columns;

                setMasterState(data);
                setCategoryColumns(newColumns);
                setData(data);
                setinward_outwarddata(inward_outward);
            })
            .catch(err => {
                console.log(err);
                message.error('Error fetching Product Code Data');
            })
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, [stockTransferId, showExtraTag]);



    const columns = [
        {
            title: 'Index',
            dataIndex: 'index',
            key: 'index',
            width: 30,
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Product Code',
            dataIndex: 'product_code',
            key: 'product_code',
            width: 150,
            render: (text, record) => <ProductCodeComponent productCode={text} />,
        },
        {
            title: 'Expected Quantity',
            dataIndex: 'expected_qty',
            key: 'expected_qty',
            width: 150,
        },
        {
            title: 'Found Quantity',
            dataIndex: 'found_qty',
            key: 'found_qty',
            width: 150,
        },
        {
            title: 'Outward Count',
            dataIndex: 'outward_count',
            key: 'outward_count',
            width: 150,
            hidden: inward_outward === 'inward' ? false : true,
        }
    ].filter((item) => !item.hidden);

    return (
        <div className='my-form-outer' style={{ paddingBottom: '20px', paddingLeft: '60px' }}>
            <div className='my-form-header'>
                <span className="actions-outer-div">
                    <ExportToExcelButton
                        fileName={`box-no-${box_no}`}
                        sheets={[
                            {
                                data: data,
                                columns: columns.slice(1, columns.length),
                            },
                        ]}
                        buttonDisabled={loading}
                        buttonLoading={loading}
                        buttonProps={{ type: 'default' }}
                    />
                </span>
                <SearchComponent
                    masterState={masterState}
                    state={data}
                    setState={setData}
                    searchOptions={[
                        { keyName: 'product_code', label: 'Product Code' },
                    ]}
                />
            </div>
            <Table
                loading={loading}
                dataSource={data}
                columns={columns}
                size='small'
                bordered
                pagination={false}
                rowClassName={(record, index) => {
                    if (record.found_qty < record.expected_qty) {
                        return 'error-row';
                    }
                    else if (record.found_qty > record.expected_qty) {
                        return 'warning-row';
                    }
                    else if (record.found_qty == record.expected_qty) {
                        return 'success-row';
                    }
                }}
            />
        </div>
    );
}


const ExpandBoxWiseProductCodes = ({ stockTransferId, product_code, showExtraTag }) => {
    const [masterState, setMasterState] = useState([]);


    // The below state is after the search is done
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    // The below var is memoized to avoid re-rendering of the filters when the data is changed, If not, the filters will be calculated again and again infinitely
    const filterableColumns = useMemo(() =>
        ['box_no']
        , []);
    const [filterOptions, setFilterOptions] = useFilterOptions(masterState, filterableColumns);

    const getData = async () => {
        setLoading(true);
        await axios.get(`/reports/expand_box_wise_product_codes`, {
            params: {
                st_id: stockTransferId,
                product_code: product_code,
                to_show_extra_tags: showExtraTag
            }
        })
            .then(res => {
                const data = res.data.data;

                setMasterState(data);
                setData(data);
            })
            .catch(err => {
                console.log(err);
                message.error('Error fetching Boxwise Data');
            })
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, [stockTransferId, showExtraTag]);



    const columns = [
        {
            title: 'Index',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Box No',
            dataIndex: 'box_no',
            key: 'box_no',
            width: 150,
            filters: filterOptions.box_no,
            onFilter: (value, record) => record.box_no == value,
            filterSearch: true,
        },
        {
            title: 'Expected Quantity',
            dataIndex: 'expected_qty',
            key: 'expected_qty',
            width: 150,
        },
        {
            title: 'Found Quantity',
            dataIndex: 'found_qty',
            key: 'found_qty',
            width: 150,
        },
    ];

    return (
        <div className='my-form-outer' style={{ paddingBottom: '20px', paddingLeft: '60px' }}>
            <div className='my-form-header'>
                <span className="actions-outer-div">
                    <ExportToExcelButton
                        fileName={`product-code-${product_code}`}
                        sheets={[
                            {
                                data: data,
                                columns: columns.slice(1, columns.length),
                            },
                        ]}
                        buttonDisabled={loading}
                        buttonLoading={loading}
                        buttonProps={{ type: 'default' }}
                    />
                </span>
                <SearchComponent
                    masterState={masterState}
                    state={data}
                    setState={setData}
                    searchOptions={[
                        { keyName: 'box_no', label: 'Box No' },
                    ]}
                />
            </div>
            <Table
                loading={loading}
                dataSource={data}
                columns={columns}
                size='small'
                bordered
                pagination={false}
                rowClassName={(record, index) => {
                    if (record.found_qty < record.expected_qty) {
                        return 'error-row';
                    }
                    else if (record.found_qty > record.expected_qty) {
                        return 'warning-row';
                    }
                    else if (record.found_qty == record.expected_qty) {
                        return 'success-row';
                    }
                }}
            />
        </div>
    );
}
