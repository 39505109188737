import { Form, Input, Button, Typography, message, Card, Spin } from "antd";
import jwt_decode from "jwt-decode";
import { useLocation, useNavigate } from "react-router-dom";
// import "antd/dist/antd.css";
import axios from 'axios';
import { useState } from "react";
const { Title } = Typography;



const Login = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(false)

    const onFinish = async (values) => {
        setLoading(true)
        await axios.post("/admin-api/login", {
            username: values.username,
            password: values.password
        }).then(res => {
            // console.log(res);
            localStorage.setItem("AdminToken", res.data.data.token);
            localStorage.setItem("RoleToken", res.data.data.role_token);
            // navigate();
            const decoded = jwt_decode(res.data.data.token);
            // window.location.href = location?.state?.from || "/";
            if (decoded?.is_super_admin) {
                window.location.href = "/dashboard/global-reports";
            } else {
                window.location.href = "/dashboard/store-reports"
            }
        }).catch(err => {
            if (err.response?.data) {
                message.error("Login Failed : " + err.response?.data?.detail);
            } else {
                message.error("Login Failed : " + err?.message);
            }
            setLoading(false);
        })
        setLoading(false)
    };

    const onFinishFailed = errorInfo => {
        console.log("Failed:", errorInfo);
        setLoading(false)
    };

    return (
        <>
            <div className="login">
                <div className="appAside">{/* <img src="loginpage.jpg" height="50%" width="50%"></img> */}</div>

                {/* <div className="appForm"> */}
                <div
                    style={{
                        height: "100vh",
                        backgroundImage: `url("/Loginpage.jpg")`,
                        backgroundColor: "white",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "bottom",
                        backgroundSize: "cover",
                        textAlign: "center",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            minWidth: "100%",
                            minHeight: "100%",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Card
                            bordered={true}
                            hoverable={true}
                            style={{
                                // margin: "Auto",
                                width: "400px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "10px",
                                padding: "10px 0px 0px 0px",
                                boxShadow: "5px 10px 50px black"

                            }}
                        >
                            <img src="/icon-512x512.png" style={{ height: "7rem" }} />
                            <Title style={{ color: "black", marginBottom: "5px", fontSize: "25px" }}>TagSmart</Title>
                            <p style={{ color: "black", marginBottom: "30px", fontSize: "20px", fontWeight: "500" }}>Inventory Management</p>


                            <Form
                                name="basic"
                                // labelCol={{
                                //     span: 8,
                                // }}
                                // wrapperCol={{
                                //     span: 16,
                                // }}

                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Form.Item
                                    style={{ color: "black" }}
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your username!",
                                        },
                                    ]}
                                >
                                    <Input size="large" autoFocus placeholder="Username" />
                                </Form.Item>

                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your password!",
                                        },
                                    ]}
                                >
                                    <Input.Password size="large" placeholder="Password" />
                                </Form.Item>

                                <Form.Item
                                // wrapperCol={{
                                //     offset: 8,
                                //     span: 16,
                                // }}
                                >
                                    {/* {loading ? <Spin /> : */}
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        size="large"
                                        loading={loading}
                                        style={{ backgroundColor: "#263552", color: "White", borderRadius: "5px", width: "16rem", height: "2.5rem" }}
                                    >
                                        Submit
                                    </Button>
                                    {/* } */}
                                    <p style={{ marginTop: "20px" }}>Version {process.env.REACT_APP_VERSION}</p>
                                </Form.Item>
                            </Form>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Login;

