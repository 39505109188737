import React, { useEffect, useState } from "react";
import SiteTreeMultiSelect from "../Components/SiteTreeMultiSelect";
import { Button, Form, Input, InputNumber, message, Table, Modal, Select } from "antd";
import { InputWithLabel } from "../../Components/Components";
import axios from "axios";
import SiteTreeSelectAuto from "../Components/SiteTreeSelectAuto";
import { read, utils } from "xlsx";
import { ExportToExcelButton } from "../../Components/ExportToExcel";

export default function Printing({ selectedOrg = {}, editPath = "/tools/printing" }) {
    const [loading, setLoading] = useState(false);
    const [productCode, setProductCode] = useState("");
    const [jobName, setJobName] = useState("");
    const [qty, setQty] = useState(0);
    const [dataJSON, setDataJSON] = useState([]);
    const [importedProductCodeData, setImportedProductCodeData] = useState([]);
    const [fileType, setFileType] = useState();

    const [selected, setSelected] = useState({
        site_id: 0,
    });
    const [modalVisible, setModalVisible] = useState(false);
    const currentDate = new Date().toLocaleString().replace(/[/:]/g, "-").replace(",", "").replace(/ /g, "_");

    useEffect(() => {
        setDataJSON([]);
    }, [selectedOrg.orgId]);

    const setSelectedKey = (key, value) => {
        setSelected((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const addDataJSON = async () => {
        if (productCode == "" || qty <= 0) {
            message.error("Please enter product code and quantity");
            return;
        }

        setLoading(true);
        await axios
            .get(`/chunmun_printing/get_printing_data_v2?product_code=${productCode}&qty=${qty}`)
            .then((res) => {
                const data = res.data.data;
                console.log("DATA : ", data);
                if (dataJSON.find((item) => item.product_code === productCode)) {
                    message.error("Product code already present");
                    return;
                } else {
                    setDataJSON([
                        ...dataJSON,
                        {
                            product_code: data.product_code ? data.product_code : "",
                            link_code: data.link_code ? data.link_code : "",
                            division: data.division ? data.division : "",
                            section: data.section ? data.section : "",
                            category1: data.category1 ? data.category1 : "",
                            category2: data.category2 ? data.category2 : "",
                            category3: data.category3 ? data.category3 : "",
                            category4: data.category4 ? data.category4 : "",
                            category5: data.category5 ? data.category5 : "",
                            category6: data.category6 ? data.category6 : "",
                            mrp: data.mrp ? data.mrp : "",
                            rsp: data.rsp ? data.rsp : "",
                            attribute: data.attribute ? data.attribute : "",
                            department: data.department ? data.department : "",
                            qty: data.qty ? data.qty : "",
                        },
                    ]);
                    setProductCode("");
                    setQty("");
                }
            })
            .catch((err) => {
                console.log(err);
                message.error("Error fetching data");
            });
        setLoading(false);
    };

    const columns = [
        {
            title: "Index",
            dataIndex: "index",
            key: "index",
            width: 50,
            render: (text, record, index) => index + 1,
        },
        {
            title: "Division",
            dataIndex: "division",
            key: "division",
        },
        {
            title: "Attribute",
            dataIndex: "attribute",
            key: "attribute",
        },
        {
            title: "Department",
            dataIndex: "department",
            key: "department",
        },
        // {
        //     title: 'Section',
        //     dataIndex: 'section',
        //     key: 'section',
        // },
        {
            title: "Category 1",
            dataIndex: "category1",
            key: "category1",
        },
        {
            title: "Category 2",
            dataIndex: "category2",
            key: "category2",
        },
        {
            title: "Category 3",
            dataIndex: "category3",
            key: "category3",
        },
        {
            title: "Category 4",
            dataIndex: "category4",
            key: "category4",
        },
        {
            title: "Category 5",
            dataIndex: "category5",
            key: "category5",
        },
        {
            title: "Category 6",
            dataIndex: "category6",
            key: "category6",
        },
        {
            title: "Item Code",
            dataIndex: "link_code",
            key: "link_code",
        },
        {
            title: "Barcode",
            dataIndex: "product_code",
            key: "product_code",
        },
        {
            title: "MRP",
            dataIndex: "mrp",
            key: "mrp",
        },
        {
            title: "QTY",
            dataIndex: "qty",
            key: "qty",
        },
        {
            title: "RSP",
            dataIndex: "rsp",
            key: "rsp",
        },
        {
            title: "Action",
            dataIndex: "",
            key: "action",
            render: (text, record, index) => (
                <Button onClick={() => removeProductCode(index)} danger>
                    Remove
                </Button>
            ),
        },
    ];

    const removeProductCode = (index) => {
        const newDataJSON = [...dataJSON];
        newDataJSON.splice(index, 1);
        setDataJSON(newDataJSON);
    };

    const handleSubmit = async () => {
        if (jobName == "") {
            message.error("Please input job name");
            return;
        }
        setLoading(true);
        setModalVisible(false);
        await axios
            .post(`/chunmun_printing/submit_data?job_name=${jobName + "_" + currentDate}&site_id=${selected.site_id}`, dataJSON)
            .then((res) => {
                message.success("Submit successfully");
                setDataJSON([]);
                setJobName("");
            })
            .catch((err) => {
                console.log(err);
                message.error("Error submit data");
            });
        setLoading(false);
    };

    const handleFileChange = (event) => {
        try {
            const file = event.target.files[0];
            if (!file) {
                setImportedProductCodeData([]);
                return;
            }
            const reader = new FileReader();

            reader.onload = (e) => {
                const fileContent = e.target.result;
                const productCode = fileContent.split("\n");
                setImportedProductCodeData(productCode);
            };
            reader.readAsText(file);
        } catch (error) {
            message.error("Error Reading File !");
            setImportedProductCodeData([]);
        }
    };

    const checkValidProductCode = async (productCode, qty) => {
        setLoading(true);
        await axios
            .get(`/chunmun_printing/get_printing_data_v2?product_code=${productCode}&qty=${qty}`)
            .then((res) => {
                const data = res.data.data;
                if (dataJSON.find((item) => item.product_code === productCode)) {
                    message.error("Product code already present");
                    return;
                } else {
                    setDataJSON((prev) => [
                        ...prev,
                        {
                            product_code: data.product_code ? data.product_code : "",
                            link_code: data.link_code ? data.link_code : "",
                            division: data.division ? data.division : "",
                            section: data.section ? data.section : "",
                            category1: data.category1 ? data.category1 : "",
                            category2: data.category2 ? data.category2 : "",
                            category3: data.category3 ? data.category3 : "",
                            category4: data.category4 ? data.category4 : "",
                            category5: data.category5 ? data.category5 : "",
                            category6: data.category6 ? data.category6 : "",
                            mrp: data.mrp ? data.mrp : "",
                            rsp: data.rsp ? data.rsp : "",
                            attribute: data.attribute ? data.attribute : "",
                            department: data.department ? data.department : "",
                            qty: data.qty ? data.qty : "",
                        },
                    ]);
                    setProductCode("");
                    setQty("");
                }
            })
            .catch((err) => {
                console.log(err);
                message.error("Product code not found !!");
            });
        setLoading(false);
    };

    const importProductCodes = () => {
        let productCodeMap = {};
        for (var pc of importedProductCodeData) {
            if (!productCodeMap[pc]) {
                productCodeMap[pc] = {
                    product_code: pc,
                    qty: 0,
                };
            }
            productCodeMap[pc].qty += 1;
        }

        console.log(productCodeMap);
        const productCodeList = Object.values(productCodeMap);
        for (let i = 0; i < productCodeList.length; i++) {
            if (productCodeList[i].product_code != "") {
                checkValidProductCode(productCodeList[i].product_code, productCodeList[i].qty);
            }
        }
        setImportedProductCodeData([]);
    };

    const handleXlsxxFileChange = (e) => {
        try {
            const file = e.target.files[0];
            const reader = new FileReader();

            reader.onload = (event) => {
                const { result } = event.target;
                const workbook = read(result, { type: "binary" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const data = utils.sheet_to_json(worksheet, { header: 1 });

                const tableData = [];
                const encounteredProductCodes = new Set(); // Track encountered product codes

                data.slice(1).forEach((row, index) => {
                    const productCode = row[9] ? row[9] : "";
                    console.log(row[0]);

                    if (!encounteredProductCodes.has(productCode)) {
                        tableData.push({
                            key: index,
                            index: index + 1,
                            division: row[0] ? row[0] : "",
                            attribute: row[1] ? row[1] : "",
                            department: row[2] ? row[2] : "",
                            category1: row[3] ? row[3] : "",
                            category2: row[4] ? row[4] : "",
                            category3: row[5] ? row[5] : "",
                            category4: row[6] ? row[6] : "",
                            category5: row[7] ? row[7] : "",
                            category6: row[8] ? row[8] : "",
                            link_code: productCode,
                            product_code: row[10] ? row[10] : "",
                            mrp: row[11] ? row[11] : "",
                            qty: row[12] ? row[12] : "",
                            rsp: row[13] ? row[13] : "",
                        });
                        encounteredProductCodes.add(productCode);
                    }
                });

                setDataJSON((prevData) => [...prevData, ...tableData]);
            };

            reader.readAsBinaryString(file);
        } catch (error) {
            console.log("File reading error !!");
        }
    };

    function chooseType() {
        if (fileType === ".txt") {
            return (
                <div style={{ display: "flex" }}>
                    <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px", fontSize: "12px" }}>
                        <b>Import Text file :</b>
                        <input style={{ marginLeft: "0px" }} type="file" accept=".txt" onChange={handleFileChange} />
                    </label>
                    <Button
                        style={{ marginTop: "18px" }}
                        onClick={importProductCodes}
                        type="primary"
                        disabled={!importedProductCodeData.length}
                        hidden={fileType === ".xlsx" ? true : false}
                    >
                        Import
                    </Button>
                </div>
            );
        } else if (fileType === ".xlsx") {
            return (
                <label style={{ display: "flex", flexDirection: "column", marginLeft: "10px", fontSize: "12px" }}>
                    <b>Import Excel file :</b>
                    <input
                        style={{ marginLeft: "0px" }}
                        type="file"
                        accept=".xlsx"
                        disabled={dataJSON.length > 0}
                        onChange={handleXlsxxFileChange}
                    />
                </label>
            );
        }
    }

    const handleChange = (value) => {
        setFileType(value);
    };

    return (
        <div className="my-form-outer">
            <div className="my-form-header">
                <span style={{ display: "inline-flex", alignItems: "center" }}>
                    <span className="my-form-title">Printing</span>
                </span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}>
                    <InputWithLabel label="Select File Type">
                        <Select
                            defaultValue="Select File"
                            style={{
                                width: 120,
                                float: "left",
                                marginRight: "15px",
                            }}
                            disabled={fileType}
                            onSelect={handleChange}
                            options={[
                                {
                                    value: "Select File",
                                    label: "Select File",
                                },
                                {
                                    value: ".txt",
                                    label: "Text File",
                                },
                                {
                                    value: ".xlsx",
                                    label: "Xlsx File",
                                },
                            ]}
                        />
                    </InputWithLabel>
                    {chooseType()}
                </div>
                <InputWithLabel label="Xlsx Template">
                    <ExportToExcelButton
                        fileName={"Sample Xlsx File"}
                        sheets={[{ sheetName: "Sample Xlsx File", data: [], columns: columns.slice(1, 15) }]}
                        buttonName="Download"
                    />
                </InputWithLabel>
            </div>

            <div className="my-table-filter-row">
                <Form className="my-form-multiple-inline-input">
                    <SiteTreeSelectAuto
                        selectedOrg={selectedOrg}
                        value={selected.site_id}
                        setValue={(value) => {
                            setSelectedKey("site_id", value);
                        }}
                        disabled={dataJSON.length > 0 ? true : false}
                    />
                    <InputWithLabel label="Product Code" reqMark={true}>
                        <Input
                            className="my-form-input"
                            value={productCode}
                            required
                            onChange={(e) => {
                                setProductCode(e.target.value);
                            }}
                        />
                    </InputWithLabel>
                    <InputWithLabel label="Qty" reqMark={true}>
                        <InputNumber
                            required
                            min={0}
                            max={100}
                            defaultValue={0}
                            value={qty}
                            onChange={(e) => {
                                setQty(e);
                            }}
                        />
                    </InputWithLabel>

                    <Button onClick={addDataJSON} style={{ marginTop: "20px" }} type="primary">
                        ADD
                    </Button>
                </Form>
                <InputWithLabel label="Job Name" reqMark={true}>
                    <Input
                        className="my-form-input"
                        value={jobName}
                        onChange={(e) => {
                            setJobName(e.target.value.replace(/ /g, "_"));
                        }}
                    />
                </InputWithLabel>
                <Modal
                    title="Are you sure to submit data ?"
                    visible={modalVisible}
                    onCancel={() => setModalVisible(false)}
                    onOk={handleSubmit}
                >
                    <p>Job Name: {jobName + "_" + currentDate}</p>
                </Modal>

                <Button
                    disabled={jobName.length <= 0 ? true : false}
                    onClick={() => setModalVisible(true)}
                    style={{ marginTop: "20px", marginRight: "20px", marginLeft: "20px" }}
                    type="danger"
                >
                    Submit
                </Button>
            </div>

            <Table
                columns={columns}
                dataSource={dataJSON}
                loading={loading}
                key={selectedOrg.orgId}
                scroll={{
                    x: 800,
                }}
                pagination={{
                    position: ["bottomRight"],
                    showSizeChanger: true,
                }}
                size="small"
            />
        </div>
    );
}