export const capitalizeFirstLetter = (string) => {
    try {
        return string.charAt(0).toUpperCase() + string.slice(1);
    } catch (error) {
        return string;
    }
}

export const copyToClipboard = (toCopy) => {
    // navigator.clipboard.writeText(text);
    // TODO: This doesnt work in Some browsers ---

    // This works in all browsers
    let el = document.createElement("textarea");
    let tempMsg = document.createTextNode(toCopy);
    el.appendChild(tempMsg);

    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
}
