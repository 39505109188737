import { Button, Checkbox, DatePicker, Modal, Progress, Select, Space, Table, Tag, message } from "antd";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { InputWithLabel } from "../../Components/Components";
import { ExportToExcelButton } from "../../Components/ExportToExcel";
import SearchComponent from "../../Masters/GeneralComponents/SearchComponent";
import moment from "moment";
import useFilterOptions from "../../Components/useFilterOptions";
import { json } from "react-router-dom";
import useInterval from "../../Components/useInterval";
import { MdCancel } from "react-icons/md";

export default function FileUpload({
    selectedOrg = {},
    permissions = {},
    editPath = "/tools/file-upload" }) {

    const statusColorMap = {
        pending: "#ffbf00",
        format_error: "#cf1322",
        data_error: "#cf1322",
        processing: "#ff5100",
        importing: "#ff9900",
        completed: "#009900",
        empty_file: "#cf1322"
    };

    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDownload, setIsDownload] = useState(false);
    const [fileLoading, setFileLoading] = useState(false);
    const [dateFilter, setDateFilter] = useState(false);
    const [isReplaced, setIsReplaced] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [isReplacedFilter, setIsReplaceFilter] = useState(true);
    const [masterState, setMasterState] = useState([]);
    const [data, setData] = useState([]);
    const [typeCode, setTypeCode] = useState([]);
    const [typeName, setTypeName] = useState([]);
    const [note, setNote] = useState("Begin by downloading our template to input your data effortlessly. After filling it out, upload the file, and we'll handle everything else!");
    const [file, setFile] = useState([]);
    const fileInput = document.getElementById("fileInput");

    const filterableColumns = useMemo(() => ["status"], []);
    const [filterOptions, setFilterOptions] = useFilterOptions(masterState, filterableColumns);


    const [requestBody, setRequestBody] = useState({
        type_code: null,
        type_name: null,
        filter_json: {
            date: moment(new Date()).format("YYYY-MM-DD")
        },
    })

    const [templateData, setTemplateData] = useState([]);


    const showModal = () => {
        setIsModalOpen(true);
    };

    useInterval(() => {
        getData()
    }, 10000)

    const handleCancel = () => {
        setIsModalOpen(false);
        setIsDownload(false);
        setDateFilter(false);
        setIsReplaced(false);
        setTemplateData([]);
        setFile([]);
        setRequestBody({
            type_code: null,
            type_name: null,
            filter_json: {
                date: null,
            }
        });

        getData();
    };

    const handleXlsxxFileChange = async (e) => {
        try {
            const files = Array.from(e.target.files);
            setFile(prevFiles => [...prevFiles, ...files]);
            e.target.value = "";
        } catch (error) {
            console.log("File reading error !!");
        }
    };

    const getData = async () => {
        setLoading(true);
        await axios
            .get(`/get_file_data`, {
                headers: {
                    row_limit: 10,
                    page_no: currentPage
                }
            })
            .then((res) => {
                setData(res.data.data);
                setTotalCount(res.data.total_count);
                setMasterState(res.data.data);
            }).catch((error) => {
                setMasterState([]);
                setData([]);
                if (error.response) {
                    console.log(error);
                    message.error(`${error.response?.status} Error ${error.response?.data?.detail}`)
                } else {
                    console.log(error);
                    message.error(error?.message);
                }
            });
        setLoading(false);
    };


    const getDropdownData = async () => {
        setLoading(true);
        await axios
            .get(`/get_file_upload_types_data`)
            .then((res) => {
                res.data?.data?.forEach((item) => {
                    setTypeCode(prev => [
                        ...prev,
                        {
                            label: item.type_code,
                            value: item.type_code
                        }
                    ]);

                    setTypeName(prev => [
                        ...prev,
                        {
                            label: removeUnderscore(item.type_name),
                            value: item.type_name
                        }
                    ]);
                });
            }).catch((error) => {
                if (error.response) {
                    console.log(error);
                    message.error(`${error.response?.status} Error ${error.response?.data?.detail}`)
                } else {
                    console.log(error);
                    message.error(error?.message);
                }
            });
        setLoading(false);
    };


    const getTemplateData = async () => {
        setLoading(true);
        await axios
            .get(`/template?type_code=${requestBody.type_code}&type_name=${requestBody.type_name}`)
            .then((res) => {
                const data = res.data.data.columns;
                const filter = res.data.data.filter_required;

                if (filter) {
                    setIsReplaceFilter(filter?.replace_flag);
                    setDateFilter(filter?.date);
                    setNote(filter?.note);
                }

                data.forEach((item) => {
                    setTemplateData(prev => [
                        ...prev,
                        {
                            title: item.category_name,
                            dataIndex: item.category_name,
                            key: item.category_name
                        }
                    ]);
                })
                setIsDownload(true);
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error);
                    message.error(`${error.response?.status} Error ${error.response?.data?.detail}`)
                } else {
                    console.log(error);
                    message.error(error?.message);
                }
                setTemplateData([]);
                setIsDownload(false);
                setDateFilter(false);
            });
        setLoading(false);
    };


    const submitFile = async () => {
        const formData = new FormData();
        for (let i = 0; i < file.length; i++) {
            formData.append("files", file[i]); // Append the file to the form data
        }
        setFileLoading(true)
        await axios
            .post(`/create_bulk_file_session?type_code=${requestBody.type_code}&type_name=${requestBody.type_name}&filter_json=${JSON.stringify(requestBody.filter_json)}&is_replace=${isReplaced ? 1 : 0}`
                , formData)
            .then((response) => {
                message.success("File uploaded successfully");
                setFile([])
                if (fileInput) {
                    fileInput.value = "";
                }
                handleCancel();
            }).catch((error) => {
                setFile([])
                if (fileInput) {
                    fileInput.value = "";
                }
                if (error.response) {
                    console.log(error);
                    message.error(`${error.response?.status} Error ${error.response?.data?.detail}`)
                } else {
                    console.log(error);
                    message.error(error?.message);
                }
            });
        setFileLoading(false)
    }

    const removeUnderscore = (text) => {
        const stringWithSpaces = text.replaceAll("_", " ");
        return stringWithSpaces
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }
    
    const removeFile = (index) => {
        setFile(prevFiles => prevFiles.filter((file, i) => i !== index));
    }

    useEffect(() => {
        getData();
    }, [currentPage])

    useEffect(() => {
        setTypeCode([]);
        setTypeName([]);
        setTemplateData([]);
        setIsDownload(false);

        getDropdownData();
        getData();
    }, [])


    useEffect(() => {
        if (requestBody.type_code != null && requestBody.type_name != null) {
            setTemplateData([]);
            getTemplateData()
        }
    }, [requestBody])

    const getPercentage = (record) => {
        if (record?.status == "completed") return 100 // status is completed then return progress is 100%
        else if (record?.total != 0) return Math.round((record?.progress_bar / record?.total) * 100) // caluculate progress if total value is not 0
        else return 0
    }

    const columns = [
        {
            title: "Sr.No",
            dataIndex: "index",
            key: "index",
            width: 50,
            render: (text, record, index) => index + 1,
        },
        {
            title: "File Name",
            dataIndex: "file_name",
            key: "file_name",
        },
        {
            title: "Type Name",
            dataIndex: "type_name",
            key: "type_name",
            render: (text, record) => <div>{removeUnderscore(text)}</div>,
        },
        {
            title: "Upload Time",
            dataIndex: "created_time",
            key: "created_time",
            render: (text, record) => moment(text).format('Do MMM YYYY, HH:mm A'),
            sorter: (a, b) => moment(a.created_time) - moment(b.created_time),
        },
        {
            title: "Stock Date",
            dataIndex: "filter_date",
            key: "filter_date",
            render: (text, record) => <div>{text || "-"}</div>,
        },
        {
            title: "Created By",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "Progress",
            dataIndex: "progress",
            key: "progress",
            render: (text, record) => (
                <Progress type="line" percent={getPercentage(record) || 0} />
            ),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            filters: filterOptions.status,
            onFilter: (value, record) => record.status == value,
            render: (text, record) => (
                <Tag color={statusColorMap[text] || "#000000"} key={record.status} style={{ fontWeight: "700" }}>
                    {removeUnderscore(text)}
                </Tag>
            ),
        },
    ];

    return (
        <div className="my-form-outer">
            <div className="my-form-header">
                <span style={{ display: "inline-flex", alignItems: "center" }}>
                    <span className="my-form-title">File Upload</span>
                </span>
            </div>

            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'end', gap: 20, marginBottom: 10 }}>
                <SearchComponent
                    masterState={masterState}
                    state={data}
                    setState={setData}
                    searchOptions={[
                        { keyName: "file_name", label: "File Name" },
                        { keyName: "type_name", label: "Type Name" },
                        { keyName: "created_time", label: "Upload Time" },
                        { keyName: "username", label: "Created By" },
                        { keyName: "status", label: "Status" },
                    ]}
                    isLabelInline={false}
                />
                {permissions?.upload_file && <Button onClick={showModal} type="danger">
                    Upload File
                </Button>}
            </div>

            <Modal title="Upload File"
                maskClosable={false}
                open={isModalOpen}
                okButtonProps={{ hidden: true }}
                cancelButtonProps={{ type: 'danger' }}
                onCancel={handleCancel}>

                <div style={{ display: 'flex', flexDirection: 'column' }}>

                    <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <InputWithLabel reqMark label={"Select Type Name"}>
                            <Select
                                defaultValue="Select Type Name"
                                options={typeName}
                                value={requestBody.type_name || "Selecte Type Name"}
                                style={{ width: '230px' }}
                                onSelect={(value) => {
                                    setRequestBody(prev => ({ ...prev, type_name: value, type_code: value }))
                                    setRequestBody(prev => ({ ...prev, filter_json: { date: moment(new Date()).format("YYYY-MM-DD") } }))
                                }}
                            />
                        </InputWithLabel>

                        {dateFilter && <InputWithLabel label={'Select Date'} reqMark>
                            <DatePicker
                                className="my-form-input"
                                format={"YYYY-MM-DD"}
                                defaultValue={moment(requestBody.filter_json.date, "YYYY-MM-DD")}
                                onChange={(value) => setRequestBody(prev => ({ ...prev, filter_json: { date: value.format("YYYY-MM-DD") } }))}
                                allowClear={false}
                                disabledDate={(current) => { return current && current > moment().endOf('day'); }}
                            />
                        </InputWithLabel>}
                    </Space>

                    <div style={{ marginTop: 20 }}>
                        <p>Note : {note}</p>
                        <ExportToExcelButton
                            fileName={"Sample File"}
                            sheets={[{ sheetName: "Sample Xlsx File", data: [], columns: templateData }]}
                            buttonName="Download Template"
                            buttonDisabled={!isDownload}
                        />
                    </div>

                    {(requestBody.type_code != null && requestBody.type_name != null && isDownload) &&
                        <div style={{ marginTop: 20 }}>
                            <label style={{ display: "flex", flexDirection: "column", fontSize: "12px" }}>
                                <b>Select / Upload Multiple Files</b>
                                <input
                                    style={{ marginTop: 10 }}
                                    id="fileInput"
                                    type="file"
                                    multiple
                                    accept=".xlsx"
                                    onChange={handleXlsxxFileChange}
                                />
                            </label>

                            <div style={{ marginTop: 10 }}>
                                {file.map((item, index) => (
                                    <div style={{ margin: 0, display: 'flex', gap: 5, alignContent: 'center' }}>
                                        <p>{index + 1}. {item.name}</p>
                                        <MdCancel style={{ cursor: 'pointer' }} onClick={() => removeFile(index)} size={16} />
                                    </div>
                                ))}
                            </div>

                            {isReplacedFilter && <div style={{ marginTop: 15 }}>
                                <InputWithLabel isInline label={"Is file replaced ?"}>
                                    <Checkbox style={{ marginTop: '2px' }}
                                        checked={isReplaced}
                                        onChange={(e) => setIsReplaced(e.target.checked)}>
                                        Replaced
                                    </Checkbox>
                                </InputWithLabel>
                            </div>}

                            <Button
                                style={{ float: 'right', width: 200, marginTop: 20 }}
                                type="primary"
                                onClick={submitFile}
                                disabled={!file}
                                loading={fileLoading}>
                                Upload File
                            </Button>
                        </div>}

                </div>

            </Modal>

            <Table
                columns={columns}
                dataSource={data}
                loading={loading}
                scroll={{
                    x: 800,
                }}
                // pagination={{
                //     position: ["bottomRight"],
                //     showSizeChanger: true,
                // }}
                pagination={
                    {
                        current: currentPage,
                        total: totalCount,
                        onChange: (e) => setCurrentPage(e)
                    }
                }
                size="large"
            />
        </div>
    )

}