import { Button, message, Popconfirm, Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import SearchComponent from '../GeneralComponents/SearchComponent';
import { deleteButtonTitle, deleteButtonType, editButtonTitle, editButtonType } from '../../Constants';
import moment from 'moment';

export default function AllAppSettings({
    selectedOrg,
    permissions={},
    
    editPath="/masters/appsettings/edit",
    // addPath="/masters/appsettings/add",
}) {
    const [masterState, setMasterState] = useState([]);

    // The below state is after the search is done
    const [appsettingsTemplates, setAppsettingsTemplates] = useState([]);
    const [loading, setLoading] = useState(true);

    const getTemplates = async () => {
        setLoading(true);
        /*
            This api return format:
            res.data => appsettings
            [
                {
                    group_name: "decoding",
                    setting_id: 1,
                    value: "0",
                    created_time: "2022-05-23T12:24:19",
                    setting_name: "encode_decode_logic",
                    updated_time: "2022-05-23T12:24:18"
                }
            ]

        
        */
        await axios.get('/mobile/get_app_settings', {
            params: {
                o_id: selectedOrg.orgId,
            }
        })
            .then(res => {
                const data = res.data.data;
                setMasterState(data);
                setAppsettingsTemplates(data);
            })
            .catch(err => {
                console.log(err);
                setAppsettingsTemplates([]);
                message.error('Error Fetching App Settings');
            })
        
        setLoading(false);
    }


    const columns = useMemo(() => [
        {
            title: 'Id',
            dataIndex: 'setting_id',
            key: 'setting_id',
            width: '50px',
            hidden: window.location.hostname !== 'localhost',
        },
        {
            title: 'Name',
            dataIndex: 'setting_name',
            key: 'setting_name',
        },
        {
            title: 'Group Name',
            dataIndex: 'group_name',
            key: 'group_name',
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: 'Created Time',
            dataIndex: 'created_time',
            key: 'created_time',
            render: (text, record) =>  moment(text).format('Do MMM YYYY, HH:mm A'),
        },
        {
            title: 'Updated Time',
            dataIndex: 'updated_time',
            key: 'updated_time',
            render: (text, record) =>  moment(text).format('Do MMM YYYY, HH:mm A'),
        },
        {
            width: '80px',
            title: 'Action',
            render: (text, record) => (
                <div className='actions-outer-div'>
                    <Button type={editButtonType} className='actions-button' title={`Edit Scope | ${record.setting_name}`}>
                        <Link to={`${editPath}/${record.setting_id}`}>
                            {editButtonTitle}
                        </Link>
                    </Button>
                    {/* <Popconfirm 
                        overlayClassName='delete-popconfirm' 
                        title={<>{record.role_name} <br/> {roleDeleteMessage}</>} 
                        onConfirm={() => handleDelete(record.role_id)} 
                        okText="Yes" 
                        cancelText="No" 
                        disabled={!permissions.delete}
                    >
                        <Button danger type={deleteButtonType} disabled={!permissions.delete} className='actions-button' title={`Delete Scope | ${record.role_name}`}>
                            {deleteButtonTitle}
                        </Button>
                    </Popconfirm> */}
                </div>
            ),
            width: '150px'

        },
    ], [selectedOrg]).filter(column => !column.hidden);

    useEffect(() => {
        getTemplates();
    }, [selectedOrg.orgId]);




    return (
        <div className='my-form-outer'>
            <div className='my-form-header'>
                <span className='my-form-title'>App Settings</span>
                {/* <Button type="primary" disabled={!permissions.add}>
                    <Link to={addPath}>
                        Add App Settings
                    </Link>
                </Button> */}

            </div>
            <div style={{display: 'inline-flex', justifyContent: 'space-between', width: '100%'}}>
                <div></div>
                <SearchComponent
                    masterState={masterState}
                    state={appsettingsTemplates}
                    setState={setAppsettingsTemplates}
                    searchOptions={[
                        {keyName: 'setting_name', label: 'Name'},
                        {keyName: 'group_name', label: 'Group Name'},
                    ]}
                    // defaultSearchKeys={['setting_name']}
                />
            </div>
            <Table
                columns={columns}
                dataSource={appsettingsTemplates}
                loading={loading}
                size="small"
                pagination={{
                    position: ['bottomRight'],
                    // position: ['topRight'],
                    showSizeChanger: true,
                }}
            />
        </div>
    )
}
