import { Card, DatePicker, Divider, Empty, message, Row, Select, Space, Spin, Table, Tabs, Tag } from "antd";
import { Column, Line, Bar, Pie } from "@ant-design/plots";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import DateRange from "../Reports/Components/DateRange";
import TabPane from "antd/lib/tabs/TabPane";
import SiteTreeMultiSelect from "../RFIDReports/Components/SiteTreeMultiSelect";
import { useSelector } from "react-redux";
import { InputWithLabel } from "../Components/Components";
import ColumnGraph from "./ColumnGraph";

export default function GlobalReports({ selectedOrg = {} }) {
    const [selected, setSelected] = useState({
        date: moment(),
        dateRange: [moment().subtract(7, "days"), moment()],
        sites: [], // Array of panel ids
    });
    const globalReducer = useSelector((state) => state.globalReducer);
    const setSelectedKey = (key, value) => {
        setSelected({
            ...selected,
            [key]: value,
        });
    };
    const [dataJSON, setDataJSON] = useState([]);

    return (
        // <div>GlobalReports
        // <div className="my-table-filter-row">
        <div>
            {/* <Divider style={{padding: '30px 0px'}} >
                 <span style={{fontSize: '16px'}}>Regionwise Inward Summary</span>
             </Divider> */}
            <Tabs defaultActiveKey="1">
                <TabPane tab="Region Wise Inward Summary" key="1">
                    {/* <RegionwiseInwardReport/> */}
                    <Space>
                        <DateRange
                            value={selected.dateRange}
                            setValue={(value) => {
                                setSelectedKey("dateRange", value);
                            }}
                        />
                        <SiteTreeMultiSelect
                            selectedOrg={globalReducer.selectedOrg}
                            value={selected.site_id}
                            setValue={(value) => {
                                setSelectedKey("sites", value);
                            }}
                        />
                    </Space>
                    <RegionwiseInwardReport selected={selected} />
                </TabPane>
                <Row style={{ position: "relative", marginTop: "20px", justifyContent: "center" }}>
                    {dataJSON.length > 0 ? <ColumnGraph dataJSON={dataJSON} /> : <Empty />}
                </Row>
                <TabPane tab="All Region Wise Shop vs Back" key="2">
                    <Space>
                        <InputWithLabel label={"Date"} reqMark>
                            <DatePicker
                                format={"YYYY-MM-DD"}
                                value={selected.date}
                                onChange={(value) => {
                                    setSelectedKey("date", value);
                                }}
                                allowClear={false}
                                disabledDate={(current) => {
                                    return current && current > moment().endOf("day");
                                }}
                            />
                        </InputWithLabel>
                        <SiteTreeMultiSelect
                            selectedOrg={globalReducer.selectedOrg}
                            value={selected.sites}
                            setValue={(value) => {
                                setSelectedKey("sites", value);
                            }}
                        />
                    </Space>

                    <Divider style={{ padding: "30px 0px" }}>
                        <span style={{ fontSize: "16px" }}>All Region Summary</span>
                    </Divider>
                    <AllRegionWiseShopvsBackReport selected={selected} />
                    <Divider style={{ padding: "30px 0px" }}>
                        <span style={{ fontSize: "16px" }}>All Region Wise Summary</span>
                    </Divider>
                    <RegionWiseShopvsBackReport selected={selected} />
                </TabPane>
            </Tabs>
        </div>
        // </div>
    );
}

// export default GlobalReports

const RegionwiseInwardReport = ({ selected }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [table, setTable] = useState([]);
    const [dataJSON, setDataJSON] = useState([]);

    const getDetails = async () => {
        setLoading(true);
        await axios
            .post("/reports/region_wise_inward_report", {
                from_date: selected.dateRange[0].startOf("day").format(),
                to_date: selected.dateRange[1].endOf("day").format(),
                site_ids: selected.sites,
            })
            .then((res) => {
                const data = res.data.data;
                console.log("data", data)
                setData(data);
                setDataJSON(data);
                const transformedData = res.data.data.reduce((result, item) => {
                    const region = result.find(entry => entry.region_name === item.region_name);
                    if (region) {
                      if (item.status === "pending") {
                        region.pending_invoices = item.number_of_invoices;
                      } else if (item.status === "scanned") {
                        region.scanned_invoices = item.number_of_invoices;
                      }
                    } else {
                      result.push({
                        region_name: item.region_name,
                        pending_invoices: item.status === "pending" ? item.number_of_invoices : 0,
                        scanned_invoices: item.status === "scanned" ? item.number_of_invoices : 0
                      });
                    }
                    return result;
                  }, []);
                  setTable(transformedData);
                  console.log(transformedData);
            })
            .catch((err) => {
                console.log(err);
                message.error("Error fetching bar chart data");
            });
        setLoading(false);
    };

    useEffect(() => {
        if (!selected?.sites?.length) {
            setLoading(false);
            return;
        }
        getDetails();
    }, [selected.sites, selected.dateRange]);

    const configBar = {
        data,
        isStack: true,
        xField: "number_of_invoices",
        yField: "region_name",
        seriesField: "status",
        label: {
            position: "middle",
            layout: [
                {
                    type: "interval-adjust-position",
                },
                {
                    type: "interval-hide-overlap",
                },
                {
                    type: "adjust-color",
                },
            ],
        },
        color: ({ status }) => statusColorMap[status],
    };

    const [graph, setGraph] = useState(Line);
    const globalReducer = useSelector((state) => state.globalReducer);

    const statusColorMap = { pending: "#E04B4B", scanned: "#61C149" };

    const config = {
        data,
        isGroup: true,
        xField: "region_name",
        yField: "number_of_invoices",
        seriesField: "status",

        label: {
            position: "middle",
            layout: [
                {
                    type: "interval-adjust-position",
                },
                {
                    type: "interval-hide-overlap",
                },
                {
                    type: "adjust-color",
                },
            ],
        },
        color: ({ status }) => statusColorMap[status],
    };
    function setGrph() {
        if (graph === "Column") {
            return <Column {...config} />;
        } else if (graph === "Line") {
            return <Line {...config} />;
        } else if (graph === "Bar") {
            return <Bar {...configBar} />;
        } else {
            return <Column {...config} />;
        }

        setLoading(false);
    }

    const handleChange = (value) => {
        setGraph(value);
    };

    const columns = useMemo(() => [
        {
            title: "Region Name",
            dataIndex: "region_name",
            key: "region_name",
        },
        {
            title: "Pending Invoices",
            dataIndex: "pending_invoices",
            key: "pending_invoices",
            render: (text, record) => (
                <Tag color={statusColorMap["pending"] || "#000000"} key={record.pending_invoices} style={{ fontWeight: "700" }}>
                    {text}
                </Tag>
            ),
        },
        {
            title: "Scanned Invoices",
            dataIndex: "scanned_invoices",
            key: "scanned_invoices",
            render: (text, record) => (
                <Tag color={statusColorMap["scanned"] || "#000000"} key={record.scanned_invoices} style={{ fontWeight: "700" }}>
                    {text}
                </Tag>
            ),
        },
    ]);

    return (
        <>
            <div className="my-form-outer">
                <InputWithLabel label="Select Graph">
                    <Select
                        defaultValue="Column"
                        style={{
                            width: 120,
                            float: "left",
                        }}
                        onChange={handleChange}
                        options={[
                            {
                                // Column ,Line,Area,Scatter
                                value: "Column",
                                label: "Column Graph",
                            },
                            {
                                value: "Bar",
                                label: "Stacked Graph",
                            },
                            {
                                value: "Line",
                                label: "Line Graph",
                            },
                        ]}
                    />
                </InputWithLabel>
            </div>
            <Divider style={{ padding: "30px 0px" }}>
                <span style={{ fontSize: "16px" }}>Graphical Data</span>
            </Divider>
            <Card style={{ width: "100%", height: "100%" }}>
                <Spin tip="Generating Graph" spinning={loading}>
                    {/* <Column {...config} /> */}
                    {setGrph()}
                </Spin>
            </Card>
            <Divider style={{ padding: "30px 0px" }}>
                <span style={{ fontSize: "16px" }}>Tabular Data</span>
            </Divider>
            <Table columns={columns} dataSource={table} pagination={false} />
        </>
    );
};

const AllRegionWiseShopvsBackReport = ({ selected }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [dataJSON, setDataJSON] = useState([]);

    const getAllShopVsBackData = async () => {
        setLoading(true);
        await axios
            .post("/admin-api/reports/stock_take/region_wise_shop_and_back_zone_percentage", {
                from_date: selected.date.format("YYYY-MM-DD"),
                site_id: selected.sites,
            })
            .then((res) => {
                const data = res.data.data;
                // var sites = res.data.site_details;
                setData(data);
                setDataJSON(data);
            })
            .catch((err) => {
                console.log(err);
                message.error("Error fetching bar chart data");
            });
        setLoading(false);
    };

    useEffect(() => {
        if (!selected?.sites?.length) {
            setLoading(false);
            return;
        }
        getAllShopVsBackData();
    }, [selected.sites, selected.date]);

    const color = (zoneName) => {
        if (zoneName === "Shop Floor") {
            return "green";
        } else if (zoneName === "Back Store") {
            return "blue";
        } else {
            return "#2F9739";
        }
    };

    const columns = useMemo(() => [
        {
            title: "Zone Name",
            dataIndex: "zone_name",
            key: "zone_name",
            render: (text, record) => (
                <Tag
                    // set color according to pie chart
                    color={color(text)}
                    key={record.zone_name}
                    style={{ fontWeight: "700" }}
                >
                    {text?.toUpperCase()}
                </Tag>
            ),
        },
        {
            title: "Count",
            dataIndex: "sum",
            key: "sum",
        },
    ]);

    const piechart_data = {
        appendPadding: 10,
        data: data.filter((data) => data.zone_name !== "Total Count"),
        angleField: "sum",
        colorField: "zone_name",

        radius: 0.75,
        label: {
            type: "spider",
            labelHeight: 28,
            content: "{name}\n{percentage}",
        },
        interactions: [
            {
                type: "element-selected",
            },
            {
                type: "element-active",
            },
        ],
    };
    const AllTotal = data.reduce((a, b) => a + b.sum, 0);
    data.push({ zone_name: "Total Count", sum: AllTotal });

    console.log(dataJSON);
    return (
        <>
            {dataJSON.length > 1 ? (
                <div className="my-form-outer" style={{ display: "flex", justifyContent: "center" }}>
                    <Card style={{ width: 500, margin: "0 20px", display: "inline-grid" }}>
                        <Pie {...piechart_data} />
                    </Card>
                    <Card style={{ width: 500, height: 100, margin: "0 20px", display: "inline-grid" }}>
                        <Table dataSource={data} pagination={false} columns={columns} />
                    </Card>
                </div>
            ) : (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
                    <Empty />
                </div>
            )}
        </>
    );
};

const RegionWiseShopvsBackReport = ({ selected }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [masterState, setMasterState] = useState([]);
    const [dataJSON, setDataJSON] = useState([]);

    const getShopVsBackData = async () => {
        setLoading(true);
        await axios
            .post("/admin-api/reports/stock_take/region_id_wise_shop_and_back_zone_percentage", {
                from_date: selected.date.format("YYYY-MM-DD"),
            })
            .then((res) => {
                const data = res.data.data;
                setData(data);
                setMasterState(data);
                setDataJSON(data);
            })
            .catch((err) => {
                console.log(err);
                message.error("Error fetching bar chart data");
            });
        setLoading(false);
    };

    useEffect(() => {
        getShopVsBackData();
    }, [selected.date]);

    const color = (zoneName) => {
        // set color according to pie chart
        if (zoneName === "Shop Floor") {
            return "green";
        } else if (zoneName === "Back Store") {
            return "blue";
        } else {
            return "#2F9739";
        }
    };

    const regionIds = [...new Set(data.map((item) => item.region_id))];
    const columns = useMemo(() => [
        {
            title: "Zone Name",
            dataIndex: "zone_name",
            key: "zone_name",
            render: (text, record) => (
                <Tag
                    color={color(text)}
                    key={record.zone_name}
                    style={{ fontWeight: "700" }}
                >
                    {text?.toUpperCase()}
                </Tag>
            ),
        },
        {
            title: "Count",
            dataIndex: "sum",
            key: "sum",
        },
    ]);

    return (
        <>
            <div className="my-form-outer">
                {regionIds.map((regionId) => {
                    // Filter data for the current region
                    const regionData = data.filter((item) => item.region_id === regionId);
                    const regionName = regionData[0]?.region_name;

                    const piechart_data = {
                        appendPadding: 10,
                        data: regionData.filter((data) => data.zone_name !== "All Total"),
                        angleField: "sum",
                        colorField: "zone_name",
                        radius: 0.75,
                        label: {
                            type: "spider",
                            labelHeight: 28,
                            content: "{name}\n{percentage}",
                        },
                        interactions: [
                            {
                                type: "element-selected",
                            },
                            {
                                type: "element-active",
                            },
                        ],
                    };
                    const AllTotal = regionData.reduce((a, b) => a + b.sum, 0);
                    regionData.push({ zone_name: "Total Count", sum: AllTotal });

                    return (
                        <Card key={regionId} title={regionName} style={{ width: 500, margin: "0 20px", display: "inline-grid" }}>
                            <Pie {...piechart_data} />
                            <Table dataSource={regionData} pagination={false} columns={columns} />
                        </Card>
                    );
                })}
            </div>
        </>
    );
};
