import { message, TreeSelect } from 'antd';
import { convertSitesToTreeV2 } from '../../Masters/GeneralComponents/Functions';
import React, { useState, useEffect } from 'react'
import { InputWithLabel } from '../../Components/Components';
import axios from 'axios';



export default function SiteTreeMultiSelect({
    selectedOrg,

    value = [],
    setValue = () => { alert("Site Select setValue method not defined") },

    className = "my-form-input",
    style = { width: "300px" },
    status = "",

    showTreeLine = true,
    showLeafIcon = false,
    disabled = false,
    isHidden = false,
    pickerLabel = "Select Sites",
}) {
    const [loading, setLoading] = useState(true);
    // Options for site select
    const [siteTree, setSiteTree] = useState([]);


    // This is the state which will handle Change
    // Then on blur we will set the main state which is responsible for the filter
    const [selectedSites, setSelectedSites] = useState([]);

    const getSiteOptions = async () => {
        setLoading(true);
        await axios.get('/admin-api/all_sites_by_o_id', {
            params: {
                o_id: selectedOrg.orgId,
            }
        })
            .then(res => {
                const data = res.data.data;

                const { treeData, siteIds } = convertSitesToTreeV2(data);

                console.log(treeData);

                // Select the sites which are present in the value and also in the siteIds
                // If length of intersection is 0, then select siteIds
                let newSelected = [];
                const set = new Set(value);
                newSelected = siteIds.filter(x => set.has(x));
                if (newSelected.length === 0) {
                    newSelected = siteIds;
                }

                setSiteTree(treeData);
                setSelectedSites(newSelected);
                setValue(newSelected);     // Set the siteIds as the value of the select
            })
            .catch(err => {
                setSiteTree([]);
                setSelectedSites([]);
                console.log(err);
                message.error('Error in fetching sites');
            })
        setLoading(false);

    }

    useEffect(() => {
        getSiteOptions();
    }, [selectedOrg.orgId]);


    return (
        <>
            {!isHidden &&
                <InputWithLabel label={pickerLabel}>
                    <TreeSelect
                        className={className}
                        style={style}

                        key={selectedOrg.orgId}

                        treeData={siteTree}
                        treeLine={showTreeLine && { showLeafIcon }}

                        value={selectedSites}
                        onChange={setSelectedSites}
                        maxTagCount={1}
                        maxTagPlaceholder={selectedSites => `+ ${selectedSites.length} more`}
                        treeCheckable={true}

                        showCheckedStrategy={'SHOW_CHILD'}
                        placeholder={!loading && "Select Sites"}

                        onBlur={() => setValue(selectedSites)}

                        loading={loading}
                        disabled={loading || disabled}

                        showSearch={true}
                        filterTreeNode={(inputValue, treeNode) => {
                            return treeNode.title.toLowerCase().includes(inputValue.toLowerCase());
                        }}
                    />
                </InputWithLabel>}
        </>
    )
}
