import { Table, message, Button, Tag } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import SearchComponent from '../../Masters/GeneralComponents/SearchComponent';
import DateRange from '../../Reports/Components/DateRange';
import SiteTreeMultiSelect from '../Components/SiteTreeMultiSelect';
import axios from 'axios';
import moment from 'moment';
import { editButtonType } from '../../Constants';
import { Link } from 'react-router-dom';
import useFilterOptions from '../../Components/useFilterOptions';


export default function EncodingSessionsList({
    selectedOrg={},
    permissions={},

    addPath="/tools/encoding/sessions/add",
    editPath="/tools/encoding/sessions/edit",
}){
    // Using session storage to save it even if the user refreshes the page, If opened in new tab, then it will be empty
    // setItem is in the useEffect below
    const [selected, setSelected] = useState({
        dateRange: [
            sessionStorage.getItem("encoding-dateRange") ? moment(JSON.parse(sessionStorage.getItem("encoding-dateRange"))?.[0]) : moment().subtract(1, 'month'),
            moment(JSON.parse(sessionStorage.getItem("encoding-dateRange"))?.[1]),   // It will be undefined if no value is present in local storage and moment will take it as today's date
        ],

        sites: sessionStorage.getItem('encoding-siteIds') ? JSON.parse(sessionStorage.getItem('encoding-siteIds')) : [],
    });

    const [masterState, setMasterState] = useState([]);

    // The below state is after the search is done
    const [filteredState, setFilteredState] = useState([]);
    const [loading, setLoading] = useState(true);

    const filterableColumns = useMemo(() => ["status"], []);
    const [filterOptions, setFilterOptions] = useFilterOptions(masterState, filterableColumns);

    const getData = async () => {
        setLoading(true);
        await axios.post('/encoding/list_of_sessions', {
            o_id: selectedOrg.orgId,
            from_date: selected.dateRange[0].startOf('day').format(),
            to_date: selected.dateRange[1].endOf('day').format(),
            site_ids: selected.sites,
        })
            .then(res => {
                const data = res.data.data;

                setMasterState(data);
                setFilteredState(data);

            })
            .catch(err => {
                console.log(err);
                message.error('Error fetching data');
                setMasterState([]);
                setFilteredState([]);
            })
        setLoading(false);
    }

    useEffect(() => {
        // This useEffect dosent have the selectedOrg as a dependency because if
        // new org is selected, then new sites will be loded ... and that will trigger getData
        // If we add that dependency, then it will trigger getData twice and the first time with previous org's sites
        if(!selected?.sites?.length) {
            setMasterState([]);
            setLoading(false);
            return;
        }
        getData();

        selected?.sites && sessionStorage.setItem('encoding-siteIds', JSON.stringify(selected.sites));
        selected?.dateRange && sessionStorage.setItem("encoding-dateRange", JSON.stringify(selected.dateRange));
    }, [selected.sites, selected.dateRange])
    // }, [selectedOrg.orgId, selected.sites, selected.dateRange])

    // This is just for UI loading
    useEffect(() => {
        if(selectedOrg.orgId) {
            setLoading(true);
        }
    }, [selectedOrg.orgId]);

    const statusColorMap = { active: "#61C149", close: "#E04B4B", pending_approval: "#EE9322", cancel: "#7D7C7C"};
    /*
        columns {
      "session_id": 5,
      "session_name": "{abc}",
      "session_type": "{planned}",
      "encoded_qty": 0,
      "pending_qty": 0,
      "status": "active"
    },
    */
    const columns = [
        {
            title: 'Session ID',
            dataIndex: 'session_id',
            key: 'session_id',
            sorter: (a, b) => a.session_id - b.session_id,
            width: '10px',
        },
        {
            title: 'Session Name',
            dataIndex: 'session_name',
            key: 'session_name',
            width: '80px',
        },
        {
            title: 'Session Type',
            dataIndex: 'session_type',
            key: 'session_type',
            width: '80px',
        },
        {
            title: 'Site Name ',
            dataIndex: 'site_name',
            key: 'site_name',
            width: '130px',
        },
        {
            title: 'Reference Number',
            dataIndex: 'ref_number',
            key: 'ref_number',
            width: '180px',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            filters: filterOptions.status,
            render: (text, record) => (
                <Tag
                    color={statusColorMap[text] || "#000000"}
                    key={record.status}
                    style={{ fontWeight: '700' }}
                >
                    {removeUnderscore(text)}
                </Tag>
            ),
            onFilter: (value, record) => record.status == value,
            width: '80px',
        },
        {
            title: 'Total Encode Qty',
            dataIndex: 'encoded_qty',
            key: 'encoded_qty',
            width: '80px',
            sorter: (a, b) => a.encoded_qty - b.encoded_qty,
        },
        // {
        //     title: 'Pending Approval',
        //     dataIndex: 'pending_approval_qty',
        //     key: 'pending_approval_qty',
        //     width: '80px',
        // },
        {
            title: 'Action',
            render: (text, record) => (
                <div className='actions-outer-div'>
                    <Button type={editButtonType} className='actions-button' >
                        <Link to={`${editPath}/${record.session_id}`}>
                            View
                        </Link>
                    </Button>
                </div>
            ),
			width: '80px',
        },
    ].filter(column => !column.hidden);

    const setSelectedKey = (key, value) => {
        setSelected(prev => ({
            ...prev,
            [key]: value,
        }));
    }

    const removeUnderscore = (text) => {
        const stringWithSpaces = text.replaceAll("_", " ");
        return stringWithSpaces
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    return (
        <div className='my-form-outer'>
            <div className='my-form-header'>
                <span className='my-form-title'>Encoding Session List</span>
                <Button type="primary"
                    // disabled={!permissions.add}
                >
                    <Link to={addPath}>
                        Create Session
                    </Link>
                </Button>
            </div>
            <div className="my-table-filter-row">
                <div className="my-form-multiple-inline-input">
                    {/* Read documentation inside DateRange for set methods */}
                    <DateRange
                        selectedOrg={selectedOrg}

                        value={selected.dateRange}
                        setValue={(value) => {setSelectedKey('dateRange', value)}}
                    />
                    <SiteTreeMultiSelect
                        selectedOrg={selectedOrg}
                        value={selected.sites}
                        setValue={(value) => {setSelectedKey('sites', value)}}
                        // style={{width: '450px'}}
                    />
                </div>
                <SearchComponent
                    masterState={masterState}
                    state={filteredState}
                    setState={setFilteredState}

                    searchOptions={[
                        {keyName: 'session_id', label: 'Id'},
                        {keyName: 'session_name', label: 'Name'},
                        {keyName: 'session_type', label: 'Type'},
                        {keyName: 'status', label: 'Status'},
                        {keyName: 'pending_approval_qty', label: 'Pending Approval'},

                    ]}
                    isLabelInline={false}
                />
            </div>
            <Table
                columns={columns}
                dataSource={filteredState}
                loading={loading}
                size='small'
                key={selectedOrg.orgId}
                pagination={{
                    position: ['bottomRight'],
                    // position: ['topRight'],
                    showSizeChanger: true,
                    defaultPageSize: parseInt(sessionStorage.getItem('encoding-tablePageSize') || 10),
                    defaultCurrent: parseInt(sessionStorage.getItem('encoding-tableCurrentPage') || 1),
                }}
                onChange={(pagination, filters, sorter, extra) => {
                    // console.log('params', pagination, filters, sorter, extra);
                    sessionStorage.setItem('encoding-tablePageSize', pagination.pageSize);
                    sessionStorage.setItem('encoding-tableCurrentPage', pagination.current);
                }}
            />

        </div>
    )
}
