import { message, DatePicker, Table, Tooltip, Checkbox, Image, Space } from 'antd';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react'
import { MdContentCopy } from 'react-icons/md';
import { ExportToExcelButton } from '../../Components/ExportToExcel';
import useFilterOptions from '../../Components/useFilterOptions';
import SearchComponent from '../../Masters/GeneralComponents/SearchComponent';
import { capitalizeFirstLetter, copyToClipboard } from '../Components/Functions';
import moment from 'moment';
import SiteTreeSelectAuto from "../Components/SiteTreeSelectAuto";
import { InputWithLabel } from '../../Components/Components';
import { ProductCodeComponent } from '../Components/ProductCodeDetailsModal';


export default function CustomerStockTable({
    selectedOrg = {},
    permissions = {},
}) {
    const [selected, setSelected] = useState({
        date: moment(),
        site_id: 0,
    });

    const [masterState, setMasterState] = useState([]);

    // Below satate will hold the data filtered after `Our Search Component (SearchComponent)`
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Below state is for the current data shown after filtering and sorting (Exported from Antd Table)
    // (for excel export)
    const [currentTableData, setCurrentTableData] = useState([]);

    const [categoryColumns, setCategoryColumns] = useState([]);
    const filterableColumns = useMemo(() =>
        [...categoryColumns.map(c => c.category_name)]
        , [categoryColumns]);
    const [filterOptions, setFilterOptions] = useFilterOptions(masterState, filterableColumns);
    const [settings, setSettings] = useState({
        showImages: true,
    });

    const fixedColumns = [
        {
            title: 'Product Code',
            dataIndex: 'product_code',
            key: 'product_code',
            render: (text, record) => <ProductCodeComponent productCode={text} />,
            width: 140,
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            key: 'qty',
        },
    ];

    const getData = async () => {
        setLoading(true);
        await axios.post('/admin-api/reports/customer_stock_data', {
            site_id: selected.site_id,
            dt: selected.date.format('YYYY-MM-DD'),
        })
            .then(res => {
                const data = res.data.data;
                const newColumns = res.data.columns;

                setData(data);
                setMasterState(data);
                setCategoryColumns(newColumns);
                setCurrentTableData(data);        // Initialising the current table data to the data we got from the API
            })
            .catch(err => {
                // setFilterOptions({});
                setData([]);
                setCategoryColumns([]);
                setCurrentTableData([]);

                console.log(err);
                message.error('Error fetching Customer Stock data');
            })
        setLoading(false);
    }

    useEffect(() => {
        // This useEffect dosent have the selectedOrg as a dependency because if
        // new org is selected, then new sites will be loded ... and that will trigger getData
        // If we add that dependency, then it will trigger getData twice and the first time with previous org's sites
        if (!selected.site_id) {
            setMasterState([]);
            setLoading(false);
            return;
        }
        getData();
    }, [selected.site_id, selected.date])
    // }, [selectedOrg.orgId, selected.sites, selected.date])

    // This is just for UI loading
    useEffect(() => {
        if (selectedOrg.orgId) {
            setLoading(true);
        }
    }, [selectedOrg.orgId]);


    const columns = useMemo(() => [
        ...fixedColumns,
        ...categoryColumns.map(c => {
            return {
                // title: c.category_name,
                title: capitalizeFirstLetter(c.category_name),
                dataIndex: c.category_name,
                key: c.category_name,
                filters: filterOptions[c.category_name],
                onFilter: (value, record) => record[c.category_name] == value,
                filterSearch: true,
            }
        }),
        {
            title: 'Image Url',
            dataIndex: ['image_url', 'image_url', 0],
            key: 'image_url?.image_url?.[0]',
            render: (text, record) => {
                return (
                    <Image
                        src={text || "/image-not-found.png"}
                        alt=""
                        width='100px'
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/image-not-found.png';
                        }}
                    />
                )
            },
            // Aligning the image to the center
            align: 'center',
            hidden: !settings.showImages,
        }
    ].filter(c => c.hidden !== true), [categoryColumns, filterOptions, settings.showImages]);

    const setSelectedKey = (key, value) => {
        setSelected(s => ({
            ...s,
            [key]: value,
        }));
    }

    const setSettingsKey = (key, value) => {
        setSettings(prev => ({ ...prev, [key]: value }));
    }

    return (
        <div className='my-form-outer'>
            <div className='my-form-header'>
                <Space direction='vertical'>
                    <span className='my-form-title'>Customer Stock Data</span>
                    <span>{`Customer's ERP Stock from ${moment(selected.date).format("DD MMM YYYY")}'s opening`}</span>
                </Space>
                <span>
                    <InputWithLabel label='Show Images' isInline={true}>
                        <Checkbox
                            checked={settings.showImages}
                            onChange={(e) => setSettingsKey('showImages', e.target.checked)}
                        />
                    </InputWithLabel>
                </span>
            </div>
            <div className="my-table-filter-row">
                <div className="my-form-multiple-inline-input">

                    <SiteTreeSelectAuto
                        selectedOrg={selectedOrg}
                        value={selected.site_id}
                        // onChange={(value) => {setSelectedKey('site_id', value)}}
                        setValue={(value) => { setSelectedKey('site_id', value) }}
                    />

                    <InputWithLabel label={'Date'}>
                        <DatePicker
                            className="my-form-input"
                            format={"YYYY-MM-DD"}
                            value={selected.date}
                            onChange={(value) => { setSelectedKey('date', value) }}
                            allowClear={false}
                            disabledDate={(current) => { return current && current > moment().endOf('day'); }}
                        />
                    </InputWithLabel>
                </div>
                <div className="actions-outer-div" style={{ alignItems: 'flex-end' }}>
                    <SearchComponent
                        masterState={masterState}
                        state={data}
                        isLabelInline={false}
                        setState={setData}
                        searchOptions={[
                            { keyName: 'product_code', label: 'Product Code' },
                            { keyName: 'qty', label: 'Quantity' },
                            // Inventory Columns
                            ...categoryColumns.map(c => {
                                return {
                                    keyName: c.category_name,
                                    label: capitalizeFirstLetter(c.category_name),
                                }
                            }),
                        ]}
                        defaultSearchKeys={['product_code']}
                        searchDebounceTime={500}
                    />
                    <ExportToExcelButton
                        fileName={`Customer_Stock - ${selectedOrg?.orgName}`}
                        sheets={[
                            {
                                sheetName: 'Customer Stock',
                                data: currentTableData,
                                columns: columns,
                            },
                        ]}
                    />
                </div>
            </div>
            <div>

                {/* <InputWithLabel label="Expiry Time" reqMark={true}>
                    <DatePicker
                    value={details.expiry_time !== "" ?dayjs(details.expiry_time, dateFormat): null}
                    onChange={(date, dateString) => {
                    setSelectedKey("expiry_time", date);
                    }}
                    className="my-form-input"
                    format={dateFormat}
                    />
            </InputWithLabel> */}
            </div>

            <Table
                dataSource={data}
                columns={columns}
                loading={loading}
                size="small"
                pagination={{
                    position: ['bottomRight'],
                    // position: ['topRight'],
                    showSizeChanger: true,
                    defaultPageSize: 20,
                }}
                onChange={(pagination, filters, sorter, extra) => {
                    // console.log('params', pagination, filters, sorter, extra);
                    setCurrentTableData(extra.currentDataSource);
                }}
            />

        </div>


    )
}

