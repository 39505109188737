import React, { useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Input, Select, Table, Modal, message, Checkbox, Tooltip, Tabs, Switch, TimePicker, Divider, Spin, Statistic, Drawer, Popover, InputNumber, Tag, Dropdown, Space } from 'antd';
import { useEffect } from 'react';
import axios from 'axios';
import MyPortal from '../../Components/MyPortal';
import SearchComponent from '../../Masters/GeneralComponents/SearchComponent';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';
import moment from 'moment';
import useFilterOptions from '../../Components/useFilterOptions';
import { MdContentCopy, MdPieChartOutlined } from 'react-icons/md';
import { ExportToExcelButton, exportToExcel } from '../../Components/ExportToExcel';
import { Pie } from '@ant-design/charts';
import { Sunburst } from '@ant-design/plots';
import { InputWithLabel } from '../../Components/Components';
import { ERPvsStockTake_Info, InformationPopup, InvalidEPCs_Info, NotInStock_Info, RawEPCs_Info, ZoneWiseData_Info } from '../../Components/InformationBox';
import { capitalizeFirstLetter, copyToClipboard } from '../Components/Functions';
import ReplenishmentReport from './ReplenishmentReport';
import { ProductCodeComponent } from '../Components/ProductCodeDetailsModal';
import ReplenishmentReportV2 from './ReplenishmentReportV2';
import DisplayInformation, { StockVSErpHelpContent } from './DisplayInformation';

const { TabPane } = Tabs;

export default function OneSessionReport({
    selectedOrg,
    permissions = {},

    homePath = "/inventory/stock-take/sessions",
}) {
    let sessionId = parseInt(useParams().id);
    const navigate = useNavigate();

    // For having the active tab in the url (for refreshing)
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState(searchParams.get('tab') || 'dashboard');
    const changeActiveTab = (key) => {
        setActiveTab(key);
        searchParams.set('tab', key);
        setSearchParams(searchParams, { replace: true });
    }

    const [sessionData, setSessionData] = useState({});
    const [currentTableData, setCurrentTableData] = useState([]);
    const [categoryColumns, setCategoryColumns] = useState([]);
    const [loading, setLoading] = useState(true);

    const [filters, setFilters] = useState({
        defaultFinalStatus: null,
    });
    const setFiltersKey = (key, value) => {
        setFilters(prev => ({
            ...prev,
            [key]: value,
        }))
    }
    // This is for redirecting to erp vs stocktake tab with filter if defaultFinalStatus is set
    useEffect(() => {
        if (filters?.defaultFinalStatus) {
            changeActiveTab('erp-vs-stock-take');
        }
    }, [filters.defaultFinalStatus]);

    const getSessionData = async () => {
        setLoading(true);
        await axios.get('/admin-api/reports/stock_take/summary_by_session_id', {
            params: {
                o_id: selectedOrg.orgId,
                session_id: sessionId,
            }
        })
            .then(res => {
                const data = res.data.data;

                setSessionData(prev => ({
                    ...prev,
                    ...data,
                }));
            })
            .catch(err => {
                console.log(err);
                message.error('Error fetching session data');
            })
        setLoading(false);
    }

    const getFinalStatusPieData = async () => {
        // setLoading(true);
        await axios.get("/admin-api/reports/stock_take/grouped_by_final_status", {
            params: {
                o_id: selectedOrg.orgId,
                session_id: sessionId,
            }
        })
            .then(res => {
                const data = res.data.data;

                setSessionData(prev => ({
                    ...prev,
                    finalStatusPieData: data.map(d => ({
                        name: d.final_status,
                        value: d.count,
                    })),
                }));
            })
            .catch(err => {
                console.log(err);
                message.error('Error fetching final status pie data');
            })
        // setLoading(false);
    }

    const getCategoryData = async () => {
        setLoading(true);
        await axios.get('/admin-api/reports/stock_take/stock_vs_rfid', {
            params: {
                session_id: sessionId,
                o_id: selectedOrg?.orgId,
            }
        })
            .then(res => {
                const data = res.data.data;
                const newColumns = res.data.columns;

                // setData(data);
                // setMasterState(data);
                setCategoryColumns(newColumns);
                setCurrentTableData(data);        // Initialising the current table data to the data we got from the API
            })
            .catch(err => {
                // setFilterOptions({});
                // setData([]);
                setCategoryColumns([]);
                setCurrentTableData([]);

                console.log(err);
                message.error('Error fetching ERP Vs Stock Take data');
            })
        setLoading(false);
    }


    useEffect(() => {
        getSessionData();
        getFinalStatusPieData();
        getCategoryData();
    }, [])


    return (
        <div className='my-form-outer'>
            <div className='my-form-header'>
                <span className='my-form-title' style={{ textDecoration: 'none' }}>
                    ({sessionId}) Session  : {sessionData?.session_name} / <u>{sessionData?.site_name}</u>
                </span>
            </div>
            {/* <MyPortal id='navbar-selector-portal'>
                <span style={{color: 'var(--navbarColor)', textDecoration: 'none'}}>
                    Session : {sessionData?.session_name} ({sessionId}) / {sessionData?.site_name}
                </span>
            </MyPortal> */}
            <div>
                <Tabs
                    tabBarExtraContent={
                        <div>
                            <Button type='danger' onClick={() => navigate(homePath)} >
                                Back
                            </Button>
                        </div>
                    }
                    activeKey={activeTab}
                    onChange={changeActiveTab}
                >
                    <TabPane className="my-form-tabpane-outer" tab="Dashboard" key="dashboard">
                        <div className="my-table-filter-row" >
                            <div style={{ display: 'inline-flex', flexShrink: 0, gap: '20px' }}>
                                <Statistic loading={loading} title="Valid Count" value={sessionData?.valid_count} />
                                <Statistic loading={loading} title="Expected Count" value={sessionData?.expected_count} />
                                <Statistic
                                    loading={loading}
                                    title="Percentage Scanned"
                                    suffix={"%"}
                                    value={
                                        sessionData && sessionData.valid_count && sessionData.expected_count
                                            ? ((sessionData.valid_count / sessionData.expected_count) * 100)
                                            : 0
                                    }
                                    precision={2}
                                    style={{ marginLeft: '30px' }}
                                />

                                <Statistic
                                    loading={loading}
                                    title="Total Scanned Count"
                                    value={sessionData?.total_scanned}
                                // style={{marginLeft: '30px'}}
                                />

                            </div>
                            <div style={{ float: 'right', display: 'inline-flex', flexShrink: 0, gap: '10px' }}>
                                <InvalidEPCsTable
                                    selectedOrg={selectedOrg}
                                    sessionId={sessionId}
                                />
                                <NotInSOHTable
                                    selectedOrg={selectedOrg}
                                    sessionId={sessionId}
                                />
                            </div>
                        </div>
                        <Divider >
                            <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>ERP vs RFID Product Matching - Pie Chart</span>
                        </Divider>
                        <div>
                            <h1 style={{ display: 'flex', justifyContent: 'center' }}>To view more details, simply click on the pie chart.</h1>
                            <FinalStatusPieChart
                                data={sessionData?.finalStatusPieData}
                                loading={loading}
                                setDefaultFinalStatusFilter={(val) => setFiltersKey('defaultFinalStatus', val)}
                            />
                        </div>
                        <Divider >
                            <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>Category Data - Pie Chart</span>
                        </Divider>

                        <div>
                            <SunBurstChart
                                data={currentTableData}
                                categoryColumns={categoryColumns}
                                loading={loading}
                            />
                        </div>
                    </TabPane>
                    <TabPane className="my-form-tabpane-outer" tab="ERP Vs Stock-Take" key="erp-vs-stock-take">
                        <ERPVsStockTake
                            selectedOrg={selectedOrg}
                            sessionId={sessionId}
                            finalStatusPieData={sessionData?.finalStatusPieData}
                            defaultFinalStatusFilter={filters?.defaultFinalStatus}
                            setDefaultFinalStatusFilter={(val) => setFiltersKey('defaultFinalStatus', val)}
                        />
                    </TabPane>
                    <TabPane className="my-form-tabpane-outer" tab="Zonewise Data" key="zonewise-data">
                        <ZoneWiseBarcodeData
                            selectedOrg={selectedOrg}
                            sessionId={sessionId}
                        />
                    </TabPane>
                    <TabPane className="my-form-tabpane-outer" tab="Raw EPC's" key="raw-epcs">
                        <RawEpcsTable
                            selectedOrg={selectedOrg}
                            sessionId={sessionId}
                        />
                    </TabPane>
                    <TabPane className="my-form-tabpane-outer" tab="Box Data" key="box-data">
                        <BoxData
                            selectedOrg={selectedOrg}
                            sessionId={sessionId}
                        />
                    </TabPane>
                    <TabPane className="my-form-tabpane-outer" tab="Replenishment Report" key="replenishment">
                        <ReplenishmentReport
                            selectedOrg={selectedOrg}
                            sessionId={sessionId}
                        />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}


const FinalStatusPieChart = ({
    data = [],
    loading = false,
    setDefaultFinalStatusFilter,
}) => {

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', top: '0%', right: '2%', zIndex: 100 }}>
                <DisplayInformation>
                    <StockVSErpHelpContent />
                </DisplayInformation>
            </div>

            <Pie
                data={data || []}
                angleField="value"
                colorField="name"
                loading={loading}
                label={{
                    content: ({ name, percent, value }) => `${name} (${value})             ${(percent * 100)?.toFixed(2)}%`,
                    type: 'spider',
                    labelHeight: 40,
                    labelLineHeight: 30,
                }}
                appendPadding={30}
                interactions={[{ type: 'element-selected' }, { type: 'element-active' }, { type: 'pie-legend-active' }]}
                // Add the percentage to the legend
                legend={{
                    layout: 'vertical',
                    position: 'right',
                    // itemName: {
                    //     formatter: (name) => `${name} (${data?.find(d => d.name === name)?.value})`,
                    // },
                }}
                // Color if matching => green, else red
                color={(d) => {
                    if (d.name === 'Matching') {
                        return '#2dad10';
                    }
                    else if (d.name === 'High in ERP') {
                        return '#fadc57';
                    }
                    else if (d.name === 'Low in ERP') {
                        return 'red';
                    }
                    // return "#fadc57";
                }}
                tooltip={{
                    showMarkers: false,
                    customContent: (title, items) => {
                        const item = items[0];
                        if (item) {
                            const { name, value, color } = item;
                            console.log({ item });
                            return (
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderRadius: '5px',
                                    marginTop: '6px',
                                    height: "20px",
                                }}>
                                    <div style={{ display: 'flex', justifyContent: 'start', gap: 5 }}>
                                        <div style={{ backgroundColor: color, borderRadius: '50%', width: 10, height: 10 }}></div>
                                        <p style={{ color: '#373737' }}>{name}: {value}</p>
                                    </div>
                                    <p style={{ fontSize: '12px', color: 'black' }}>Tap to View</p>
                                </div>
                            );
                        }
                        return '';
                    },
                }}
                onReady={(plot) => {
                    plot.on('element:click', (evt) => {
                        const data = evt.data.data;
                        // console.log('element:click', evt);
                        setDefaultFinalStatusFilter(data?.name);
                    });
                }}
            />
        </div>
    )
}



const InvalidEPCsTable = ({
    selectedOrg,
    sessionId,
}) => {
    const [masterState, setMasterState] = useState([]);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const getData = async () => {
        setLoading(true);
        await axios.get('/admin-api/reports/stock_take/invalid_epcs_by_session_id', {
            params: {
                session_id: sessionId,
                o_id: selectedOrg?.orgId,
            }
        })
            .then(res => {
                const data = res.data.data;

                setMasterState(data);
                setData(data);
            })
            .catch(err => {
                console.log(err);
                message.error('Error fetching Invalid Epc data');
            })
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, []);

    const columns = [
        {
            title: 'EPC',
            dataIndex: 'epc',
            key: 'epc',
        },
        {
            title: 'Found In Zone',
            dataIndex: 'zone_name',
            key: 'zone_name',
        },
    ];

    return (
        <>
            <span onClick={() => setDrawerOpen(true)} style={{ marginRight: '10px', cursor: 'pointer' }}>
                <Statistic
                    title={
                        <>
                            Invalid EPC's
                            <InformationPopup>
                                {InvalidEPCs_Info}
                            </InformationPopup>
                        </>
                    }
                    value={data?.length}
                    style={{ cursor: 'pointer', color: '#cf1322' }}
                    valueStyle={{ color: '#cf1322' }}
                    loading={loading}
                />
            </span>
            <Drawer
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                width={"80%"}
                title={
                    <div className="my-table-filter-row">
                        <span>Invalid EPC's</span>
                        <Space>
                            <SearchComponent
                                masterState={masterState}
                                state={data}
                                setState={setData}

                                searchOptions={[
                                    { keyName: 'epc', label: 'EPC' },
                                    { keyName: 'zone_name', label: 'Zone' },

                                ]}
                                isLabelInline={true}

                                searchDebounceTime={1000}
                            />
                            <ExportToExcelButton
                                fileName={`Invalid EPC List`}
                                sheets={[
                                    {
                                        sheetName: 'Invalid EPC List Data',
                                        data: data,
                                        columns: columns
                                    }
                                ]}
                            />
                        </Space>
                    </div>
                }
                placement="left"
            >
                <Table
                    dataSource={data}
                    columns={columns}
                    loading={loading}
                    size="small"
                    pagination={{
                        position: ['bottomRight'],
                        // position: ['topRight'],
                        showSizeChanger: true,
                        defaultPageSize: 20,
                    }}
                />
            </Drawer>

        </>
    )

}


const NotInSOHTable = ({
    selectedOrg,
    sessionId,
}) => {
    const [masterState, setMasterState] = useState([]);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const getData = async () => {
        setLoading(true);
        await axios.get('/admin-api/reports/stock_take/not_in_stock_epcs', {
            params: {
                session_id: sessionId,
                o_id: selectedOrg?.orgId,
            }
        })
            .then(res => {
                const data = res.data.data;

                setMasterState(data);
                setData(data);
            })
            .catch(err => {
                console.log(err);
                message.error('Error fetching Not In Stock Epc data');
            })
        setLoading(false);

    }

    useEffect(() => {
        getData();
    }, []);

    const columns = [
        {
            title: 'EPC',
            dataIndex: 'epc',
            key: 'epc',
        },
        {
            title: 'Product Code',
            dataIndex: 'product_code',
            key: 'product_code',
        },
        {
            title: 'Found In Zone',
            dataIndex: 'zone_name',
            key: 'zone_name',
        }
    ];

    return (
        <>
            <span onClick={() => setDrawerOpen(true)} style={{ marginRight: '10px', cursor: 'pointer' }}>
                <Statistic
                    title={
                        <>
                            Not In Stock EPCs
                            <InformationPopup>
                                {NotInStock_Info}
                            </InformationPopup>
                        </>
                    }
                    value={data?.length}
                    style={{ cursor: 'pointer', color: '#cf1322' }}
                    valueStyle={{ color: '#cf1322' }}
                    loading={loading}
                />
            </span>
            <Drawer
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                width={"80%"}
                title={
                    <div className="my-table-filter-row">
                        <span>Not In Stock EPC's</span>
                        <Space>
                            <SearchComponent
                                masterState={masterState}
                                state={data}
                                setState={setData}

                                searchOptions={[
                                    { keyName: 'epc', label: 'EPC' },
                                    { keyName: 'product_code', label: 'Product Code' },
                                    { keyName: 'zone_name', label: 'Zone' },
                                ]}
                                isLabelInline={true}

                                searchDebounceTime={1000}
                            />
                            <ExportToExcelButton
                                fileName={`NOT IN SOH List`}
                                sheets={[
                                    {
                                        sheetName: 'NOT IN SOH Data',
                                        data: data,
                                        columns: columns
                                    }
                                ]}
                            />
                        </Space>
                    </div>
                }
                placement="left"
            >
                <Table
                    dataSource={data}
                    columns={columns}
                    loading={loading}
                    size="small"
                    pagination={{
                        position: ['bottomRight'],
                        // position: ['topRight'],
                        showSizeChanger: true,
                        defaultPageSize: 10,
                    }}
                />
            </Drawer>
        </>
    )
}


const ExpandedDateWiseBarcode = ({ sessionId, productCode }) => {

    const columns = [
        {
            title: 'Date',
            dataIndex: 'created_date',
            key: 'created_date',
            render: (text, record) => moment(text).format('Do MMMM')
        },
        {
            title: 'Session Id',
            dataIndex: 'session_id',
            key: 'session_id',
            render: (text) => <div>{text || "-"}</div>
        },
        {
            title: 'Stock Qty',
            dataIndex: 'stock_qty',
            key: 'stock_qty',
        },
        {
            title: 'RFID QTY',
            dataIndex: 'rf_count',
            key: 'rf_count',
        },
        {
            title: 'Difference',
            dataIndex: 'diff',
            key: 'diff',
        },
        {
            title: 'Final Status',
            dataIndex: 'final_status',
            key: 'final_status',
            render: (text, record) => {
                let color = 'red';
                if (text.includes('Matching')) {
                    color = 'green';
                }

                return (
                    <div style={{ backgroundColor: color, color: 'white', textAlign: 'center' }} >
                        {text}
                    </div>
                )
            }
        }
    ]

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);


    useEffect(() => {
        axios.get(`admin-api/reports/erp_vs_rfid_datewise_barcode?session_id=${sessionId}&&product_code=${productCode}&limit=5`)
            .then(res => {
                setData(res.data.data);
                setLoading(false);
            }).catch(err => {
                // console.log(err);
            })

    }, [])

    return (
        <Table
            dataSource={data}
            loading={loading}
            columns={columns}
            pagination={false}
        />
    )

}


const ERPVsStockTake = ({
    selectedOrg,
    sessionId,
    finalStatusPieData,
    defaultFinalStatusFilter,
    setDefaultFinalStatusFilter,
}) => {
    // Below is the main state that will hold all the data from API
    const [masterState, setMasterState] = useState([]);

    // Below satate will hold the data filtered after `Our Search Component (SearchComponent)`
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Below state is for the current data shown after filtering and sorting (Exported from Antd Table)
    // (for excel export)
    const [currentTableData, setCurrentTableData] = useState([]);

    /*
        Example Of data
        [
            {
                c1	:	CK MENS UG
                c2	:	Boxer
                c3	:	MIX
                c4	:	34
                c5	:	MEN
                product_code	:	887000000000
                stock_qty	:	1
                rf_count	:	3
                diff	:	-2
                final_status	:	Low in ERP
                stock_status	:	Negative

            }
        ]
    */
    const [categoryColumns, setCategoryColumns] = useState([]);

    // The below var is memoized to avoid re-rendering of the filters when the data is changed
    // If not, the filters will be calculated again and again infinitely
    const filterableColumns = useMemo(() =>
        ['stock_qty', 'rf_count', 'final_status', ...categoryColumns.map(c => c.category_name)]
        , [categoryColumns]);
    const [filterOptions, setFilterOptions] = useFilterOptions(masterState, filterableColumns);

    const fixedColumns = [
        {
            title: 'Product Code',
            dataIndex: 'product_code',
            key: 'product_code',
            render: (text, record) => <ProductCodeComponent productCode={text} />,
        },
        {
            title: 'Stock Qty',
            dataIndex: 'stock_qty',
            key: 'stock_qty',
            width: 100,
            filters: filterOptions.stock_qty,
            onFilter: (value, record) => record.stock_qty == value,
            filterSearch: true,
        },
        {
            title: 'RF Count',
            dataIndex: 'rf_count',
            key: 'rf_count',
            width: 100,
            filters: filterOptions.rf_count,
            onFilter: (value, record) => record.rf_count == value,
            filterSearch: true,
        },
        {
            title: 'Diff',
            dataIndex: 'diff',
            key: 'diff',
            width: 100,
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.diff - b.diff,
        },
        {
            title: 'Final Status',
            dataIndex: 'final_status',
            key: 'final_status',
            render: (text, record) => {
                let color = 'red';
                if (text.includes('Matching')) {
                    color = 'green';
                }

                return (
                    <div style={{ backgroundColor: color, textAlign: 'center', borderRadius: '2px', color: '#fff' }} >
                        {text}
                    </div>
                )
            },
            filters: filterOptions.final_status,
            onFilter: (value, record) => record.final_status == value,
            filterSearch: true,
            defaultFilteredValue: defaultFinalStatusFilter ? [defaultFinalStatusFilter] : [],
        }
    ];


    const getData = async () => {
        setLoading(true);
        await axios.get('/admin-api/reports/stock_take/stock_vs_rfid', {
            params: {
                session_id: sessionId,
                o_id: selectedOrg?.orgId,
            }
        })
            .then(res => {
                const data = res.data.data;
                const newColumns = res.data.columns;

                setData(data);
                setMasterState(data);
                setCategoryColumns(newColumns);
                setCurrentTableData(data);        // Initialising the current table data to the data we got from the API
            })
            .catch(err => {
                // setFilterOptions({});
                setData([]);
                setCategoryColumns([]);
                setCurrentTableData([]);

                console.log(err);
                message.error('Error fetching ERP Vs Stock Take data');
            })
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, [sessionId]);


    const columns = useMemo(() => [
        ...categoryColumns.map(c => {
            return {
                // title: c.category_name,
                title: capitalizeFirstLetter(c.category_name),
                dataIndex: c.category_name,
                key: c.category_name,
                filters: filterOptions[c.category_name],
                onFilter: (value, record) => record[c.category_name] == value,
                filterSearch: true,
            }
        }),
        ...fixedColumns,
    ], [categoryColumns, filterOptions, defaultFinalStatusFilter]);


    return (
        <div >
            <Tabs
                tabPosition='top'
                key={defaultFinalStatusFilter}
                size='small'
                style={{ padding: "0px" }}
                tabBarExtraContent={
                    <div className='my-form-header'>
                        <SearchComponent
                            masterState={masterState}
                            state={data}
                            setState={setData}
                            searchOptions={[
                                { keyName: 'product_code', label: 'Product Code' },
                                { keyName: 'final_status', label: 'Final Status' },
                                // Inventory Columns
                                ...categoryColumns.map(c => {
                                    return {
                                        keyName: c.category_name,
                                        label: capitalizeFirstLetter(c.category_name),
                                    }
                                }),
                            ]}
                            defaultSearchKeys={['product_code']}
                            searchDebounceTime={500}
                        />
                        <span className="actions-outer-div" style={{ marginLeft: '20px' }}>
                            <InformationPopup iconStyle={{ fontSize: '20px', marginLeft: '0' }} >
                                {ERPvsStockTake_Info}
                            </InformationPopup>
                            <ExportToExcelButton
                                fileName={`Session ${sessionId} - Barcode Wise`}
                                sheets={[
                                    {
                                        sheetName: 'RFID vs ERP Comparison',
                                        data: currentTableData,
                                        columns: columns,
                                    }
                                ]}
                                buttonDisabled={loading}
                                buttonLoading={loading}
                            />
                            <Button onClick={() => { getData() }} loading={loading} disabled={loading} >Refresh</Button>
                            <DisplayInformation>
                                <StockVSErpHelpContent />
                            </DisplayInformation>
                        </span>
                    </div>
                }
            >
                <TabPane tab="Table" key="1">
                    <Table
                        dataSource={data}
                        columns={columns}
                        loading={loading}
                        size="small"
                        pagination={{
                            position: ['bottomRight'],
                            // position: ['topRight'],
                            showSizeChanger: true,
                            defaultPageSize: 10,
                        }}
                        onChange={(pagination, filters, sorter, extra) => {
                            // console.log('params', pagination, filters, sorter, extra);
                            setCurrentTableData(extra.currentDataSource);
                        }}
                        rowKey={record => record.product_code}
                        expandable={{
                            rowExpandable: record => record?.product_code?.length > 0,
                            expandedRowRender: record => <div>
                                <ExpandedDateWiseBarcode productCode={record.product_code} sessionId={sessionId} />
                            </div>
                        }}
                    />
                </TabPane>
                {/* <TabPane tab="Category Data Chart" key="2">
                    <SunBurstChart
                        data={currentTableData}
                        categoryColumns={categoryColumns}
                        loading={loading}
                    />

                </TabPane>
                <TabPane tab="Final Status Pie" key="3">
                    <FinalStatusPieChart
                        data={finalStatusPieData}
                        loading={loading}
                        setDefaultFinalStatusFilter={setDefaultFinalStatusFilter}
                    />
                </TabPane> */}
            </Tabs>
        </div>
    )
}


const RawEpcsTable = ({
    sessionId,
    selectedOrg,
}) => {
    const [masterState, setMasterState] = useState([]);

    // The below state is after the search is done
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getData = async () => {
        setLoading(true);
        await axios.get('/admin-api/reports/stock_take/all_epcs_by_session_id', {
            params: {
                session_id: sessionId,
                o_id: selectedOrg?.orgId,
            }
        })
            .then(res => {
                const data = res.data.data;

                setMasterState(data);
                setData(data);
            })
            .catch(err => {
                console.log(err);
                message.error('Error fetching Raw Epc\'s data');
            })
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, []);

    const columns = [
        {
            title: 'EPC',
            dataIndex: 'epc',
            key: 'epc',
        },
        {
            title: 'Product Code',
            dataIndex: 'product_code',
            key: 'product_code',
            render: (text, record) => <ProductCodeComponent productCode={text} />,
        },
        {
            title: 'Scan Time',
            dataIndex: 'scan_time',
            key: 'scan_time',
            render: (text, record) => moment(text).format('Do MMM YYYY, HH:mm A'),
            sorter: (a, b) => moment(a.created_time) - moment(b.created_time),
        },
        {
            title: 'Zone Name',
            dataIndex: 'zone_name',
            key: 'zone_name',
        },
    ];

    const exportProductCodeQtyData = () => {
        console.log('Exporting Product Code Qty Data');
        const productCodeWiseData = {};
        data.forEach(d => {
            if (!d.product_code) {
                return;
            }
            if (!productCodeWiseData[d.product_code]) {
                productCodeWiseData[d.product_code] = {
                    product_code: d.product_code,
                    qty: 0,
                }
            }
            productCodeWiseData[d.product_code].qty += 1;
        });
        console.log('productCodeWiseData', productCodeWiseData);

        const productCodeWiseDataArray = Object.values(productCodeWiseData);
        const columns = [
            { title: 'Product Code', dataIndex: 'product_code', key: 'product_code' },
            { title: 'Qty', dataIndex: 'qty', key: 'qty' },
        ];

        const fileName = `Session ${sessionId} - Product Code Wise`;
        const sheetName = 'Product Code Wise';
        exportToExcel({
            fileName: fileName,
            sheets: [
                { sheetName: sheetName, data: productCodeWiseDataArray, columns: columns },
            ]
        })
    }

    return (
        <div className='my-form-outer'>
            <div className='my-form-header'>
                <span className="actions-outer-div">
                    <Button onClick={() => { getData() }} loading={loading} disabled={loading} >Refresh</Button>
                    <Dropdown
                        placement="bottomLeft"
                        menu={{
                            items: [
                                {
                                    key: '1',
                                    label: 'All Scanned EPC\'s',
                                    onClick: () => {
                                        exportToExcel({
                                            fileName: `Session ${sessionId} - Raw EPCs`,
                                            sheets: [
                                                { sheetName: 'Raw EPCs', data: masterState, columns: columns },
                                            ]
                                        })
                                    }
                                },
                                {
                                    key: '2',
                                    label: 'Product code Wise Scanned Qty',
                                    onClick: () => { exportProductCodeQtyData() }
                                },
                            ]
                        }}
                    >
                        <Button type='primary'>Export</Button>
                    </Dropdown>
                    <InformationPopup iconStyle={{ fontSize: '20px', marginLeft: '0' }} >
                        {RawEPCs_Info}
                    </InformationPopup>
                </span>
                <SearchComponent
                    masterState={masterState}
                    state={data}
                    setState={setData}
                    searchOptions={[
                        { keyName: 'epc', label: 'EPC' },
                        { keyName: 'product_code', label: 'Product Code' },
                        { keyName: 'zone_name', label: 'Zone Name' },
                    ]}
                    searchDebounceTime={300}
                />
            </div>
            <Table
                columns={columns}
                dataSource={data}
                loading={loading}
                size="small"
                pagination={{
                    position: ['bottomRight'],
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
            />
        </div>
    )


}


const ZoneWiseBarcodeData = ({
    selectedOrg,
    sessionId,
}) => {
    // Below is the main state that will hold all the data from API
    const [masterState, setMasterState] = useState([]);

    // Below satate will hold the data filtered after `Our Search Component (SearchComponent)`
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Below state is for the current data shown after filtering and sorting (Exported from Antd Table)
    // (for excel export)
    const [currentTableData, setCurrentTableData] = useState([]);

    /*
        Example Of data
        [
            {
                c1	:	CK MENS UG
                c2	:	Boxer
                c3	:	MIX
                c4	:	34
                c5	:	MEN
                product_code	:	887000000000
                stock_qty	:	1
                rf_count	:	3
                diff	:	-2
                final_status	:	Low in ERP
                stock_status	:	Negative

            }
        ]
    */
    const [categoryColumns, setCategoryColumns] = useState([]);

    // The below var is memoized to avoid re-rendering of the filters when the data is changed
    // If not, the filters will be calculated again and again infinitely
    const filterableColumns = useMemo(() =>
        ['count', 'zone_name', ...categoryColumns.map(c => c.category_name)]
        , [categoryColumns]);
    const [filterOptions, setFilterOptions] = useFilterOptions(masterState, filterableColumns);

    const fixedColumns = [
        {
            title: 'Product Code',
            dataIndex: 'product_code',
            key: 'product_code',
            render: (text, record) => <ProductCodeComponent productCode={text} />,
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            width: 100,
            filters: filterOptions.count,
            onFilter: (value, record) => record.count == value,
            filterSearch: true,
        },
        {
            title: 'Zone Name',
            dataIndex: 'zone_name',
            key: 'zone_name',
            // width: 100,
            filters: filterOptions.zone_name,
            onFilter: (value, record) => record.zone_name == value,
            filterSearch: true,
        },
    ];


    const getData = async () => {
        setLoading(true);
        await axios.get('/admin-api/reports/stock_take/zone_wise_for_session', {
            params: {
                session_id: sessionId,
                o_id: selectedOrg?.orgId,
            }
        })
            .then(res => {
                const data = res.data.data;
                const newColumns = res.data.columns;

                setData(data);
                setMasterState(data);
                setCategoryColumns(newColumns);
                setCurrentTableData(data);        // Initialising the current table data to the data we got from the API
            })
            .catch(err => {
                // setFilterOptions({});
                setData([]);
                setCategoryColumns([]);
                setCurrentTableData([]);

                console.log(err);
                message.error('Error fetching Zonewise data');
            })
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, [sessionId]);


    const columns = useMemo(() => [
        ...categoryColumns.map(c => {
            return {
                // title: c.category_name,
                title: capitalizeFirstLetter(c.category_name),
                dataIndex: c.category_name,
                key: c.category_name,
                filters: filterOptions[c.category_name],
                onFilter: (value, record) => record[c.category_name] == value,
                filterSearch: true,
            }
        }),
        ...fixedColumns,
    ], [categoryColumns, filterOptions]);

    // Reduce the data to zonewise count
    const pieChartData = useMemo(() => {
        const data = {};
        masterState.forEach(d => {
            if (data[d.zone_name]) {
                data[d.zone_name] += d.count;
            } else {
                data[d.zone_name] = d.count;
            }
        });
        return Object.keys(data).map(k => ({ name: k, value: data[k] }));
    }, [masterState]);




    return (
        <div className='my-form-outer'>
            <div className='my-form-header'>
                {/* <span className='my-form-title' style={{textDecoration: 'none', fontSize: '16px'}}>
                    ERP Vs Stock Take
                </span> */}
                <span className="actions-outer-div">
                    <Button onClick={() => { getData() }} loading={loading} disabled={loading} >Refresh</Button>
                    <ExportToExcelButton
                        fileName={`Session ${sessionId} - Zone Wise Data`}
                        sheets={[
                            {
                                sheetName: 'Zone Wise Data',
                                data: currentTableData,
                                columns: columns,
                            }
                        ]}
                        buttonDisabled={loading}
                        buttonLoading={loading}
                    />

                    <InformationPopup iconStyle={{ fontSize: '20px', marginLeft: '0' }} >
                        {ZoneWiseData_Info}
                    </InformationPopup>
                    {
                        pieChartData.length > 0 && (
                            <Popover
                                placement="bottom"
                                title={null}
                                destroyTooltipOnHide={true}
                                style={{ width: '100%', padding: '40px' }}
                                // trigger="click"
                                content={
                                    <>
                                        <Pie
                                            data={pieChartData}
                                            angleField="value"
                                            colorField="name"
                                            label={{
                                                type: 'inner',
                                                offset: '-30%',
                                                content: ({ name, percent }) => `${name}\n${(percent * 100)?.toFixed(2)}%`,
                                                style: {
                                                    fontSize: 14,
                                                    // textAlign: 'center',
                                                },
                                            }}
                                            legend={false}
                                        />
                                    </>
                                }
                            >
                                <Button icon={<MdPieChartOutlined />} >
                                    Pie Chart
                                </Button>
                            </Popover>
                        )
                    }
                </span>
                <SearchComponent
                    masterState={masterState}
                    state={data}
                    setState={setData}
                    searchOptions={[
                        { keyName: 'product_code', label: 'Product Code' },
                        { keyName: 'zone_name', label: 'Zone Name' },
                        // Inventory Columns
                        ...categoryColumns.map(c => {
                            return {
                                keyName: c.category_name,
                                label: capitalizeFirstLetter(c.category_name),
                            }
                        }),
                    ]}
                    defaultSearchKeys={['product_code', 'zone_name']}
                    searchDebounceTime={500}
                />

            </div>
            <Table
                dataSource={data}
                columns={columns}
                loading={loading}
                size="small"
                pagination={{
                    position: ['bottomRight'],
                    // position: ['topRight'],
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
                onChange={(pagination, filters, sorter, extra) => {
                    // console.log('params', pagination, filters, sorter, extra);
                    setCurrentTableData(extra.currentDataSource);
                }}
            />
        </div>
    )
}


function SunBurstChart({
    data = [],
    loading,
    categoryColumns = [],
    hierarchyLevel = 3
}) {
    // const [graphData, setGraphData] = React.useState([]);
    const [hierLevel, setHierLevel] = React.useState(hierarchyLevel);
    const [sunburstSumKey, setSunburstSumKey] = React.useState('rf_count');


    const graphData = React.useMemo(() => {
        var sunburstData = {};

        data.forEach(d => {
            var sunburstRef = sunburstData;
            for (var cIndex = 0; cIndex < Math.min(categoryColumns.length, hierLevel); cIndex++) {
                const columnDetails = categoryColumns[cIndex];
                if (!sunburstRef[d[columnDetails.category_name]]) {
                    sunburstRef[d[columnDetails.category_name]] = {
                        category_name: columnDetails.category_name,
                        sum: 0,
                        children: {},
                    };
                }

                sunburstRef[d[columnDetails.category_name]].sum += d[sunburstSumKey];
                sunburstRef = sunburstRef[d[columnDetails.category_name]].children;

            }

        })

        // Recursively convert to array

        const convertNestedJsonToArr = (json = {}) => {
            var arr = [];
            for (var key in json) {
                arr.push({
                    name: key,
                    category_name: json[key].category_name,
                    sum: json[key].sum,
                    children: convertNestedJsonToArr(json[key].children),
                })
            }
            return arr;
        }

        console.log('sunburstData', convertNestedJsonToArr(sunburstData));
        return {
            name: 'All',
            children: convertNestedJsonToArr(sunburstData)
        }
    }, [data, sunburstSumKey, hierLevel, categoryColumns]);


    const handleChange = (value) => {
        setSunburstSumKey(value);
    }

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', top: '30px', left: '0', zIndex: '1', display: 'inline-flex', flexDirection: 'column', gap: '4px' }}>
                <InputWithLabel label="Sum Key" >
                    <Select style={{ width: '200px' }} value={sunburstSumKey} onChange={handleChange}>
                        <Select.Option value="stock_qty">ERP Stock</Select.Option>
                        <Select.Option value="rf_count">RFID Count</Select.Option>
                    </Select>
                </InputWithLabel>
                <InputWithLabel label="Hierarchy Level" >
                    <div style={{ display: 'inline-flex', gap: '4px', alignItems: 'center' }}>
                        <InputNumber style={{ width: '180px' }} value={hierLevel} onChange={setHierLevel} min={1} max={categoryColumns.length}
                            formatter={value => `${value} Categories`}
                        />
                        <InformationPopup iconStyle={{ fontSize: '20px', marginLeft: '0' }} >
                            The number of categories to be used in the sunburst chart.
                            {/* <br />Current Categories: {categoryColumns.map(c => c.category_name).join(', ')} */}
                            <br />The maximum number of categories is {categoryColumns.length}
                        </InformationPopup>
                    </div>
                    <div style={{ marginTop: '10px' }}>
                        {
                            categoryColumns.map((c, c_i) => {
                                return (
                                    <Tag color={c_i < hierLevel ? 'blue' : 'default'} onClick={() => setHierLevel(c_i + 1)} style={{ cursor: 'pointer' }} >
                                        {capitalizeFirstLetter(c.category_name)}
                                    </Tag>
                                )
                            })
                        }
                    </div>
                </InputWithLabel>

            </div>
            <Sunburst
                // key={graphData.length}
                style={{ height: '70vh' }}
                data={graphData}
                innerRadius={0.2}
                width={700}
                height={700}
                hierarchyConfig={{
                    field: 'sum'
                }}
                legend={{
                    visible: true,
                    layout: 'horizontal',
                    position: 'right',
                }}
                tooltip={{
                    visible: true,
                }}
                label={{
                    // autoRotate: false,
                    // type: 'spider',
                    formatter: (params) => {
                        return `${params.name}\n(${params.sum})`
                    },

                    layout: [
                        {
                            type: 'limit-in-shape',
                        },
                    ],
                }}
            />
        </div>
    )
}

const BoxData = ({
    selectedOrg,
    sessionId,
}) => {
    // Below is the main state that will hold all the data from API
    const [masterState, setMasterState] = useState([]);

    // Below satate will hold the data filtered after `Our Search Component (SearchComponent)`
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Below state is for the current data shown after filtering and sorting (Exported from Antd Table)
    // (for excel export)
    const [currentTableData, setCurrentTableData] = useState([]);

    /*
        Example Of data
        [
            {
                c1	:	CK MENS UG
                c2	:	Boxer
                c3	:	MIX
                c4	:	34
                c5	:	MEN
                product_code	:	887000000000
                stock_qty	:	1
                rf_count	:	3
                diff	:	-2
                final_status	:	Low in ERP
                stock_status	:	Negative

            }
        ]
    */
    const [categoryColumns, setCategoryColumns] = useState([]);

    // The below var is memoized to avoid re-rendering of the filters when the data is changed
    // If not, the filters will be calculated again and again infinitely
    const filterableColumns = useMemo(() =>
        ['count', 'zone_name', ...categoryColumns.map(c => c.category_name)]
        , [categoryColumns]);
    const [filterOptions, setFilterOptions] = useFilterOptions(masterState, filterableColumns);

    const fixedColumns = [
        {
            title: 'Product Code',
            dataIndex: 'product_code',
            key: 'product_code',
            render: (text, record) => <ProductCodeComponent productCode={text} />,
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            width: 100,
            filters: filterOptions.count,
            onFilter: (value, record) => record.count == value,
            filterSearch: true,
        },
        {
            title: 'Zone Name',
            dataIndex: 'zone_name',
            key: 'zone_name',
            // width: 100,
            filters: filterOptions.zone_name,
            onFilter: (value, record) => record.zone_name == value,
            filterSearch: true,
        },
        {
            title: 'Site Name',
            dataIndex: 'site_name',
            key: 'site_name',
            // width: 100,
            filters: filterOptions.site_name,
            onFilter: (value, record) => record.site_name == value,
            filterSearch: true,
        },
    ];


    const getData = async () => {
        setLoading(true);
        await axios.get('/admin-api/reports/stock_take/zone_wise_for_session_box_data', {
            params: {
                session_id: sessionId,
                o_id: selectedOrg?.orgId,
            }
        })
            .then(res => {
                const data = res.data.data;
                const newColumns = res.data.columns;

                setData(data);
                setMasterState(data);
                setCategoryColumns(newColumns);
                setCurrentTableData(data);        // Initialising the current table data to the data we got from the API
            })
            .catch(err => {
                // setFilterOptions({});
                setData([]);
                setCategoryColumns([]);
                setCurrentTableData([]);

                console.log(err);
                message.error('Error fetching box data');
            })
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, [sessionId]);


    const columns = useMemo(() => [
        ...categoryColumns.map(c => {
            return {
                // title: c.category_name,
                title: capitalizeFirstLetter(c.category_name),
                dataIndex: c.category_name,
                key: c.category_name,
                filters: filterOptions[c.category_name],
                onFilter: (value, record) => record[c.category_name] == value,
                filterSearch: true,
            }
        }),
        ...fixedColumns,
    ], [categoryColumns, filterOptions]);

    // Reduce the data to zonewise count
    const pieChartData = useMemo(() => {
        const data = {};
        masterState.forEach(d => {
            if (data[d.zone_name]) {
                data[d.zone_name] += d.count;
            } else {
                data[d.zone_name] = d.count;
            }
        });
        return Object.keys(data).map(k => ({ name: k, value: data[k] }));
    }, [masterState]);




    return (
        <div className='my-form-outer'>
            <div className='my-form-header'>
                {/* <span className='my-form-title' style={{textDecoration: 'none', fontSize: '16px'}}>
                    ERP Vs Stock Take
                </span> */}
                <span className="actions-outer-div">
                    <Button onClick={() => { getData() }} loading={loading} disabled={loading} >Refresh</Button>
                    <ExportToExcelButton
                        fileName={`Session ${sessionId} - Box Data`}
                        sheets={[
                            {
                                sheetName: 'Box Data',
                                data: currentTableData,
                                columns: columns,
                            }
                        ]}
                        buttonDisabled={loading}
                        buttonLoading={loading}
                    />

                    <InformationPopup iconStyle={{ fontSize: '20px', marginLeft: '0' }} >
                        {ZoneWiseData_Info}
                    </InformationPopup>
                    {
                        pieChartData.length > 0 && (
                            <Popover
                                placement="bottom"
                                title={null}
                                destroyTooltipOnHide={true}
                                style={{ width: '100%', padding: '40px' }}
                                // trigger="click"
                                content={
                                    <>
                                        <Pie
                                            data={pieChartData}
                                            angleField="value"
                                            colorField="name"
                                            label={{
                                                type: 'inner',
                                                offset: '-30%',
                                                content: ({ name, percent }) => `${name}\n${(percent * 100)?.toFixed(2)}%`,
                                                style: {
                                                    fontSize: 14,
                                                    // textAlign: 'center',
                                                },
                                            }}
                                            legend={false}
                                        />
                                    </>
                                }
                            >
                                <Button icon={<MdPieChartOutlined />} >
                                    Pie Chart
                                </Button>
                            </Popover>
                        )
                    }
                </span>
                <SearchComponent
                    masterState={masterState}
                    state={data}
                    setState={setData}
                    searchOptions={[
                        { keyName: 'product_code', label: 'Product Code' },
                        { keyName: 'zone_name', label: 'Zone Name' },
                        // Inventory Columns
                        ...categoryColumns.map(c => {
                            return {
                                keyName: c.category_name,
                                label: capitalizeFirstLetter(c.category_name),
                            }
                        }),
                    ]}
                    defaultSearchKeys={['product_code', 'zone_name']}
                    searchDebounceTime={500}
                />

            </div>
            <Table
                dataSource={data}
                columns={columns}
                loading={loading}
                size="small"
                pagination={{
                    position: ['bottomRight'],
                    // position: ['topRight'],
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
                onChange={(pagination, filters, sorter, extra) => {
                    // console.log('params', pagination, filters, sorter, extra);
                    setCurrentTableData(extra.currentDataSource);
                }}
            />
        </div>
    )
}