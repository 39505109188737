import React, { useMemo, useState } from "react";
import { Button, message, Tabs, Spin, Statistic, Table, Input, Checkbox, Divider, Select, Upload, Tag, Space, Switch, Popconfirm } from 'antd';
import { useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate, Link, useSearchParams } from "react-router-dom";
import { MdOutlineEditOff } from "react-icons/md";
import moment from "moment";
import SearchComponent from "../../Masters/GeneralComponents/SearchComponent";
import { InputWithLabel } from "../../Components/Components";
import SiteTreeSelectAuto from "../Components/SiteTreeSelectAuto";
import { validateEncodeSessionForm } from "../../Components/Validations";
import { Column } from '@ant-design/plots';
import { ExportToExcelButton, exportToExcel } from "../../Components/ExportToExcel";
import { Helmet } from "react-helmet";
// HiOutlineUpload
import { HiOutlineUpload } from "react-icons/hi";
import useFilterOptions from "../../Components/useFilterOptions";

const { TabPane } = Tabs;

const statusColorMap = {
    done: "#61C149", close: "#E04B4B", pending_approval: "#EE9322", pending: "#EE9322",
    error: "#E04B4B", need_approval: "#088395", encoding_inprogress: "#E9B824", active: "#61C149", cancel: "#cc0000"
};

export default function EditEPCGenerationSession({
    selectedOrg = {},
    isAddNew = true,
    permissions = {},

    homePath = "/tools/epc-generation/sessions",
    editPath = "/tools/epc-generation/sessions/edit",
}) {
    let sessionId = parseInt(useParams().id);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(searchParams.get('tab') || 'details');
    const changeActiveTab = (key) => {
        setActiveTab(key);
        searchParams.set('tab', key);
        setSearchParams(searchParams, { replace: true });
    }

    // Here will be the default state of the form (For add new)
    const [details, setDetails] = useState({
        ref_number: '',
        session_name: moment().format('DD MMM, HH:mm:a'),
        session_type: 'planned',
        site_id: 0,
        check_in_product_master: true,
        is_re_encoding_allowed: false,

        created_by: '',
        status: '',
    });
    // This state is for validation Errors
    // Key is the name of the field and value is a json {errors: [{message, type}, {...}], help: ""}
    const [errors, setErrors] = useState({});

    const [selectOptions, setSelectOptions] = useState({
        session_types: [
            { value: 'planned', label: 'Planned' },
            { value: 'unplanned', label: 'Unplanned' },
        ],
    });

    const getDetails = async () => {
        setLoading(true);
        await axios.get('/encoding/session_details', {
            params: {
                o_id: selectedOrg.orgId,
                session_id: sessionId,
            }
        })
            .then(res => {
                const data = res.data.data;

                setDetails(prev => ({
                    ...prev,

                    ref_number: data.ref_number || '',
                    session_name: data.session_name || 'name',
                    session_type: data.session_type || 'unplanned',
                    site_id: data.site_id || 0,
                    check_in_product_master: data.check_in_product_master || false,
                    is_re_encoding_allowed: data.is_re_encoding_allowed || false,

                    created_by: data.username || '',
                    status: data.status || '',
                }));
            })
            .catch(err => {
                // If error status is 404, then redirect to home
                if (err.response?.status === 404) {
                    message.error(err.response?.data?.detail || 'Session not found');
                    navigate(homePath);
                    return;
                }

                navigate(homePath);
                console.log(err);
                message.error('Error fetching data');
            });
        setLoading(false);
    }

    // HIGHLIGHT: This is the main useEffect .......
    useEffect(() => {
        // getOptions();
        if (isAddNew) {
            setLoading(false);
        }
        else {
            getDetails();
        }
    }, []);


    const setDetailsKey = (key, value) => {
        setDetails(d => ({
            ...d,
            [key]: value,
        }));

    }

    async function changeSessionStatus() {
        setLoading(true);
        await axios.get(`/encoding/change_session_status?e_session_id=${sessionId}`)
            .then(res => {
                getDetails();
                message.success("Session Status Change");
            })
            .catch(err => {
                console.log(err);
                message.error('Error to change session status');
            })
        setLoading(false);
    }

    async function cancelSession() {
        setLoading(true);
        await axios.post(`/encoding/cancel_session?e_session_id=${sessionId}`)
            .then(res => {
                getDetails();
                message.success("Session cancel successfully");
            })
            .catch(error => {
                if (error.response) {
                    console.log(error);
                    message.error(error.response?.data?.detail)
                } else {
                    console.log(error);
                    message.error(error?.message)
                }
            })
        setLoading(false);
    }

    const handleSubmit = async () => {
        setLoading(true);

        let d, e, errorTab, errStatus, errorsIn;
        ({ d, e, errorTab, errStatus, errorsIn } = validateEncodeSessionForm(details, errors, isAddNew));
        setDetails(prev => ({ ...prev, ...d }));
        setErrors(prev => ({ ...prev, ...e }));

        if (errStatus === "error") {
            console.log("Error in form : ", errorsIn);
            setActiveTab(errorTab);

            setLoading(false);
            return;
        }

        if (isAddNew) {
            await axios.post('/encoding/create_session', {
                o_id: selectedOrg.orgId,
                session_name: details.session_name,
                ref_number: details.ref_number,
                session_type: details.session_type,
                site_id: details.site_id,
                check_in_product_master: details.check_in_product_master,
                is_re_encoding_allowed: details.is_re_encoding_allowed,
            })
                .then(res => {
                    const data = res.data.data;

                    message.success('Session created successfully');
                    navigate(`${editPath}/${data.e_session_id}?tab=planned-tags`);
                })
                .catch(err => {
                    console.log(err);
                    message.error('Error creating session');
                });
        }
        else {
            await axios.put('/encoding/edit_session', {
                o_id: selectedOrg.orgId,
                e_session_id: sessionId,
                session_name: details.session_name,
                ref_number: details.ref_number,
                check_in_product_master: details.check_in_product_master,
                is_re_encoding_allowed: details.is_re_encoding_allowed,
            })
                .then(res => {
                    const data = res.data.data;

                    message.success('Session updated successfully');
                    navigate(homePath);
                })
                .catch(err => {
                    console.log(err);
                    message.error('Error updating session');
                });
        }
        setLoading(false);
    }

    const removeUnderscore = (text) => {
        const stringWithSpaces = text.replaceAll("_", " ");
        return stringWithSpaces
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    return (
        <div className='my-form-outer'>
            <div className='my-form-header'>
                <span className='my-form-title'>
                    {isAddNew ? "Create New Session" : "Session Details"}
                </span>
            </div>
            <div>
                <Tabs
                    tabBarExtraContent={
                        <div>
                            <Space>
                                <Button type={activeTab === 'details' ? 'ghost' : 'danger'} onClick={() => navigate(homePath)}>
                                    Back
                                </Button>

                                {(activeTab === 'details') && (permissions?.edit) && ((details?.status != "cancel") && !isAddNew) &&
                                    <Popconfirm
                                        title="Once the session is cancelled it cannot be activated again"
                                        onConfirm={() => cancelSession()}
                                        okText="Confirm"
                                        cancelText="Cancel"
                                        placement="bottom"
                                    >
                                        <Button type="danger" loading={loading}>
                                            Cancel  Session
                                        </Button>
                                    </Popconfirm>}
                            </Space>
                            {
                                activeTab === 'details' &&
                                <Button style={{ marginLeft: '5px' }} type="primary" onClick={handleSubmit} loading={loading}
                                    disabled={loading
                                        // || !permissions.edit
                                    }
                                >
                                    {isAddNew ? "Create" : "Save"}
                                </Button>
                            }
                        </div>
                    }
                    activeKey={activeTab}
                    onChange={changeActiveTab}
                >
                    <TabPane className="my-form-tabpane-outer" tab="Session Details" key="details">
                        <Spin
                            spinning={loading}
                        // spinning={loading || !permissions.edit}
                        // tip={(!loading && !permissions.edit) && <>You Cannot Edit</>}
                        // indicator={(!loading && !permissions.edit) && <MdOutlineEditOff />}
                        >
                            <div className="my-form-multiple-inline-input">
                                <InputWithLabel label="Session Name" error={errors.session_name} reqMark={true}>
                                    <Input
                                        value={details.session_name}
                                        onChange={(e) => setDetailsKey('session_name', e.target.value)}
                                        className="my-form-input"
                                        status={errors?.session_name?.errors?.[0]?.msg && "error"}
                                    />
                                </InputWithLabel>
                                <InputWithLabel label="Ref No" error={errors.ref_number} reqMark={true}>
                                    <Input
                                        value={details.ref_number}
                                        onChange={(e) => setDetailsKey('ref_number', e.target.value)}
                                        className="my-form-input"
                                        status={errors?.ref_number?.errors?.[0]?.msg && "error"}
                                    />
                                </InputWithLabel>

                                <InputWithLabel label="Session Id">
                                    <Input
                                        value={sessionId ? sessionId : ""}
                                        disabled
                                        className="my-form-input"
                                    />
                                </InputWithLabel>
                            </div>

                            <div className="my-form-multiple-inline-input" style={{ gap: '20px', margin: '10px 0px' }}>
                                <SiteTreeSelectAuto
                                    selectedOrg={selectedOrg}
                                    value={details.site_id}
                                    setValue={(value) => setDetailsKey('site_id', value)}
                                    inputErrors={errors.site_id}
                                    reqMark={true}
                                    initialUnselected={true}
                                    disabled={!isAddNew}
                                />
                                {
                                    !isAddNew && (
                                        <>
                                            <InputWithLabel label="Created By">
                                                <Input
                                                    value={details.created_by}
                                                    disabled
                                                    className="my-form-input"
                                                />
                                            </InputWithLabel>
                                            <InputWithLabel label="Session Status">
                                                <Tag
                                                    color={statusColorMap[details.status] || "#000000"}
                                                    style={{ fontWeight: '700' }}
                                                >
                                                    {removeUnderscore(details.status)}
                                                </Tag>
                                            </InputWithLabel>
                                            {(details?.status != "cancel" && details?.status != "close") && (permissions?.approve_encode_session) && <InputWithLabel label="Approval / Revoke">
                                                <Switch
                                                    onClick={() => changeSessionStatus()}
                                                    checked={details.status == "active" ? true : false} />
                                            </InputWithLabel>}
                                        </>
                                    )
                                }
                            </div>

                            <div className="my-form-multiple-inline-input">
                                <InputWithLabel label="Session Type" error={errors.session_type}>
                                    <Select
                                        value={details.session_type}
                                        onChange={(value) => setDetailsKey('session_type', value)}
                                        className="my-form-input"
                                        status={errors?.session_type?.errors?.[0]?.msg && "error"}
                                        loading={loading || selectOptions?.session_types?.length === 0}
                                        disabled={loading || selectOptions?.session_types?.length === 0 || !isAddNew}
                                    >
                                        {
                                            selectOptions?.session_types.map((item, index) => (
                                                <Select.Option key={index} value={item.value}>{item.label}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                </InputWithLabel>
                                <InputWithLabel label="Re-encoding allowed ?" error={errors.is_re_encoding_allowed} reqMark={true}>
                                    <Checkbox
                                        checked={details.is_re_encoding_allowed}
                                        onChange={e => setDetailsKey("is_re_encoding_allowed", e.target.checked)}
                                        disabled={loading}
                                    />
                                </InputWithLabel>
                                <InputWithLabel label="Check in product master ?" error={errors.is_check_in_product_master} reqMark={true}>
                                    <Checkbox
                                        checked={details.check_in_product_master}
                                        onChange={e => setDetailsKey("check_in_product_master", e.target.checked)}
                                        disabled={loading}
                                    />
                                </InputWithLabel>
                            </div>

                        </Spin>
                    </TabPane>
                    {
                        !isAddNew && (
                            <TabPane className="my-form-tabpane-outer" tab="Planned Tags" key="planned-tags">
                                <PlannedTagsTab
                                    selectedOrg={selectedOrg}
                                    permissions={permissions}

                                    sessionId={sessionId}
                                    sessionDetails={details}
                                />
                            </TabPane>
                        )
                    }
                    {
                        !isAddNew && (
                            <TabPane className="my-form-tabpane-outer" tab="Summary" key="summary">
                                <SessionSummary
                                    selectedOrg={selectedOrg}
                                    permissions={permissions}

                                    sessionId={sessionId}
                                    sessionDetails={details}
                                />
                            </TabPane>
                        )
                    }
                    {
                        !isAddNew && (
                            <TabPane className="my-form-tabpane-outer" tab="Tags" key="tags">
                                <TagsTable
                                    selectedOrg={selectedOrg}
                                    permissions={permissions}

                                    sessionId={sessionId}
                                    sessionDetails={details}
                                />
                            </TabPane>
                        )
                    }
                </Tabs>
            </div>
        </div>
    )
}

const SessionSummary = ({
    selectedOrg = {},
    permissions = {},

    sessionId,
    sessionDetails = {},
}) => {
    const [loading, setLoading] = useState(true);
    // Details of the summary
    const [details, setDetails] = useState({});

    const getDetails = async () => {
        if (!sessionId) return;
        setLoading(true);
        await axios.get('/encoding/session_epc_count', {
            params: {
                o_id: selectedOrg.orgId,
                session_id: sessionId,
            }
        })
            .then(res => {
                const data = res.data.data;

                setDetails(prev => ({
                    ...prev,
                    ...data,
                }));
            })
            .catch(err => {
                console.log(err);
                message.error('Error fetching summary data');
            });
        setLoading(false);

    }

    useEffect(() => {
        getDetails();
    }, [sessionId]);

    return (
        <div>
            <div className="my-form-multiple-inline-input" style={{ gap: '25px', marginBottom: '15px' }}>
                <Statistic loading={loading} title="Successfully Encoded" value={details.successfully_encoded} />
                <Statistic loading={loading} title="Error" value={details.error} />
                <Statistic loading={loading} title="Pending Approval" value={details.pending_approval} />
                <Statistic loading={loading} title="Pending" value={details.pending} />
                <Statistic loading={loading} title="Average Time" value={details.avg} precision={2} suffix="sec" />
            </div>
            <Divider style={{ padding: '30px 0px' }} >
                <span style={{ fontSize: '16px' }}>Hour Wise Summary</span>
            </Divider>
            <HourWiseBarChart
                selectedOrg={selectedOrg}
                permissions={permissions}

                sessionId={sessionId}
                sessionDetails={sessionDetails}
            />
        </div>
    )
}

const HourWiseBarChart = ({
    selectedOrg = {},
    permissions = {},

    sessionId,
    sessionDetails = {},
}) => {
    const [loading, setLoading] = useState(true);
    // Details of the summary
    const [data, setData] = useState([]);

    const getDetails = async () => {
        if (!sessionId) return;
        setLoading(true);
        await axios.get('/encoding/hourwise_count', {
            params: {
                o_id: selectedOrg.orgId,
                session_id: sessionId,
            }
        })
            .then(res => {
                const data = res.data.data;

                const sortedData = data.sort((a, b) => moment(a.hour).isBefore(b.hour) ? -1 : 1);
                setData(sortedData);
            })
            .catch(err => {
                console.log(err);
                message.error('Error fetching bar chart data');
            });
        setLoading(false);

    }

    useEffect(() => {
        getDetails();
    }, [sessionId]);

    return (
        <div>

            <Column
                data={data}
                xField="hour"
                yField="successfully_encoded"
                xAxis={{
                    label: {
                        formatter: (value) => {
                            return moment(value).format('hh a, DD MMM')
                        }
                    }
                }}
                tooltip={{
                    title: (value) => {
                        return moment(value).format('hh a, DD MMM')
                    }
                }}
                label={{
                    position: 'top',
                    animate: true
                }}

                loading={loading}
                style={{ width: '100%', height: '350px' }}

            />
        </div>
    )
}

const TagsTable = ({
    selectedOrg = {},
    permissions = {},

    sessionId,
    sessionDetails = {},
}) => {
    const [masterState, setMasterState] = useState([]);

    // The below state is after the search is done
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const filterableColumns = useMemo(() => ["status"], []);
    const [filterOptions, setFilterOptions] = useFilterOptions(masterState, filterableColumns);

    const getData = async () => {
        setLoading(true);
        await axios.get('/encoding/session_epc_list', {
            params: {
                session_id: sessionId,
                o_id: selectedOrg?.orgId,
            }
        })
            .then(res => {
                const data = res.data.data;

                setMasterState(data);
                setData(data);
            })
            .catch(err => {
                setMasterState([]);
                setData([]);
                console.log(err);
                message.error('Error fetching Raw Epc\'s data');
            })
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, [sessionId]);

    const handleApprove = async (record) => {
        setLoading(true);
        await axios.post('/encoding/approve', {
            o_id: selectedOrg.orgId,
            session_id: sessionId,
            encode_id: record.encode_id,
        })
            .then(res => {
                message.success('Successfully approved');
                getData();
            })
            .catch(err => {
                console.log(err);
                message.error('Error approving');
            })
        setLoading(false);
    }

    const columns = [
        {
            title: 'Encode Id',
            dataIndex: 'encode_id',
            key: 'encode_id',
            sorter: (a, b) => a.encode_id - b.encode_id,
            width: 80,
        },
        {
            title: 'Previous Product Code',
            dataIndex: 'pre_product_code',
            key: 'pre_product_code',
        },
        {
            title: 'Previous EPC',
            dataIndex: 'prev_epc',
            key: 'prev_epc',
        },
        {
            title: 'Encoded EPC',
            dataIndex: 'epc',
            key: 'epc',
        },
        {
            title: 'Encoded Product Code',
            dataIndex: 'product_code',
            key: 'product_code',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            filters: filterOptions.status,
            render: (text, record) => (
                <Tag
                    color={statusColorMap[text] || "#E04B4B"}
                    key={record.status}
                    style={{ fontWeight: '700' }}
                >
                    {text?.toUpperCase()}
                </Tag>
            ),
            onFilter: (value, record) => record.status == value,
            filterSearch: true,
            width: '80px',
        },
        {
            title: 'Encoded Time',
            dataIndex: 'created_time',
            key: 'created_time',
            sorter: (a, b) => moment(a.created_time) - moment(b.created_time),
            render: (text, record) => moment(text).format('Do MMM YYYY, HH:mm A'),
        },
        {
            title: 'Action',
            render: (text, record) => (
                <div className='actions-outer-div'>
                    {
                        !record.is_approved && record.status === 'encoding_inprogress' &&
                        <Button className='actions-button' type="primary" onClick={() => handleApprove(record)}>
                            Approve
                        </Button>
                    }
                </div>
            ),
            width: '80px',
        },
    ];

    return (
        <div className='my-form-outer'>
            <div className='my-form-header'>
                <div>
                    {/* <ExportToExcel
                        data={masterState}
                        columns={columns}
                        fileName={`Session ${sessionId} - Raw EPCs`}
                    /> */}
                    <span className='my-form-title' style={{ textDecoration: 'none', fontSize: '16px' }}>
                        Encoded Tags
                    </span>
                </div>
                <div style={{display:'flex', alignItems:'center', gap:20}}>
                    <SearchComponent
                        masterState={masterState}
                        state={data}
                        setState={setData}
                        searchOptions={[
                            { keyName: 'encode_id', label: 'Encode Id' },
                            { keyName: 'status', label: 'Status' },
                            { keyName: 'pre_product_code', label: 'Previous Product Code' },
                            { keyName: 'prev_epc', label: 'Previous EPC' },
                            { keyName: 'epc', label: 'Encoded EPC' },
                            { keyName: 'product_code', label: 'Encoded Product Code' },
                        ]}
                        searchDebounceTime={500}
                    />
                    <ExportToExcelButton
                        fileName={`Encoded Tags List ${moment(Date()).format('Do MMM YYYY, HH:mm A')}`}
                        sheets={[{ sheetName: "Encoded Tag List", data: data, columns: columns }]}
                    />
                </div>
            </div>
            <Table
                columns={columns}
                dataSource={data}
                loading={loading}
                size="small"
                pagination={{
                    position: ['bottomRight'],
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
            />
        </div>
    )


}

const PlannedTagsTab = ({
    selectedOrg = {},
    permissions = {},

    sessionId,
    sessionDetails = {},
}) => {
    const [masterState, setMasterState] = useState([]);

    // The below state is after the search is done
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getData = async () => {
        setLoading(true);
        await axios.get('/dashboard/encoding/planned_session_details', {
            params: {
                session_id: sessionId,
                o_id: selectedOrg?.orgId,
            }
        })
            .then(res => {
                const data = res.data.data;

                setMasterState(data);
                setData(data);
            })
            .catch(err => {
                setMasterState([]);
                setData([]);
                console.log(err);
                message.error('Error fetching Planned Epc\'s data');
            })
        setLoading(false);
    }

    const columnsFor = [
        {
            title: 'Division',
            dataIndex: 'Division',
            key: 'Division',
        },
        {
            title: 'Category5',
            dataIndex: 'Category5',
            key: 'Category5',
        },
        {
            title: 'Department',
            dataIndex: 'Department',
            key: 'Department',
        },
        {
            title: 'Category1',
            dataIndex: 'Category1',
            key: 'Category1',
        },
        {
            title: 'Category4',
            dataIndex: 'Category4',
            key: 'Category4',
        },
        {
            title: 'Category3',
            dataIndex: 'Category3',
            key: 'Category3',
        },
        {
            title: 'Desc5',
            dataIndex: 'Desc5',
            key: 'Desc5',
        },
        {
            title: 'Desc3',
            dataIndex: 'Desc3',
            key: 'Desc3',
        },
        {
            title: 'RSP',
            dataIndex: 'RSP',
            key: 'RSP',
        },
        {
            title: 'Barcode',
            dataIndex: 'Barcode',
            key: 'Barcode',
        },
        {
            title: 'EPC',
            dataIndex: 'EPC',
            key: 'EPC',
        },

    ];

    const downlaodData = async (data) => {
        var sheets = [{ sheetName: "EPC List", data: data, columns: columnsFor }]
        var fileName = `EPC List ${moment(Date()).format('Do MMM YYYY, HH:mm A')}`
        exportToExcel({ fileName, sheets })
    }

    useEffect(() => {
        getData();
    }, [sessionId]);

    const columns = [
        {
            title: 'Product Code',
            dataIndex: 'product_code',
            key: 'zone_name',
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            key: 'qty',
        },
        {
            title: 'Encoded Quantity',
            dataIndex: 'encoded_qty',
            key: 'encoded_qty',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        }
    ];

    const generateEPC = async () => {
        setLoading(true);
        await axios.get(`/admin/admin-api/generated_epcs?encode_session_id=${sessionId}`)
            .then(res => {
                message.success('EPC Generate Successfully');
                var sheets = [{ sheetName: "EPC List", data: res.data?.data, columns: columnsFor }]
                var fileName = `EPC List ${moment(Date()).format('Do MMM YYYY, HH:mm A')}`
                exportToExcel({ fileName, sheets })
                getData();
            })
            .catch(err => {
                console.log(err);
                message.error('Error to generate EPCs');
            })
        setLoading(false);
    }

    return (
        <div className='my-form-outer'>
            <div className='my-form-header'>
                <span className="actions-outer-div">
                    <Button onClick={() => { getData() }} loading={loading} disabled={loading} >Refresh</Button>
                    {/* using our own ExportToExcel with required column and passing empty data .... Which will download the template file */}
                    {permissions?.upload_file && <div style={{ display: 'flex', justifyItems: 'center' }}>
                        <ExportToExcelButton
                            fileName={`Template - Planned Tags`}
                            buttonName={'Download Template'}
                            sheets={[
                                {
                                    data: [],
                                    columns: [
                                        { title: 'Division', dataIndex: 'Division', key: 'Division' },
                                        { title: 'Category5', dataIndex: 'Category5', key: 'Category5' },
                                        { title: 'Department', dataIndex: 'Department', key: 'Department' },
                                        { title: 'Category1', dataIndex: 'Category1', key: 'Category1' },
                                        { title: 'Category4', dataIndex: 'Category4', key: 'Category4' },
                                        { title: 'Category3', dataIndex: 'Category3', key: 'Category3' },
                                        { title: 'Desc5', dataIndex: 'Desc5', key: 'Desc5' },
                                        { title: 'Desc3', dataIndex: 'Desc3', key: 'Desc3' },
                                        { title: 'Barcode', dataIndex: 'Barcode', key: 'Barcode' },
                                        { title: 'RSP', dataIndex: 'RSP', key: 'RSP' },
                                        { title: 'QTY', dataIndex: 'QTY', key: 'QTY' },
                                    ]
                                }
                            ]}
                        />
                        <UploadExcelFile
                            url={`/dashboard/upload_file_for_epc_generation?session_id=${sessionId}`}
                            style={{ marginLeft: '20px' }}
                            onUploadSuccess={() => { getData() }}
                        />
                        <Button disabled={data.length > 0 ? false : true} style={{ marginLeft: '20px' }} loading={loading} onClick={() => generateEPC()}>
                            Generate EPC
                        </Button>
                    </div>}
                </span>
                <SearchComponent
                    masterState={masterState}
                    state={data}
                    setState={setData}
                    searchOptions={[
                        { keyName: 'product_code', label: 'Product Code' },
                        { keyName: 'status', label: 'Status' },
                    ]}
                    searchDebounceTime={300}
                />
            </div>
            <Table
                columns={columns}
                dataSource={data}
                loading={loading}
                size="small"
                pagination={{
                    position: ['bottomRight'],
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
                rowClassName={(record, index) => {
                    if (record.status === 'DONE') {
                        return 'encode-status-done';
                    }
                    else if (record.status === 'PENDING') {
                        return 'encode-status-pending';
                    }
                }}
            />
            <Helmet>
                <style>{`
                    .encode-status-done {
                        background-color: #73ee62;
                    }
                    .encode-status-pending {
                        background-color: #ffa39e;
                    }
                `}</style>
            </Helmet>
        </div>
    )
}

const UploadExcelFile = ({
    url,
    style = {},
    onUploadSuccess = () => { },
}) => {
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);

    const uploadFile = async () => {
        const formData = new FormData();
        formData.append('file', fileList[0].originFileObj);

        setUploading(true);
        await axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(res => {
                message.success('File uploaded successfully');
                setFileList([]);
                onUploadSuccess();
            })
            .catch(err => {
                console.log(err);
                try {
                    message.error(err.response.data.detail);
                    setFileList([]);
                } catch (error) {
                    message.error('Error uploading file');
                }
            })
        setUploading(false);
    }

    const props = {
        onRemove: file => {
            setFileList(null);
        },
        beforeUpload: file => {
            setFileList([file]);
            return false;
        },
        fileList,
    };

    return (
        <span style={{ display: 'inline-flex', ...style }}>
            <Upload
                name="xlsx_file"
                accept=".xlsx"
                // maxCount={1}
                fileList={fileList}
                onPreview={(file) => {
                    // console.log("fileUrl", file.url);
                }}
                onChange={({ fileList }) => {
                    setFileList(fileList);
                    console.log("fileList", fileList);
                }}
                beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                style={{ display: 'flex' }}
            // showUploadList={false}
            >
                <Button disabled={fileList.length > 0 || uploading} loading={uploading}
                    icon={<HiOutlineUpload />}
                    // To take width 100% of parent element
                    style={{ borderRadius: '4px 0px 0px 4px', width: '100%', display: 'inline-flex', alignItems: 'center' }}
                >
                    {fileList.length > 0 ? fileList[0].name : 'Add File'}
                </Button>
            </Upload>
            <Button
                onClick={() => { uploadFile() }}
                loading={uploading}
                disabled={fileList.length === 0 || uploading}
                style={{ borderRadius: '0px 4px 4px 0px' }}
                type="primary"
            >
                Upload
            </Button>
        </span>
    )
}
