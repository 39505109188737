import { Modal, Tooltip } from 'antd'
import React from 'react'
import { IoInformationCircleSharp } from 'react-icons/io5'

export const InformationPopup = ({
    children,
    iconStyle={},
}) => {

    return (
        <Tooltip title={children}>
            <IoInformationCircleSharp
                style={{
                    marginLeft: "5px",
                    color: 'black',
                    ...iconStyle,
                }}
            />
        </Tooltip>
    )
}

export const InformationModal = ({
    title="Information",
    children,
}) => {
    const [visible, setVisible] = React.useState(false);

    return (
        <>
            <IoInformationCircleSharp onClick={() => setVisible(true)} />
            <Modal
                title={title}
                open={visible}
                onCancel={() => setVisible(false)}
                footer={null}
            >
                {children}
            </Modal>
        </>
    )
}


export const InvalidEPCs_Info = "These are the epcs that are scanned during stock take session which are not a part of our organisation. This is a rare case though and these tags need to be identified and rectified on priority.";
export const NotInStock_Info = "These are the epcs which belong to our organisation but are not present in the stock / inventory data of your ERP System.";
export const ERPvsStockTake_Info = "It is the comparison stock / inventory data of your ERP System with the RFID Scan data scanned in that session.";
export const RawEPCs_Info = "It is the report of all RFID tags that are scanned in that session with respect to the zone where the RFID tag is present. If you find this data to be in accurate please ensure you select the correct zone while scanning from the handhel readers";
export const ZoneWiseData_Info = "It is the report of all the RFID tags which are scanned during a session grouped by the product_code along with the zones they are present in and complete product detail against the product code.";
export const ERPvsStockTake_PieChart = "This pie chart percentage of product codes with their stock status comparison for the session.";