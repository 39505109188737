import { Image, Modal, Spin, Tooltip, message } from "antd"
import Barcode from "react-barcode"
import QRCode from "react-qr-code"
import { capitalizeFirstLetter, copyToClipboard } from "./Functions"
import { useEffect, useState } from "react"
import axios from "axios"
import { MdContentCopy } from "react-icons/md"

export const ProductCodeComponent = ({
    productCode,
    showCopyIcon=true,
}) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [details, setDetails] = useState({});
    const [columns, setColumns] = useState([]);

    const getData = async () => {
        setLoading(true)
        await axios.get(`/admin-api/reports/stock_take/product_code_details`, {
            params: {
                product_code: productCode,
            }
        })
            .then(res => {
                const data = res.data.data;
                const categoryColumns = res.data.categories;

                setDetails(prev => ({
                    ...prev,
                    ...data,
                    productCode: productCode,
                }));
                setColumns(categoryColumns);
            })
            .catch(err => {
                console.log(err);
                message.error("Error getting product code details");
            });
            setLoading(false);
    };

    useEffect(() => {
        // console.log(open, productCode, details?.productCode);
        if (open && productCode && !details?.productCode) {
            getData();
        }
    }, [open]);

    return (
        <>
        <Tooltip title={"Click to open barcode"} placement='topLeft'>
            {showCopyIcon &&
                <MdContentCopy
                    title='copy'
                    onClick={() => { copyToClipboard(productCode); message.success(`Copied ${productCode} to clipboard`) }}
                    style={{ cursor: 'pointer' }}
                />
            }
            <span style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => { setOpen(true) }}>
                {productCode}
            </span>
        </Tooltip>
        <Modal
            title={`Product Code: ${productCode}`}
            open={open}
            onCancel={() => { setOpen(false) }}
            onClose={() => { setOpen(false) }}
            footer={null}
            width={'70%'}
            destroyOnClose={true}
        // bodyStyle={{background: 'black'}}
        >
            <Spin spinning={loading}>
                <div style={{display: 'inline-flex', width: '100%', flexDirection: 'row',}}>
                    <Image
                        src={details?.image_url?.image_url?.[0] || "/image-not-found.png"}
                        key={details?.image_url?.image_url?.[0]}
                        alt=""
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/image-not-found.png';
                        }}
                        wrapperStyle={{ width: "30%", objectFit: 'cover' }}
                    />


                    <div style={{ textAlign: 'center', width: '60%', textAlign: 'left', paddingLeft: '20px' }}>
                        <div style={{ display: 'inline-flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            {productCode && <Barcode value={productCode || ""} width={3} height={150} displayValue={false} />}
                            {productCode && <QRCode value={productCode || ""} size={150} style={{ marginLeft: '40px' }} />}
                        </div>
                        {
                            columns.map(c => {
                                return (
                                    <h3><span style={{ display: 'inline-block', width: '200px', textAlign: 'left' }}>{capitalizeFirstLetter(c?.category_name)}</span>: <span style={{ display: 'inline-block', width: '200px', textAlign: 'left' }}>{details?.[c?.category_name]}</span></h3>
                                )
                            })
                        }
                    </div>
                </div>
            </Spin>
        </Modal>
        </>
    )
}

