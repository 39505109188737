import { Checkbox, Image, message, Table, Tooltip } from 'antd';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react'
import { MdContentCopy } from 'react-icons/md';
import { InputWithLabel } from '../../Components/Components';
import { ExportToExcelButton } from '../../Components/ExportToExcel';
import useFilterOptions from '../../Components/useFilterOptions';
import SearchComponent from '../../Masters/GeneralComponents/SearchComponent';
import { capitalizeFirstLetter, copyToClipboard } from '../Components/Functions';
import { ProductCodeComponent } from '../Components/ProductCodeDetailsModal';

export default function ProductMasterTable({
    selectedOrg={},
    permissions={},
}) {
    const [masterState, setMasterState] = useState([]);

    // Below satate will hold the data filtered after `Our Search Component (SearchComponent)`
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Below state is for the current data shown after filtering and sorting (Exported from Antd Table)
    // (for excel export)
    const [currentTableData, setCurrentTableData] = useState([]);

    const [categoryColumns, setCategoryColumns] = useState([]);
    const filterableColumns = useMemo(() =>
        [...categoryColumns.map(c => c.category_name)]
    ,[categoryColumns]);
    const [filterOptions, setFilterOptions] = useFilterOptions(masterState, filterableColumns);
    const [settings, setSettings] = useState({
        showImages: true,
    });

    const fixedColumns = [
        {
            title: 'Product Code',
            dataIndex: 'product_code',
            key: 'product_code',
            render: (text, record) => <ProductCodeComponent productCode={text} />,
            width: 140,
        },
    ];

    const getData = async () => {
        setLoading(true);
        await axios.get('/dashboard/encoding/product_details', {
            params: {
                o_id: selectedOrg.orgId,
            }
        })
            .then(res => {
                const data = res.data.data;
                const newColumns = res.data.columns;

                setData(data);
                setMasterState(data);
                setCategoryColumns(newColumns);
                setCurrentTableData(data);        // Initialising the current table data to the data we got from the API
            })
            .catch(err => {
                // setFilterOptions({});
                setData([]);
                setCategoryColumns([]);
                setCurrentTableData([]);

                console.log(err);
                message.error('Error fetching ERP Vs Stock Take data');
            })
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, [selectedOrg]);

    const columns = useMemo(() => [
        ...fixedColumns,
        ...categoryColumns.map(c => {
            return {
                // title: c.category_name,
                title: capitalizeFirstLetter(c.category_name),
                dataIndex: c.category_name,
                key: c.category_name,
                filters: filterOptions[c.category_name],
                onFilter: (value, record) => record[c.category_name] == value,
                filterSearch: true,
            }
        }),
        {
            title: 'Image Url',
            dataIndex: ['image_url', 'image_url', 0],
            key: 'image_url?.image_url?.[0]',
            render: (text, record) => {
                return (
                    <Image
                        src={text || "/image-not-found.png"}
                        alt=""
                        width='100px'
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/image-not-found.png';
                        }}
                    />
                )
            },
            // Aligning the image to the center
            align: 'center',
            hidden: !settings.showImages,
        }
    ].filter(c => c.hidden !== true), [categoryColumns, filterOptions, settings.showImages]);


    const setSettingsKey = (key, value) => {
        setSettings(prev => ({ ...prev, [key]: value }));
    }

    return (
        <div className='my-form-outer'>
            <div className='my-form-header'>
                <span className='my-form-title'>Product Master Data</span>
                <span className="actions-outer-div">
                    <SearchComponent
                        masterState={masterState}
                        state={data}
                        setState={setData}
                        searchOptions={[
                            {keyName: 'product_code', label: 'Product Code'},
                            {keyName: 'final_status', label: 'Final Status'},
                            // Inventory Columns
                            ...categoryColumns.map(c => {
                                return {
                                    keyName: c.category_name,
                                    label: capitalizeFirstLetter(c.category_name),
                                }
                            }),
                        ]}
                        defaultSearchKeys={['product_code']}
                        searchDebounceTime={500}
                    />
                    <ExportToExcelButton
                        fileName={`Product Master - ${selectedOrg?.orgName}`}
                        sheets={[
                            {
                                data: currentTableData,
                                columns: columns,
                            }
                        ]}
                    />
                    <InputWithLabel label='Show Images' isInline={true}>
                        <Checkbox
                            checked={settings.showImages}
                            onChange={(e) => setSettingsKey('showImages', e.target.checked)}
                        />
                    </InputWithLabel>
                </span>
            </div>
            <Table
                dataSource={data}
                columns={columns}
                loading={loading}
                size="small"
                pagination={{
                    position: ['bottomRight'],
                    // position: ['topRight'],
                    showSizeChanger: true,
                    defaultPageSize: 20,
                }}
                onChange={(pagination, filters, sorter, extra) => {
                    // console.log('params', pagination, filters, sorter, extra);
                    setCurrentTableData(extra.currentDataSource);
                }}
            />

        </div>


    )
}
