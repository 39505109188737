import { Table, message, Button, Progress, Select } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import DateRange from '../Reports/Components/DateRange';
import SiteTreeMultiSelect from '../RFIDReports/Components/SiteTreeMultiSelect';
import axios from 'axios';
import moment from 'moment';
import { exportToExcel } from '../Components/ExportToExcel';
import { InputWithLabel } from '../Components/Components';

// {
//     "data": [
//       {
//         "date": "2023-01-16",
//         "site_id": 1,
//         "stock_take_count": 311,
//         "erp_stock_count": 18397,
//         "stock_take_percentage": "1.6904929652810097%"
//       },
//       {
//         "date": "2022-12-28",
//         "site_id": 1,
//         "stock_take_count": 12,
//         "erp_stock_count": 39889,
//         "stock_take_percentage": "0.030083482852205634%"
//       }
//     ]
//   }

export default function DateWiseStockTakeReport({
    selectedOrg = {},
    permissions = {},
}) {
    const [selected, setSelected] = useState({
        dateRange: [moment().subtract(7, 'days'), moment()],
        sites: [],     // Array of panel ids
    });

    const [masterState, setMasterState] = useState([]);
    const [reportType, setReportType] = useState('percentage');

    // The below state is after the search is done
    const [filteredState, setFilteredState] = useState([]);
    const [loading, setLoading] = useState(true);

    // Below are the columns that are made dynamically for the date range
    const [dateColumns, setDateColumns] = useState([]);

    const getData = async () => {
        setLoading(true);
        // var res = {data: require('./data.json')};
        await axios.post('/admin-api/reports/stock_take/stock_take_comparison_report', {
            o_id: selectedOrg.orgId,
            from_date: selected.dateRange[0].startOf('day').format(),
            to_date: selected.dateRange[1].endOf('day').format(),
            site_id: selected.sites,
        })
            .then(res => {
                var d = res.data.data;
                var sites = res.data.site_details;
                var { data, columns } = convertData(d, sites);

                setMasterState(data);
                setFilteredState(data);
                setDateColumns(columns);
            })
            .catch(err => {
                console.log(err);
                message.error('No data available for the selected date range');
                setMasterState([]);
                setFilteredState([]);
            })
        setLoading(false);
    }

    function interpolate(percent) {
        const colorMap = new Map([
            [0, "#f2f2f2"], //grey
            [35, "#FF8080"], //red
            [95, "#FFD966"], //orange
            [100, "#B0D9B1"], //green
        ]);
    
        for (const [key, value] of colorMap) {
            if (percent <= key) {
                return value;
            }
        }
    
        return "#8EACCD"; // if percentage is greater than 100 then blue
    }

    /*
    We receive data in this format:
    [
        {
            "date": "2023-01-16",
            "site_id": 1,
            "site_name": "Site 1",
            "stock_take_count": 311,
            "erp_stock_count": 18397,
        },
        {
            "date": "2022-12-28",
            "site_id": 1,
            "site_name": "Site 1",
            "stock_take_count": 0,
            "erp_stock_count": 18397,
        },
        {
            "date": "2022-12-28",
            "site_id": 2,
            "site_name": "Site 2",
            "stock_take_count": 12,
            "erp_stock_count": 39889,
        }
    ]

    // We need to convert it to
    [
        {
            site_id: 1,
            site_name: "Site 1",
            "2023-01-16": {
                stock_take_count: 311,
                erp_stock_count: 18397,
            },
            "2022-12-28": {
                stock_take_count: 0,
                erp_stock_count: 18397,
            }
        }
    ]
    */
    const convertData = (data, sites) => {
        // First we need to get the dates
        var dates = {};
        var siteData = {};
        for (var site of sites) {
            siteData[site.site_id] = {
                site_id: site.site_id,
                site_name: site.site_name,
            }
        }

        data.forEach(d => {

            dates[d.date] = {
                title: d.date,
                dataIndex: d.date,
                key: d.date,
                render: (thisDate, record) => (
                    <>
                        {reportType == "percentage" ?
                            <Progress
                                // type="circle"
                                // Calculate the percentage
                                percent={thisDate?.percentage || 0}
                                // format={percent => percent && `${percent}%`}
                                strokeColor={thisDate?.percentage ? thisDate?.percentage > 75 ? '#87d068' : '#108ee9' : '#f50'}
                                width={55}
                                style={{ cursor: 'pointer', padding: "0 20px" }}
                                status={thisDate ? "active" : "exception"}
                            /> :
                            <p style={{
                                backgroundColor: interpolate(thisDate?.percentage || 0 / 100),
                                // color: thisDate?.percentage ? "#fff" : "#000",
                                fontWeight: 'bold',
                            }}>
                                {thisDate?.stock_take_count ? thisDate?.stock_take_count : '0'}/{thisDate?.erp_stock_count ? thisDate?.erp_stock_count : '0'}
                            </p>
                        }
                    </>
                ),
                align: 'center',
            }

            if (siteData[d.site_id]) {
                siteData[d.site_id][d.date] = {
                    stock_take_count: d.stock_take_count,
                    erp_stock_count: d.erp_stock_count,
                    date: d.date,
                    percentage: d.stock_take_percentage,
                }
            }
        })

        return {
            data: Object.values(siteData),
            columns: Object.values(dates),
        }
    }


    useEffect(() => {
        // This useEffect dosent have the selectedOrg as a dependency because if
        // new org is selected, then new sites will be loded ... and that will trigger getData
        // If we add that dependency, then it will trigger getData twice and the first time with previous org's sites
        if (selected.sites.length === 0) {
            setMasterState([]);
            setLoading(false);
            return;
        }
        getData();
    }, [selected.sites, selected.dateRange, reportType])
    // }, [selectedOrg.orgId, selected.sites, selected.dateRange])

    // This is just for UI loading
    useEffect(() => {
        if (selectedOrg.orgId) {
            setLoading(true);
        }
    }, [selectedOrg.orgId]);


    const columns = useMemo(() => [
        {
            title: 'Site',
            dataIndex: 'site_name',
            key: 'site_name',
        },
        ...dateColumns,
    ], [dateColumns]);

    const setSelectedKey = (key, value) => {
        setSelected(prev => ({
            ...prev,
            [key]: value,
        }));
    }

    const handleChange = () => {

        exportToExcel({
            fileName: `Stock Take Sessions ${selected.dateRange[0].format("DD")} to ${selected.dateRange[1].format("DD MMM")}`,
            sheets: [
                {
                    sheetName: "Stock Sessions",
                    data: filteredState.map(item => {
                        const data = {
                            site_id: item.site_id,
                            site_name: item.site_name,
                        };

                        // Loop through the date columns and add them to the export data
                        dateColumns.forEach(column => {
                            const columnData = item[column.key];
                            if (columnData) {
                                data[column.key] = (reportType === "percentage") ? (columnData.percentage + '%' || 'No Data' )
                                : (columnData?.stock_take_count ? columnData?.stock_take_count : '0') + '/' + (columnData?.erp_stock_count ? columnData?.erp_stock_count : '0');
                            }
                            else {
                                data[column.key] = 'No Data';
                            }
                        });

                        return data;
                    }),
                    columns: [
                        {
                            title: 'Site Name',
                            dataIndex: 'site_name',
                        },
                        ...dateColumns.map(column => ({
                            title: column.title,
                            dataIndex: column.key,
                        })),
                    ],
                },
            ],
        });
    }


    return (
        <div className='my-form-outer'>
            <div className="my-table-filter-row" style={{ alignItems: 'flex-end' }}>
                <span className='my-form-title'>Global Scan Report</span>
                <div className="my-form-multiple-inline-input" style={{ width: 'max-content' }}>
                    {/* Read documentation inside DateRange for set methods */}
                    <InputWithLabel label={"Select Report Type"}>
                        <Select
                            style={{ width: '200px' }}
                            defaultValue={'percentage'}
                            onChange={(value) => setReportType(value)}
                            options={
                                [
                                    {
                                        label: 'Percentage Wise',
                                        value: 'percentage',
                                    },
                                    {
                                        label: 'Count Wise',
                                        value: 'count'
                                    }
                                ]
                            }
                        />
                    </InputWithLabel>
                    <DateRange
                        selectedOrg={selectedOrg}
                        value={selected.dateRange}
                        setValue={(value) => { setSelectedKey('dateRange', value) }}
                    />
                    <SiteTreeMultiSelect
                        selectedOrg={selectedOrg}
                        value={selected.sites}
                        setValue={(value) => { setSelectedKey('sites', value) }}
                    // style={{width: '450px'}}
                    />
                    <InputWithLabel label={<>&nbsp;</>}>
                        <Button
                            type="primary"
                            onClick={handleChange}
                        >Export to Excel
                        </Button>
                    </InputWithLabel>
                </div>
            </div>
            <Table
                columns={columns}
                dataSource={filteredState}
                loading={loading}
                size='small'
                key={selectedOrg.orgId}
                pagination={false}
                bordered
            />
        </div>
    )
}
