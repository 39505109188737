import React, { useEffect, useMemo, useState } from 'react'
import useFilterOptions from '../../Components/useFilterOptions';
import axios from 'axios';
import { Image, Popover, Table, Tabs, Tag, Tooltip, message } from 'antd';
import SearchComponent from '../../Masters/GeneralComponents/SearchComponent';
import { ExportToExcelButton } from '../../Components/ExportToExcel';
import { ProductCodeComponent } from '../Components/ProductCodeDetailsModal';
const { TabPane } = Tabs;


const colorMap = {
    "Shop Display": "green",
    "Shop Broken": "red",
    "Back Display": "green",
    "Back Broken": "blue",
    "broken": "red",
    "display": "green",
}

export default function ReplenishmentReportV2({
    selectedOrg={},
    sessionId
}) {
    const [masterShop, setMasterShop] = useState([]);
    const [masterBack, setMasterBack] = useState([]);

    const [loading, setLoading] = useState(true);

    const getData = async () => {
        setLoading(true);
        await axios.get(`/admin-api/reports/stock_take/replenishment-advice-report`, {
            params: {
                session_id: sessionId,
                o_id: selectedOrg?.orgId,
            }
        })
            .then(res => {
                const data = res.data.data;
                const shop = res.data.shop;
                const back = res.data.back;

                setMasterShop(shop);
                setMasterBack(back);
            })
            .catch(err => {
                // setFilterOptions({});
                setMasterShop([]);
                setMasterBack([]);
                console.log(err);
                message.error(err?.response?.data?.detail || 'Error Finding Replenishment data!');
            })
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, [sessionId]);


    return (
        <div>
            {/* <Tabs
                tabPosition='left'
                size='small'
                style={{padding: "0px"}}
            > */}
                {/* <TabPane tab="Shop" key="1">
                    <ShopTab
                        masterState={masterShop}
                        setMasterState={setMasterShop}
                        loading={loading}
                    />
                </TabPane> */}
                {/* <TabPane tab="Back" key="2"> */}
                    <BackTab
                        masterState={masterBack}
                        setMasterState={setMasterBack}
                        loading={loading}
                    />
                {/* </TabPane>
            </Tabs> */}
        </div>
    )
}

const ShopTab = ({
    masterState,
    setMasterState,
    loading,
}) => {
    const [data, setData] = useState([]);

    // (for excel export)
    const [currentTableData, setCurrentTableData] = useState([]);
    useEffect(() => { setCurrentTableData(data) }, [masterState]);

    const filterableColumns = useMemo(() =>
        // TODO: Change this
        ['section', 'department', 'option', 'color', 'size', 'status']
    ,[]);
    const [filterOptions, setFilterOptions] = useFilterOptions(masterState, filterableColumns);

    const columns = useMemo(() => [
        {
            title: "Section",
            dataIndex: "section",
            key: "section",
            filters: filterOptions.section,
            onFilter: (value, record) => record.section === value,
            filterSearch: true,
        },
        {
            title: "Department",
            dataIndex: "department",
            key: "department",
            filters: filterOptions.department,
            onFilter: (value, record) => record.department === value,
            filterSearch: true,
        },
        {
            title: "Option",
            dataIndex: "option",
            key: "option",
            filters: filterOptions.option,
            onFilter: (value, record) => record.option === value,
            filterSearch: true,
        },
        {
            title: "Color",
            dataIndex: "color",
            key: "color",
            filters: filterOptions.color,
            onFilter: (value, record) => record.color === value,
            filterSearch: true,
        },
        {
            title: "Product Code",
            dataIndex: "product_code",
            key: "product_code",
            render: (text, record) => <ProductCodeComponent productCode={text} />,
        },
        {
            title: "Size",
            dataIndex: "size",
            key: "size",
            filters: filterOptions.size,
            onFilter: (value, record) => record.size === value,
            filterSearch: true,
        },
        {
            title: "Size Curve",
            dataIndex: "size_curve",
            key: "size_curve",
        },
        {
            title: "Found Sizes",
            dataIndex: "found_sizes",
            key: "found_sizes",
        },
        {
            title: "Qty",
            dataIndex: "qty",
            key: "qty",
        },
        {
            title: "Ratio",
            dataIndex: "ratio",
            key: "ratio",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            filters: filterOptions.status,
            onFilter: (value, record) => record.status === value,
            filterSearch: true,
            render: (text, record) => {


                return (
                    <Tag color={colorMap[text]} key={text}>
                        {text?.toUpperCase()}
                    </Tag>
                )
            }
        },
    ], [filterOptions]);

    return (
        <div className='my-form-outer'>
            <div className='my-form-header'>
                {/* <ExportToExcelButton
                    buttonLoading={loading}
                    buttonDisabled={data.length === 0 || loading}
                    fileName={`Replenishment-Report-${sessionId}`}
                    sheets={[
                        {sheetName: 'Shop Replenishment Report', data: currentTableData, columns: columns},
                    ]}
                /> */}
                <span></span>
                <SearchComponent
                    masterState={masterState}
                    state={data}
                    setState={setData}
                    searchOptions={[
                        {keyName: 'product_code', label: 'Product Code'},
                    ]}
                    searchDebounceTime={500}
                />
            </div>
            <Table
                dataSource={data}
                columns={columns}
                loading={loading}
                size="small"
                pagination={{
                    position: ['bottomRight'],
                    // position: ['topRight'],
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
                onChange={(pagination, filters, sorter, extra) => {
                    // console.log('params', pagination, filters, sorter, extra);
                    setCurrentTableData(extra.currentDataSource);
                }}
            />
        </div>
    )
}

const BackTab = ({
    masterState,
    setMasterState,
    loading,
}) => {
    const [data, setData] = useState([]);

    // (for excel export)
    const [currentTableData, setCurrentTableData] = useState([]);
    useEffect(() => { setCurrentTableData(data) }, [masterState]);

    const filterableColumns = useMemo(() =>
        // TODO: Change this
        ['section','division', 'department', 'option', 'color', 'size', 'status', 'suggestion']
    ,[]);
    const [filterOptions, setFilterOptions] = useFilterOptions(masterState, filterableColumns);

    const statusColorMap = {
        Replenishable: "#A5DD9B",
        Broken: "#E04B4B",
        'Excess Size': "#51829B",
        'Shop Display':"#F1EF99",
        Replenishable2: "#79AC78",
        Replenishable1: "#79AC78",
        Replenishable: "#79AC78",
        broken: "#E04B4B",
        backup: "#51829B",
        display: "#E9B384",
    };

    const columns = useMemo(() => [
        {
            title: "Division",
            dataIndex: "division",
            key: "division",
            filters: filterOptions.option,
            onFilter: (value, record) => record.option === value,
            filterSearch: true,
        },
        {
            title: "Option",
            dataIndex: "option",
            key: "option",
            filters: filterOptions.option,
            onFilter: (value, record) => record.option === value,
            filterSearch: true,
        },
        {
            title: "Section",
            dataIndex: "section",
            key: "section",
            filters: filterOptions.section,
            onFilter: (value, record) => record.section === value,
            filterSearch: true,
        },
        {
            title: "Department",
            dataIndex: "department",
            key: "department",
            filters: filterOptions.department,
            onFilter: (value, record) => record.department === value,
            filterSearch: true,
        },
        {
            title: "Size Curve",
            dataIndex: "size_curve",
            key: "size_curve",
        },
        {
            title: "Size found in Back",
            dataIndex: "back_sizes",
            key: "back_sizes",
            render: (text, record) => record.back_sizes?.map((item, index) => (
                <Popover
                    destroyTooltipOnHide={true}
                    content={
                        <div>
                            <div><b>Qty</b>: {item.qty}</div>
                            <div><b>Size</b>: {item.size}</div>
                            <div><b>Product Code</b>: {item.product_code}</div>
                            <div><b>Status</b>: <Tag color={statusColorMap[item.status] || "black"}>{item.status}</Tag></div>
                        </div>
                    }
                    title={record.option}
                    trigger={["hover", "click"]}

                >
                    <Tag key={index} color={statusColorMap[item.status] || "black"}>
                        {item.size}
                    </Tag>
                </Popover>
            )),
        },
        {
            title: "Size found in Shop",
            dataIndex: "shop_sizes",
            key: "shop_sizes",
            // render: (text, record) => record.shop_sizes?.map((item, index) => <Tag key={index}>{item}</Tag>)
            render: (text, record) => record.shop_sizes?.map((item, index) => (
                <Popover
                    destroyTooltipOnHide={true}
                    content={
                        <div>
                            <div><b>Qty</b>: {item.qty}</div>
                            <div><b>Size</b>: {item.size}</div>
                            <div><b>Product Code</b>: {item.product_code}</div>
                            <div><b>Status</b>: <Tag color={statusColorMap[item.status] || "black"}>{item.status}</Tag></div>
                        </div>
                    }
                    title={record.option}
                    trigger={["hover", "click"]}
                >
                    <Tag key={index} color={statusColorMap[item.status] || "black"}>
                        {item.size}
                    </Tag>
                </Popover>
            )),
        },
        Table.EXPAND_COLUMN,
        {
            title: "Move from back to Shop",
            dataIndex: "move_sizes",
            key: "move_sizes",
            render: (text, record) => record.move_sizes?.map((item, index) => (
                <Popover
                    destroyTooltipOnHide={true}
                    content={
                        <div>
                            <div><b>Qty</b>: {item.qty}</div>
                            <div><b>Size</b>: {item.size}</div>
                            <div><b>Product Code</b>: {item.product_code}</div>
                        </div>
                    }
                    title={record.option}
                    trigger={["hover", "click"]}
                >
                    <Tag key={index} color={statusColorMap[item.status] || "black"} style={{margin: '0'}}>
                        {item.size}
                    </Tag>
                </Popover>
            ))
        },
        {
            title: "Suggestion",
            dataIndex: "status",
            key: "status",
            render: (text, record) => (
                <Tag
                    color={statusColorMap[text] || "#000000"}
                    key={record.status}
                    style={{ fontWeight: '700' }}>
                    {text?.toUpperCase()}
                </Tag>
            ),
            filters: filterOptions.status,
            onFilter: (value, record) => record.status === value,
            filterSearch: true,
        }
    ], [filterOptions]);


    const excelExportData = useMemo(() => {
        return masterState.map(item => ({
            ...item,
            back_sizes: item.back_sizes?.map(x => x.size).join(', '),
            shop_sizes: item.shop_sizes?.map(x => x.size).join(', '),
            move_sizes: item.move_sizes?.map(x => x.size).join(', '),
            // TODO: Check this
            // total_qty: item.available_sizes?.reduce((acc, curr) => acc + curr.qty, 0),
            to_be_moved: item.move_sizes?.map(x => x.product_code).join(', '),
        }))
    }, [masterState]);


    return (
        <div className='my-form-outer'>
            <div className='my-form-header'>
                <div className='actions-outer-div'>

                    <ExportToExcelButton
                        buttonLoading={loading}
                        buttonDisabled={excelExportData.length === 0 || loading}
                        sheets={[
                            {
                                sheetName: 'Replenishment Report',
                                data: excelExportData,
                                columns: [
                                    { title: 'Division', dataIndex: 'division'},
                                    { title: 'Option', dataIndex: 'option' },
                                    { title: 'Section', dataIndex: 'section' },
                                    { title: 'Department', dataIndex: 'department' },
                                    { title: 'Size Curve', dataIndex: 'size_curve' },
                                    { title: 'Sizes in Back Store', dataIndex: 'back_sizes' },
                                    {title: 'Qty In Back',dataIndex: 'total_qty'},
                                    { title: 'Sizes in Shop', dataIndex: 'shop_sizes' },
                                    { title: 'Move from Back to Shop', dataIndex: 'move_sizes' },
                                    { title: 'To be moved', dataIndex: 'to_be_moved' },
                                    { title: 'Suggestion', dataIndex: 'status' },
                                    { title: 'Store Code', dataIndex: 'site_code' },   
                                ]
                            }
                        ]}
                        fileName="Replenishment Advice Report"
                    />

                    <h3>Inventory of back store which can be moved to shop to <b>fix broken options on shop floor</b></h3>
                </div>

                <SearchComponent
                    masterState={masterState}
                    state={data}
                    setState={setData}
                    searchOptions={[
                        {keyName: 'product_code', label: 'Product Code'},
                    ]}
                    searchDebounceTime={500}
                />

            </div>

            <Table
                dataSource={data}
                columns={columns}
                loading={loading}
                size="small"
                bordered
                pagination={{
                    position: ['bottomRight'],
                    // position: ['topRight'],
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
                onChange={(pagination, filters, sorter, extra) => {
                    // console.log('params', pagination, filters, sorter, extra);
                    setCurrentTableData(extra.currentDataSource);
                }}
                expandable={{
                    rowExpandable: record => (record.status === "Replenishable"),
                    expandedRowRender: record => (
                        <div style={{display: "flex"}}>
                            <Image
                                src={record?.image_url?.image_url?.[0] || "/image-not-found.png"}
                                alt=""
                                height='150px'
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = '/image-not-found.png';
                                }}
                                style={{minWidth: "80px"}}
                            />
                            <div style={{marginLeft: "10px", border: '0px solid red'}}>
                                <h2>Move the following sizes from back to shop</h2>
                                <div style={{display: "flex", flexDirection: "column", gap: "8px"}}>
                                    {record.move_sizes?.map((item, index) => (
                                        <div>
                                            <Tag key={index} color="green">
                                                <ProductCodeComponent productCode={item.product_code} />
                                            </Tag>
                                            <Tag key={index} color="yellow">
                                                {item.size}
                                            </Tag>
                                            <Tag key={index} color="red">
                                                {item.qty}
                                            </Tag>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ),
                }}
                rowKey={(record) => record.option}
            />
        </div>
    )
}
