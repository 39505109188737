import React from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Divider, Modal } from 'antd'

const finalStatusDivStyle = {
    marginTop: '10px',
}

const finalStatusSpanStyle = {
    padding: '2px 10px',
    marginRight: '5px',
    textAlign: 'center',
    borderRadius: '2px',
    color: '#fff',
    float: 'left',
    width: '120px',
}

export default function DisplayInformation({
    children,
}) {
    const [visible, setVisible] = React.useState(false);
    return (
        <>
            <Button style={{ borderRadius: '100%' }} icon={<QuestionCircleOutlined />} onClick={() => setVisible(true)} />
            <Modal
                title="Help"
                open={visible}
                onCancel={() => setVisible(false)}
                footer={null}
                bodyStyle={{ padding: '2px 20px 10px 20px' }}
            >
                {children}
            </Modal>
        </>
    )
}

export const ReplenishmentHelpContent = () => (
    <>
    <Divider orientation="left" orientationMargin={0}>
        <h3>Replenishment Terms</h3>
    </Divider>
    <p style={{paddingLeft: '20px', marginTop: '4px'}}>
        <div style={finalStatusDivStyle}>
            <span style={{ ...finalStatusSpanStyle, backgroundColor: 'green' }} >
                Shop Display
            </span>
            Items at SHOP with <b>COMPLETE</b> size sets
        </div>
        <div style={finalStatusDivStyle}>
            <span style={{ ...finalStatusSpanStyle, backgroundColor: 'red' }} >
                Shop Broken
            </span>
            Items at SHOP with <b>BROKEN</b> size sets
        </div>
        <div style={finalStatusDivStyle}>
            <span style={{ ...finalStatusSpanStyle, backgroundColor: 'green' }} >
                Back Display
            </span>
            Items at BACK with <b>COMPLETE</b> size sets
        </div>
        <div style={finalStatusDivStyle}>
            <span style={{ ...finalStatusSpanStyle, backgroundColor: 'red' }} >
                Back Broken
            </span>
            Items at BACK with <b>BROKEN</b> size sets
        </div>
    </p>
    </>
)

export const StockVSErpHelpContent = () => (
    <>
    <Divider orientation="left" orientationMargin={0}>
        <h3>Final Status</h3>
    </Divider>
    <b>Final Status</b> is the status of the stock in the system.
    <p style={{paddingLeft: '20px', marginTop: '4px'}}>
        <div style={finalStatusDivStyle}>
            <span style={{ ...finalStatusSpanStyle, backgroundColor: 'red' }} >
                High in ERP
            </span>
            ERP Stock count is <b>greater than</b> RFID scan count.
        </div>
        <div style={finalStatusDivStyle}>
            <span style={{ ...finalStatusSpanStyle, backgroundColor: 'red' }} >
                Low in ERP
            </span>
            ERP Stock count is <b>less than</b> RFID scan count.
        </div>
        <div style={finalStatusDivStyle}>
            <span style={{ ...finalStatusSpanStyle, backgroundColor: 'green' }} >
                Matching
            </span>
            ERP Stock count <b>matches</b> RFID scan count.
        </div>
        <div style={finalStatusDivStyle}>
            <span style={{ ...finalStatusSpanStyle, backgroundColor: 'red' }} >
                Not Found IN SOH
            </span>
            Product code is <b>not found</b> Stock On Hand File.
        </div>
    </p>
    </>
)
