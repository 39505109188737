import { Column } from '@ant-design/charts';
import moment from 'moment';
import React from 'react'

const ColumnGraph = ({ dataJSON, loading }) => {
    return (
        <Column
            data={dataJSON}
            loading={loading}
            xField="stock_date"
            yField="count"
            seriesField="final_status"
            isPercent={true}
            isStack={true}
            xAxis={{
                label: {
                    formatter: (value) => {
                        return moment(value).format('DD MMM, YYYY')
                    }
                }
            }}
            meta={{
                value: {
                    min: 0,
                    max: 1,
                }
            }}
            label={{
                position: "middle",
                content: item => {
                    return item.count * 100 > 4 ? `${(item.count * 100).toFixed(2)}%` : null;
                },
                style: {
                    fill: "#fff",
                },
            }}
            tooltip={{
                title: item => {
                    return moment(item).format('Do MMM, YYYY');
                }
            }}
            color={({ final_status }) => {
                if (final_status === "Matching") {
                    return "#61C149"
                }
                else if (final_status.toLowerCase().includes("high")) {
                    return "#E04B4B"
                }
                else if (final_status.toLowerCase().includes("low")) {
                    return "#EE9000"
                }
                else if (final_status.toLowerCase().includes("negative")) {
                    return "blue"
                }
                else if (final_status.toLowerCase().includes("zero")) {
                    return "grey"
                }
                else {
                    return "#E04B4B"
                }
            }}
            interactions={[
                {
                    type: "element-highlight-by-color",
                },
                {
                    type: "element-link",
                },
            ]}
            style={{ width: '100%' }}
        />
    )
}

export default ColumnGraph