import { useEffect, useState, useMemo } from "react";
import jwt_decode from "jwt-decode";
import { Routes, Route, useLocation, Navigate, Outlet, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { Button, Result, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Login from "./Auth/Login";
import { ar_loginUser, ar_logoutUser } from "./Redux/Actions/AuthActions";

// Files
import MyNavbar from "./AppLayout/MyNavbar";
import AppLayout from "./AppLayout/AppLayout";
import AllAppSettings from "./Masters/AppSettings/AllAppSettings";
import EditAppSettings from "./Masters/AppSettings/EditAppSettings";
import AllScopes from "./Masters/Scope/AllScopes";
import EditScope from "./Masters/Scope/EditScope";
import AllRoles from "./Masters/Role/AllRoles";
import EditRole from "./Masters/Role/EditRole";
import AllOrgs from "./Masters/Org/AllOrgs";
import AllUsers from "./Masters/User/AllUsers";
import EditUser from "./Masters/User/EditUser";
import EditOrg from "./Masters/Org/EditOrg";
import EditActionPlan from "./Masters/ActionPlan/EditActionPlan";
import AllActionPlans from "./Masters/ActionPlan/AllActionPlans";
import AllZoneGroups from "./Masters/ZoneGroup/AllZoneGroups";
import EditZoneGroup from "./Masters/ZoneGroup/EditZoneGroup";
import AllLocations from "./Masters/Location/AllLocations";
import EditRegion from "./Masters/Location/EditRegion";
import EditArea from "./Masters/Location/EditArea";
import EditSite from "./Masters/Location/EditSite";
import EditPriority from "./Masters/Priority/EditPriority";
import AllPriorities from "./Masters/Priority/AllPriorities";
import EditSeverity from "./Masters/Severity/EditSeverity";
import AllSeverities from "./Masters/Severity/AllSeverities";
import EditPanel from "./Masters/Panel/EditPanel";
import AllPanels from "./Masters/Panel/AllPanels";
import EventHistory from "./Reports/EventHistory";
import UnknownEvents from "./Reports/UnknownEvents";
import PanelStatusReport from "./Reports/PanelStatusReport";
import HourlyHeartBeat from "./Reports/HourlyHeartBeat";
import MonthlyHeartBeat from "./Reports/MonthlyHeartBeat";
import AuditLog from "./Reports/AuditLog";
import CurrentZoneStatus from "./Reports/CurrentZoneStatus";
import ArmDisarm from "./Reports/ArmDisarm";
import AllApiKeys from "./Masters/ApiKeys/AllApiKeys";
import EditApiKeys from "./Masters/ApiKeys/EditApiKeys";

// Icons
import { BsCalendar2Event, BsPrinter, BsUpload } from "react-icons/bs";
import { FaWpforms, FaRegBuilding, FaChartBar, FaRegListAlt } from "react-icons/fa";
import { GiArrowScope, GiSettingsKnobs } from "react-icons/gi";
import { CgOrganisation } from "react-icons/cg";
import { MdOutlineAppSettingsAlt, MdPermPhoneMsg } from "react-icons/md";
import { BsFilePerson, BsPeople } from "react-icons/bs";
import { DesktopOutlined, AppstoreOutlined, GlobalOutlined, ShopOutlined, ScheduleOutlined, DatabaseOutlined, ClusterOutlined } from "@ant-design/icons";
import { GiMeepleGroup } from "react-icons/gi";
import { GoLocation } from "react-icons/go";
import { MdPriorityHigh } from "react-icons/md";
import { BsExclamationTriangle } from "react-icons/bs";
import { BiLogOut } from "react-icons/bi";
import { VscCircuitBoard, VscTools } from "react-icons/vsc";
import { VscWorkspaceUnknown } from "react-icons/vsc";
import { HiDocumentReport, HiOutlineStatusOnline, HiUser } from "react-icons/hi";
import { FaRegCalendarAlt } from "react-icons/fa";
import { GiHourglass } from "react-icons/gi";
import { AiOutlineAudit } from "react-icons/ai";
import { BsCalendar3Event } from "react-icons/bs";
import { Notifications } from "react-push-notification";
import addNotification from "react-push-notification";
import InwardOutwardReport from "./RFIDReports/InwardOutwardNew/InwardOutwardReport";
import { gr_hideSiteSelector, gr_showSiteSelector } from "./Redux/Actions/GlobalActions";
import StockSessionsList from "./RFIDReports/StockTake/SessionsList";
import OneSessionReport from "./RFIDReports/StockTake/OneSessionReport";
import DateWiseComparison from "./RFIDReports/StockTake/DateWiseComparison";
import EncodingSessionsList from "./RFIDReports/Encoding/EncodingSessionsList";
import EditEncodingSession from "./RFIDReports/Encoding/EditEncodingSession";
import ProductMasterTable from "./RFIDReports/ProductMasterTable/ProductMasterTable";
import { MdOutlineVpnKey } from "react-icons/md";
import CustomerStockTable from "./RFIDReports/Customer_stock/CustomerStockTable";
import InwardOutwardList from "./RFIDReports/InwardOutwardNew/InwardOutwardList";
import DateWiseStockTakeReport from "./Dashboard/DateWiseStockTakeReport";
import Printing from "./RFIDReports/Printing/Printing";
import GlobalReports from "./Dashboard/GlobalReports";
import StoreReports from "./Dashboard/StoreReports";
import AsynchronousReports from "./RFIDReports/AsynchronousReports/AsynchronousReports";
import Profile from "./RFIDReports/Profile/Profile";
import EditEPCGenerationSession from "./RFIDReports/EpcGeneration/EditEPCGenerationSession";
import EPCGenerationSessionsList from "./RFIDReports/EpcGeneration/EPCGenerationSessionsList";
import FileUpload from "./RFIDReports/FileUpload/FileUpload";
import CentralPrintingList from "./RFIDReports/CentralPrinting/CentralPrintingList";
import CentralPrintingDetails from "./RFIDReports/CentralPrinting/CentralPrintingDetails";

function App() {
    const [loading, setLoading] = useState(true);
    const globalReducer = useSelector((state) => state.globalReducer);
    const authReducer = useSelector((state) => state.authReducer);
    const myRoles = authReducer?.roles;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let location = useLocation();

    // const searchParams = "orgId=" + globalReducer.selectedOrg.orgId;
    const searchParams = useMemo(() => {
        return "orgId=" + globalReducer.selectedOrg.orgId;
    }, [globalReducer.selectedOrg.orgId]);

    const getPermissions = (moduleCode) => {
        // if (authReducer.user?.isSuperAdmin) {
        //     return {
        //         add: true,
        //         edit: true,
        //         view: true,
        //         delete: true,
        //         export: true,
        //         save: true,
        //         // import_stocktransfer: true,
        //         // import_planned_list: true,
        //         // approve_encode_session: true
        //     };
        // }
        return myRoles[moduleCode] ? myRoles[moduleCode] : {};
    };

    const hasAccess = (moduleCode, perm) => {
        // console.log(authReducer.user.isSuperAdmin)
        if (authReducer.user?.isSuperAdmin) {
            return true;
        }
        return myRoles?.[moduleCode]?.[perm];
    };

    // The name and path fields are for global search
    const menuItems = useMemo(
        () => [
            // {
            //     key: "home",
            //     label: "Home",
            //     icon: <DesktopOutlined />,
            //     search: "home",
            //     onclick: () => {
            //         navigate('/')
            //     },
            // },

            {
                key: "dashboard",
                label: "Dashboard",
                icon: <AppstoreOutlined size={16} />,
                search: "dashboard",
                children: [
                    {
                        key: "global-reports",
                        label: (
                            <Link style={{ color: "inherit" }} to={`/dashboard/global-reports?${searchParams}`}>
                                Global Reports
                            </Link>
                        ),
                        icon: <GlobalOutlined />,
                        search: "global reports",
                        module_code: "TAGIDA_DASHBOARD",
                        pathname: `/dashboard/global-reports?${searchParams}`,
                        name: "Global Reports",
                    },
                    {
                        key: "store-reports",
                        label: (
                            <Link style={{ color: "inherit" }} to={`/dashboard/store-reports?${searchParams}`}>
                                Store Reports
                            </Link>
                        ),
                        icon: <ShopOutlined />,
                        search: "store reports",
                        module_code: "TAGIDA_DASHBOARD",
                        pathname: `/dashboard/store-reports?${searchParams}`,
                        name: "Store Reports",
                    },
                    {
                        key: "date-wise-stock-take",
                        label: (
                            <Link style={{ color: 'inherit' }} to={`/dashboard/date-wise-stock-take?${searchParams}`}>
                                Date Wise Stock Take
                            </Link>
                        ),
                        icon: <FaRegListAlt />,
                        search: "date wise stock take",
                        module_code: "TAGIDA_DASHBOARD",
                        pathname: `/dashboard/date-wise-stock-take?${searchParams}`,
                        name: "Date Wise Stock Take",
                    },

                ].filter((item) => {
                    if (hasAccess(item.module_code, "view") || hasAccess(item.module_code, "all")) {
                        return true;
                    }
                    return false;
                }),
            },
            {
                key: "masters",
                label: "Masters",
                icon: <FaWpforms />,
                search: "masters",
                hidden: !hasAccess("TAGIDA_MASTER", "view"),
                children: [
                    {
                        key: "scope",
                        label: (
                            <Link style={{ color: "inherit" }} to={`/masters/scope?${searchParams}`}>
                                Scope
                            </Link>
                        ),
                        icon: <GiArrowScope />,
                        search: "scope",
                        module_code: "TAGIDA_SCOPE",

                        pathname: `/masters/scope?${searchParams}`,
                        name: "Scope",
                    },
                    {
                        key: "role",
                        label: (
                            <Link style={{ color: "inherit" }} to={`/masters/role?${searchParams}`}>
                                Role
                            </Link>
                        ),
                        icon: <BsFilePerson />,
                        search: "role",
                        module_code: "TAGIDA_ROLE",

                        pathname: `/masters/role?${searchParams}`,
                        name: "Role",
                    },
                    {
                        key: "user",
                        label: (
                            <Link style={{ color: "inherit" }} to={`/masters/user?${searchParams}`}>
                                User
                            </Link>
                        ),
                        icon: <BsPeople />,
                        search: "user employee",
                        module_code: "TAGIDA_USER",

                        pathname: `/masters/user?${searchParams}`,
                        name: "User",
                    },
                    {
                        key: "appsettings",
                        label: (
                            <Link style={{ color: "inherit" }} to={`/masters/appsettings?${searchParams}`}>
                                AppSettings
                            </Link>
                        ),
                        icon: <MdOutlineAppSettingsAlt />,
                        search: "scope",
                        module_code: "TAGIDA_SCOPE",

                        pathname: `/masters/appsettings?${searchParams}`,
                        name: "App Settings",
                    },
                    {
                        key: "api_keys",
                        label: (
                            <Link style={{ color: "inherit" }} to={`/masters/api_keys?${searchParams}`}>
                                Api Keys
                            </Link>
                        ),
                        icon: <MdOutlineVpnKey />,
                        search: "api_keys",
                        module_code: "TAGIDA_API_KEYS",

                        pathname: `/masters/api_keys?${searchParams}`,
                        name: "Api Keys",
                    },
                    // {
                    // 	key: "company",
                    // 	label: (
                    // 		<Link style={{ color: 'inherit' }} to={`/masters/company?${searchParams}`}>
                    // 			Company
                    // 		</Link>
                    // 	),
                    // 	icon: <CgOrganisation />,
                    // 	search: "company",
                    // 	module_code: "TAGIDA_COMPANY",

                    // 	pathname: `/masters/company?${searchParams}`,
                    // 	name: "Company",
                    // },
                    // {
                    // 	key: "vendor",
                    // 	label: (
                    // 		<Link style={{ color: 'inherit' }} to={`/masters/vendor?${searchParams}`}>
                    // 			Vendor
                    // 		</Link>
                    // 	),
                    // 	icon: <FaRegBuilding />,
                    // 	search: "vendor",
                    // 	module_code: "TAGIDA_VENDOR",

                    // 	pathname: `/masters/vendor?${searchParams}`,
                    // 	name: "Vendor",
                    // },
                    // {
                    // 	key: "actionPlan",
                    // 	label: (
                    // 		<Link style={{ color: 'inherit' }} to={`/masters/action-plan?${searchParams}`}>
                    // 			Action Plan
                    // 		</Link>
                    // 	),
                    // 	icon: <MdPermPhoneMsg />,
                    // 	search: "action plan",
                    // 	module_code: "TAGIDA_ACTION_PLAN",

                    // 	pathname: `/masters/action-plan?${searchParams}`,
                    // 	name: "Action Plan",
                    // },
                    // {
                    // 	key: "zoneGroup",
                    // 	label: (
                    // 		<Link style={{ color: 'inherit' }} to={`/masters/zone-group?${searchParams}`}>
                    // 			Zone Group
                    // 		</Link>
                    // 	),
                    // 	icon: <GiMeepleGroup />,
                    // 	search: "zone group",
                    // 	module_code: "TAGIDA_ZONE_GROUP",

                    // 	pathname: `/masters/zone-group?${searchParams}`,
                    // 	name: "Zone Group",
                    // },
                    {
                        key: "location",
                        label: (
                            <Link style={{ color: "inherit" }} to={`/masters/location?${searchParams}`}>
                                Location
                            </Link>
                        ),
                        icon: <GoLocation />,
                        search: "location site area region zone",
                        module_code: "TAGIDA_LOCATION",

                        pathname: `/masters/location?${searchParams}`,
                        name: "Location",
                    },
                    // {
                    // 	key: "priority",
                    // 	label: (
                    // 		<Link style={{ color: 'inherit' }} to={`/masters/priority?${searchParams}`}>
                    // 			Priority
                    // 		</Link>
                    // 	),
                    // 	icon: <MdPriorityHigh />,
                    // 	search: "priority",
                    // 	module_code: "TAGIDA_PRIORITY",

                    // 	pathname: `/masters/priority?${searchParams}`,
                    // 	name: "Priority",
                    // },
                    // {
                    // 	key: "severity",
                    // 	label: (
                    // 		<Link style={{ color: 'inherit' }} to={`/masters/severity?${searchParams}`}>
                    // 			Severity
                    // 		</Link>
                    // 	),
                    // 	icon: <BsExclamationTriangle />,
                    // 	search: "severity",
                    // 	module_code: "TAGIDA_SEVERITY",

                    // 	pathname: `/masters/severity?${searchParams}`,
                    // 	name: "Severity",
                    // },
                    {
                        key: "panel",
                        label: (
                            <Link style={{ color: "inherit" }} to={`/masters/panel?${searchParams}`}>
                                Panel
                            </Link>
                        ),
                        icon: <VscCircuitBoard />,
                        search: "panel reader",
                        module_code: "TAGIDA_PANEL",

                        pathname: `/masters/panel?${searchParams}`,
                        name: "Panel",
                    },
                ].filter((item) => {
                    if (hasAccess(item.module_code, "view") || hasAccess(item.module_code, "all")) {
                        return true;
                    }
                    return false;
                }),
            },
            {
                key: "tools",
                label: "Tools",
                icon: <VscTools />,
                search: "tools",
                children: [
                    {
                        key: "encoding",
                        label: (
                            <Link style={{ color: "inherit" }} to={`/tools/encoding/sessions?${searchParams}`}>
                                Encoding
                            </Link>
                        ),
                        icon: <FaRegListAlt />,
                        search: "encoding",
                        module_code: "TAGIDA_ENCODING",
                        pathname: `/tools/encoding/sessions?${searchParams}`,
                        name: "Encoding",
                    },
                    {
                        key: "epc-generation",
                        label: (
                            <Link style={{ color: "inherit" }} to={`/tools/epc-generation/sessions?${searchParams}`}>
                                EPC Generation
                            </Link>
                        ),
                        icon: <FaRegListAlt />,
                        search: "epc generation",
                        module_code: "TAGIDA_EPC_GENERATION",
                        pathname: `/tools/epc-generation/sessions?${searchParams}`,
                        name: "EPC Generation",
                    },
                    {
                        key: "printing",
                        label: (
                            <Link style={{ color: "inherit" }} to={`/tools/printing?${searchParams}`}>
                                Printing
                            </Link>
                        ),
                        icon: <BsPrinter />,
                        search: "printing",
                        module_code: "TAGIDA_PRINTING",

                        pathname: `/tools/printing?${searchParams}`,
                        name: "Printing",
                    },
                    {
                        key: "file-upload",
                        label: (
                            <Link style={{ color: "inherit" }} to={`/tools/file-upload?${searchParams}`}>
                                File Upload
                            </Link>
                        ),
                        icon: <BsUpload />,
                        search: "file-upload",
                        module_code: "TAGIDA_FILE_UPLOAD",

                        pathname: `/tools/file-upload?${searchParams}`,
                        name: "FileUpload",
                    },
                    {
                        key: "central_printing",
                        label: (
                            <Link style={{ color: "inherit" }} to={`/tools/central-printing?${searchParams}`}>
                                Central Printing
                            </Link>
                        ),
                        icon: <BsPrinter />,
                        search: "Central Printing",
                        module_code: "TAGIDA_CENTRAL_PRINTING",
                        pathname: `/tools/central-printing?${searchParams}`,
                        name: "Central Printing",
                    },
                ].filter((item) => {
                    if (hasAccess(item.module_code, "view") || hasAccess(item.module_code, "all")) {
                        return true;
                    }
                    return false;
                }),
            },
            {
                key: "inventory",
                label: "Inventory",
                icon: <DatabaseOutlined />,
                search: "inventory",
                children: [
                    {
                        key: "stock-transfer",
                        label: <>Stock Transfer</>,
                        icon: <ClusterOutlined />,
                        search: "stock transfer",
                        module_code: "TAGIDA_INVENTORY",
                        pathname: `/inventory/stock-transfer?${searchParams}`,
                        name: "Stock Transfer",
                        children: [
                            {
                                key: "inward",
                                label: (
                                    <Link style={{ color: "inherit" }} to={`/inventory/inward-outward?report=inward&${searchParams}`}>
                                        Inward
                                    </Link>
                                ),
                                icon: <GiArrowScope />,
                                search: "inward",
                                module_code: "TAGIDA_INWARD_OUTWARD",
                                pathname: `/inventory/inward?${searchParams}`,
                                name: "Inward",
                            },
                            {
                                key: "outward",
                                label: (
                                    <Link style={{ color: "inherit" }} to={`/inventory/inward-outward?report=outward&${searchParams}`}>
                                        Outward
                                    </Link>
                                ),
                                icon: <BsFilePerson />,
                                search: "outward",
                                module_code: "TAGIDA_INWARD_OUTWARD",
                                pathname: `/inventory/outward?${searchParams}`,
                                name: "Outward",
                            },
                        ],
                    },
                    {
                        key: "product_master",
                        label: (
                            <Link style={{ color: "inherit" }} to={`/inventory/product-master?${searchParams}`}>
                                Product Master
                            </Link>
                        ),
                        icon: <FaRegListAlt />,
                        search: "product master",
                        module_code: "TAGIDA_INVENTORY",
                        pathname: `/inventory/product-master?${searchParams}`,
                        name: "Product Master",
                    },
                    {
                        key: "Customer_Stock",
                        label: (
                            <Link style={{ color: "inherit" }} to={`/inventory/customer-stock?${searchParams}`}>
                                Customer Stock
                            </Link>
                        ),
                        icon: <FaRegListAlt />,
                        search: "customer-stock",
                        // report_code: "customer-stock",
                        module_code: "TAGIDA_INVENTORY",
                        pathname: `/inventory/customer-stock?${searchParams}`,
                        name: "Customer Stock",
                    },
                    // {
                    // 	key: "inward-outward",
                    // 	label: (
                    // 		<Link style={{ color: 'inherit' }} to={`/rfid-reports/inward-outward?${searchParams}`}>
                    // 			Inward Outward
                    // 		</Link>
                    // 	),
                    // 	icon: <FaRegListAlt />,
                    // 	search: "inward outward",
                    // 	report_code: "inward_outward",

                    // 	pathname: `/rfid-reports/inward-outward?${searchParams}`,
                    // 	name: "Inward Outward",
                    // },
                    {
                        key: "stock-take",
                        label: <>Stock Take</>,
                        icon: <FaRegListAlt />,
                        search: "stock take",
                        // report_code: "stock_take",
                        module_code: "TAGIDA_INVENTORY",
                        pathname: `/inventory/stock-take?${searchParams}`,
                        name: "Stock Take",
                        children: [
                            {
                                key: "stock-take-store-wise",
                                label: (
                                    <Link style={{ color: "inherit" }} to={`/inventory/stock-take/sessions?${searchParams}`}>
                                        Sessions
                                    </Link>
                                ),
                                icon: <FaRegListAlt />,
                                search: "stock take store wise sessions",
                                report_code: "stock_take",
                                pathname: `/inventory/stock-take/sessions?${searchParams}`,
                                name: "Stock Take Sessions",
                            },
                        ],
                    },

                ].filter((item) => {
                    if (hasAccess(item.module_code, "view") || hasAccess(item.module_code, "all")) {
                        return true;
                    }
                    return false;
                }),
            },
            {
                key: "reports",
                label: (
                    <Link style={{ color: "inherit" }} to={`/asynchronous_reports?${searchParams}`}>
                        Reports
                    </Link>
                ),
                icon: <HiDocumentReport />,
                search: "reports",
                pathname: `/asynchronous_reports?${searchParams}`,
                name: "Reports",
                hidden: !hasAccess("TAGIDA_REPORTS", "view"),
            },
            // {
            //     key: "Profile",
            //     label: (
            //         <Link style={{ color: "inherit" }} to={`/profile?${searchParams}`}>
            //             Profile
            //         </Link>
            //     ),
            //     icon: <HiUser />,
            //     search: "profile",
            //     pathname: `/profile?${searchParams}`,
            //     name: "Profile",
            // },
            {
                label: "Logout",
                key: "logout",
                name: "Logout",
                search: "logout",
                icon: <BiLogOut />,
                style: { marginTop: "5px" },
                onClick: () => {
                    dispatch(ar_logoutUser());
                },
            },
        ].filter((item) => {
            if (item.children && !item.children?.length && item?.hidden) {
                return false;
            }
            return true;
        }),
        [authReducer, globalReducer.selectedOrg.orgId, searchParams]
    );



    const getRoleFromToken = () => {
        const roleToken = localStorage.getItem("RoleToken");
        try {
            var decoded = jwt_decode(roleToken);

            // axios.post('/admin-api/verify_role_token', {
            // 		"role_token": roleToken,
            // 		"token": localStorage.getItem("AdminToken")
            // 	}
            // )
            // .then(res => {})
            // .catch(err => {
            // 	console.log(err);
            // 	dispatch(ar_logoutUser());
            // });

            return decoded;
        } catch (err) {
            dispatch(ar_logoutUser());
            return;
        }

        // return decoded;
    };

    const validateToken = () => {
        setLoading(true);
        if (localStorage.getItem("AdminToken")) {
            try {
                const token = localStorage.getItem("AdminToken");
                const decoded = jwt_decode(token);
                // const decoded = {};
                const currentTime = Date.now() / 1000; // to get in milliseconds
                if (currentTime <= decoded.exp) {
                    // Get Roles
                    var roles = getRoleFromToken();

                    const data = {
                        isAuthenticated: true,
                        user: {
                            userId: decoded.user_id,
                            username: decoded.username,
                            eId: decoded.e_id,
                            roleId: decoded.role_id,
                            scopeId: decoded.scope_id,
                            isSuperAdmin: decoded.is_super_admin,
                            isAdmin: decoded.is_admin,
                            orgId: decoded.o_id,
                            parentOrgId: decoded.parent_org_id,
                            orgType: decoded.o_type,
                        },
                        roles: roles,
                    };
                    dispatch(ar_loginUser(data));
                }
            }
            catch (error) {
                dispatch(ar_logoutUser());
            }
        }
        // dispatch(ar_loginUser({ isAuthenticated: true, user: {} }));  // Uncomment this line for testing
        setLoading(false);
    };
    useEffect(() => {
        validateToken();
    }, []);

    // const buttonClick = () => {
    //       addNotification({
    //           title: 'New Updates',
    //           subtitle: 'This is a subtitle',
    //           message: 'This is a very long message',
    //           theme: 'darkblue',
    //           native: true // when using native, your OS will handle theming.
    //       });
    //   };

    // useEffect(()=>
    // {
    // 	buttonClick();

    // },[])

    console.log("User : ", globalReducer);

    return (
        <div className="App">
            <Notifications />
            {/* <Button onClick={buttonClick} type="primary">
				Notifications
			</Button> */}
            <Routes>
                <Route path="/login" element={<Login />}>
                    <Route path="/login/aa" element={<MyNavbar />} />
                </Route>

                {/*
					Validate the token first.
					Till the token is being validated, show the loading screen.
					After that loading will be false, hence check if the validation is successful or not.
					If successful, then move forward.
					Else, naviate to the login page.
				*/}
                <Route
                    path="*"
                    element={
                        loading ? (
                            <div className="App" style={{ textAlign: "center" }}>
                                <Spin size="large" style={{ marginTop: "50px" }} />
                            </div>
                        ) : authReducer.isAuthenticated ? (
                            <>
                                {/* The Outlet is in MyNavbar File (below the app-bar) */}
                                {/* Global Search component is in the navbar file because it exports the cntrl+k icons which needs to be rendered there */}
                                <AppLayout
                                    menuItems={menuItems}
                                    globalSearchItems={[
                                        // Find the search items in the menuItems with key "masters" and take its children
                                        ...(menuItems.find((item) => item.key === "masters")?.children || []),
                                        ...(menuItems.find((item) => item.key === "tools")?.children || []),
                                    ]}
                                />
                            </>
                        ) : (
                            <Navigate
                                to="/login"
                                replace={true}
                                state={{
                                    from: location.pathname + location.search,
                                }}
                            />
                        )
                    }
                >
                    {/* All Authenticated Routes here ............ */}
                    <Route path="abc" element={<h1>/abc Page</h1>} />

                    <Route path="masters" element={<Outlet />}>
                        {/* Scope Routes ===> ModuleCode: TAGIDA_SCOPE ; ApplicationCode: APP_CMS_ADMIN */}
                        <Route path="scope" element={<Outlet />}>
                            {(hasAccess("TAGIDA_SCOPE", "edit") || hasAccess("TAGIDA_SCOPE", "view")) && (
                                <Route
                                    path="edit/:id"
                                    element={
                                        <EditScope
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={false}
                                            permissions={getPermissions("TAGIDA_SCOPE")}
                                        />
                                    }
                                />
                            )}
                            {hasAccess("TAGIDA_SCOPE", "add") && (
                                <Route
                                    path="add"
                                    element={
                                        <EditScope
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={true}
                                            permissions={getPermissions("TAGIDA_SCOPE")}
                                        />
                                    }
                                />
                            )}
                            {hasAccess("TAGIDA_SCOPE", "view") && (
                                <Route
                                    path=""
                                    element={
                                        <AllScopes
                                            selectedOrg={globalReducer.selectedOrg}
                                            permissions={getPermissions("TAGIDA_SCOPE")}
                                        />
                                    }
                                />
                            )}
                        </Route>

                        {/* Role Routes ===> ModuleCode: TAGIDA_ROLE ; ApplicationCode: APP_CMS_ADMIN */}
                        <Route path="role" element={<Outlet />}>
                            {(hasAccess("TAGIDA_ROLE", "edit") || hasAccess("TAGIDA_ROLE", "view")) && (
                                <Route
                                    path="edit/:id"
                                    element={
                                        <EditRole
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={false}
                                            my_permissions={getPermissions("TAGIDA_ROLE")}
                                        />
                                    }
                                />
                            )}
                            {hasAccess("TAGIDA_ROLE", "add") && (
                                <Route
                                    path="add"
                                    element={
                                        <EditRole
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={true}
                                            my_permissions={getPermissions("TAGIDA_ROLE")}
                                        />
                                    }
                                />
                            )}
                            {hasAccess("TAGIDA_ROLE", "view") && (
                                <Route
                                    path=""
                                    element={
                                        <AllRoles
                                            selectedOrg={globalReducer.selectedOrg}
                                            permissions={getPermissions("TAGIDA_ROLE")}
                                        />
                                    }
                                />
                            )}
                        </Route>

                        {/* User Routes ===> ModuleCode: TAGIDA_USER ; ApplicationCode: APP_CMS_ADMIN */}
                        <Route path="user" element={<Outlet />}>
                            {(hasAccess("TAGIDA_USER", "edit") || hasAccess("TAGIDA_USER", "view")) && (
                                <Route
                                    path="edit/:id"
                                    element={
                                        <EditUser
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={false}
                                            permissions={getPermissions("TAGIDA_USER")}
                                        />
                                    }
                                />
                            )}
                            {hasAccess("TAGIDA_USER", "add") && (
                                <Route
                                    path="add"
                                    element={
                                        <EditUser
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={true}
                                            permissions={getPermissions("TAGIDA_USER")}
                                        />
                                    }
                                />
                            )}
                            {hasAccess("TAGIDA_USER", "view") && (
                                <Route
                                    path=""
                                    element={
                                        <AllUsers
                                            selectedOrg={globalReducer.selectedOrg}
                                            permissions={getPermissions("TAGIDA_USER")}
                                        />
                                    }
                                />
                            )}
                        </Route>

                        {/* Comapny Routes ===> ModuleCode: TAGIDA_COMPANY ; ApplicationCode: APP_CMS_ADMIN */}
                        <Route path="company" element={<Outlet />}>
                            {(hasAccess("TAGIDA_COMPANY", "edit") || hasAccess("TAGIDA_COMPANY", "view")) && (
                                <Route
                                    path="edit/:id"
                                    element={
                                        <EditOrg
                                            key="companyEdit"
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={false}
                                            permissions={{
                                                // ...getPermissions("CMSA_COMPANY"),
                                                // edit: authReducer.user.orgType === 3 ? false : getPermissions("CMSA_COMPANY").edit,
                                                edit: true,
                                                add: true,
                                            }}
                                            orgTypeId={3}
                                            orgTypeName="Company"
                                            homePath="/masters/company"
                                        />
                                    }
                                />
                            )}
                            {hasAccess("TAGIDA_COMPANY", "add") && (
                                <Route
                                    path="add"
                                    element={
                                        <EditOrg
                                            key="companyAdd"
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={true}
                                            permissions={getPermissions("TAGIDA_COMPANY")}
                                            orgTypeId={3}
                                            orgTypeName="Company"
                                            homePath="/masters/company"
                                        />
                                    }
                                />
                            )}
                            {hasAccess("TAGIDA_COMPANY", "view") && (
                                <Route
                                    path=""
                                    element={
                                        <AllOrgs
                                            key="companyAll"
                                            selectedOrg={globalReducer.selectedOrg}
                                            permissions={getPermissions("TAGIDA_COMPANY")}
                                            orgTypeId={3}
                                            orgTypeName="Company"
                                            editPath="/masters/company/edit"
                                            addPath="/masters/company/add"
                                        />
                                    }
                                />
                            )}
                        </Route>

                        {/* Vendor Routes ===> ModuleCode: TAGIDA_VENDOR ; ApplicationCode: APP_CMS_ADMIN */}
                        <Route path="vendor" element={<Outlet />}>
                            {(hasAccess("TAGIDA_VENDOR", "edit") || hasAccess("TAGIDA_VENDOR", "view")) && (
                                <Route
                                    path="edit/:id"
                                    element={
                                        <EditOrg
                                            key="vendorEdit"
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={false}
                                            permissions={getPermissions("TAGIDA_VENDOR")}
                                            orgTypeId={4}
                                            orgTypeName="Vendor"
                                            homePath="/masters/vendor"
                                        />
                                    }
                                />
                            )}
                            {hasAccess("TAGIDA_VENDOR", "add") && (
                                <Route
                                    path="add"
                                    element={
                                        <EditOrg
                                            key="vendorAdd"
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={true}
                                            permissions={getPermissions("TAGIDA_VENDOR")}
                                            orgTypeId={4}
                                            orgTypeName="Vendor"
                                            homePath="/masters/vendor"
                                        />
                                    }
                                />
                            )}
                            {hasAccess("TAGIDA_VENDOR", "view") && (
                                <Route
                                    path=""
                                    element={
                                        <AllOrgs
                                            key="vendorAll"
                                            selectedOrg={globalReducer.selectedOrg}
                                            permissions={getPermissions("TAGIDA_VENDOR")}
                                            orgTypeId={4}
                                            orgTypeName="Vendor"
                                            editPath="/masters/vendor/edit"
                                            addPath="/masters/vendor/add"
                                        />
                                    }
                                />
                            )}
                        </Route>

                        {/* Action-Plan Routes ===> ModuleCode: TAGIDA_ACTION_PLAN ; ApplicationCode: APP_CMS_ADMIN */}
                        <Route path="action-plan" element={<Outlet />}>
                            {(hasAccess("TAGIDA_ACTION_PLAN", "edit") || hasAccess("TAGIDA_ACTION_PLAN", "view")) && (
                                <Route
                                    path="edit/:id"
                                    element={
                                        <EditActionPlan
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={false}
                                            permissions={getPermissions("TAGIDA_ACTION_PLAN")}
                                        />
                                    }
                                />
                            )}
                            {hasAccess("TAGIDA_ACTION_PLAN", "add") && (
                                <Route
                                    path="add"
                                    element={
                                        <EditActionPlan
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={true}
                                            permissions={getPermissions("TAGIDA_ACTION_PLAN")}
                                        />
                                    }
                                />
                            )}
                            {hasAccess("TAGIDA_ACTION_PLAN", "view") && (
                                <Route
                                    path=""
                                    element={
                                        <AllActionPlans
                                            selectedOrg={globalReducer.selectedOrg}
                                            permissions={getPermissions("TAGIDA_ACTION_PLAN")}
                                        />
                                    }
                                />
                            )}
                        </Route>

                        {/* Zone-Group Routes ===> ModuleCode: TAGIDA_ZONE_GROUP ; ApplicationCode: APP_CMS_ADMIN */}
                        <Route path="zone-group" element={<Outlet />}>
                            {(hasAccess("TAGIDA_ZONE_GROUP", "edit") || hasAccess("TAGIDA_ZONE_GROUP", "view")) && (
                                <Route
                                    path="edit/:id"
                                    element={
                                        <EditZoneGroup
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={false}
                                            permissions={getPermissions("TAGIDA_ZONE_GROUP")}
                                        />
                                    }
                                />
                            )}
                            {hasAccess("TAGIDA_ZONE_GROUP", "add") && (
                                <Route
                                    path="add"
                                    element={
                                        <EditZoneGroup
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={true}
                                            permissions={getPermissions("TAGIDA_ZONE_GROUP")}
                                        />
                                    }
                                />
                            )}
                            {hasAccess("TAGIDA_ZONE_GROUP", "view") && (
                                <Route
                                    path=""
                                    element={
                                        <AllZoneGroups
                                            selectedOrg={globalReducer.selectedOrg}
                                            permissions={getPermissions("TAGIDA_ZONE_GROUP")}
                                        />
                                    }
                                />
                            )}
                        </Route>

                        {/* Priority Routes ===> ModuleCode: TAGIDA_PRIORITY ; ApplicationCode: APP_CMS_ADMIN */}
                        <Route path="priority" element={<Outlet />}>
                            {(hasAccess("TAGIDA_PRIORITY", "edit") || hasAccess("TAGIDA_PRIORITY", "view")) && (
                                <Route
                                    path="edit/:id"
                                    element={
                                        <EditPriority
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={false}
                                            permissions={getPermissions("TAGIDA_PRIORITY")}
                                        />
                                    }
                                />
                            )}
                            {hasAccess("TAGIDA_PRIORITY", "add") && (
                                <Route
                                    path="add"
                                    element={
                                        <EditPriority
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={true}
                                            permissions={getPermissions("TAGIDA_PRIORITY")}
                                        />
                                    }
                                />
                            )}
                            {hasAccess("TAGIDA_PRIORITY", "view") && (
                                <Route
                                    path=""
                                    element={
                                        <AllPriorities
                                            selectedOrg={globalReducer.selectedOrg}
                                            permissions={getPermissions("TAGIDA_PRIORITY")}
                                        />
                                    }
                                />
                            )}
                        </Route>

                        {/* Severity Routes ===> ModuleCode: TAGIDA_SEVERITY ; ApplicationCode: APP_CMS_ADMIN */}
                        <Route path="severity" element={<Outlet />}>
                            {(hasAccess("TAGIDA_SEVERITY", "edit") || hasAccess("TAGIDA_SEVERITY", "view")) && (
                                <Route
                                    path="edit/:id"
                                    element={
                                        <EditSeverity
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={false}
                                            permissions={getPermissions("TAGIDA_SEVERITY")}
                                        />
                                    }
                                />
                            )}
                            {hasAccess("TAGIDA_SEVERITY", "add") && (
                                <Route
                                    path="add"
                                    element={
                                        <EditSeverity
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={true}
                                            permissions={getPermissions("TAGIDA_SEVERITY")}
                                        />
                                    }
                                />
                            )}
                            {hasAccess("TAGIDA_SEVERITY", "view") && (
                                <Route
                                    path=""
                                    element={
                                        <AllSeverities
                                            selectedOrg={globalReducer.selectedOrg}
                                            permissions={getPermissions("TAGIDA_SEVERITY")}
                                        />
                                    }
                                />
                            )}
                        </Route>

                        {/* Location Routes ===> ModuleCode: TAGIDA_LOCATION ; ApplicationCode: APP_CMS_ADMIN */}
                        <Route path="location" element={<Outlet />}>
                            {(hasAccess("TAGIDA_LOCATION", "edit") || hasAccess("TAGIDA_LOCATION", "view")) && (
                                <Route
                                    path="region/edit/:id"
                                    element={
                                        <EditRegion
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={false}
                                            permissions={getPermissions("TAGIDA_LOCATION")}
                                        />
                                    }
                                />
                            )}
                            {hasAccess("TAGIDA_LOCATION", "add") && (
                                <Route
                                    path="region/add"
                                    element={
                                        <EditRegion
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={true}
                                            permissions={getPermissions("TAGIDA_LOCATION")}
                                        />
                                    }
                                />
                            )}

                            {(hasAccess("TAGIDA_LOCATION", "edit") || hasAccess("TAGIDA_LOCATION", "view")) && (
                                <Route
                                    path="area/edit/:id"
                                    element={
                                        <EditArea
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={false}
                                            permissions={getPermissions("TAGIDA_LOCATION")}
                                        />
                                    }
                                />
                            )}
                            {hasAccess("TAGIDA_LOCATION", "add") && (
                                <Route
                                    path="area/add"
                                    element={
                                        <EditArea
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={true}
                                            permissions={getPermissions("TAGIDA_LOCATION")}
                                        />
                                    }
                                />
                            )}

                            {(hasAccess("TAGIDA_LOCATION", "edit") || hasAccess("TAGIDA_LOCATION", "view")) && (
                                <Route
                                    path="site/edit/:id"
                                    element={
                                        <EditSite
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={false}
                                            permissions={getPermissions("TAGIDA_LOCATION")}
                                        />
                                    }
                                />
                            )}
                            {hasAccess("TAGIDA_LOCATION", "add") && (
                                <Route
                                    path="site/add"
                                    element={
                                        <EditSite
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={true}
                                            permissions={getPermissions("TAGIDA_LOCATION")}
                                        />
                                    }
                                />
                            )}

                            {hasAccess("TAGIDA_LOCATION", "view") && (
                                <Route
                                    path=""
                                    element={
                                        <AllLocations
                                            selectedOrg={globalReducer.selectedOrg}
                                            permissions={getPermissions("TAGIDA_LOCATION")}
                                        />
                                    }
                                />
                            )}
                        </Route>

                        {/* AppSettings Routes ===> ModuleCode: TAGIDA_APP_SETTINGS ; ApplicationCode: APP_TAGID_ADMIN */}
                        <Route path="appsettings" element={<Outlet />}>
                            {(hasAccess("TAGIDA_APP_SETTINGS", "edit") || hasAccess("TAGIDA_APP_SETTINGS", "view")) && (
                                <Route
                                    path="edit/:id"
                                    element={
                                        <EditAppSettings
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={false}
                                            permissions={getPermissions("TAGIDA_APP_SETTINGS")}
                                        />
                                    }
                                />
                            )}
                            {/* {
								(hasAccess("TAGIDA_APP_SETTINGS", "add"))
								&&
								<Route
									path="add"
									element={
										<EditAppSettings
											selectedOrg={globalReducer.selectedOrg}
											isAddNew={true}
											permissions={getPermissions("TAGIDA_APP_SETTINGS")}
										/>
									}
								/>
							} */}
                            {hasAccess("TAGIDA_APP_SETTINGS", "view") && (
                                <Route
                                    path=""
                                    element={
                                        <AllAppSettings
                                            selectedOrg={globalReducer.selectedOrg}
                                            permissions={getPermissions("TAGIDA_APP_SETTINGS")}
                                        />
                                    }
                                />
                            )}
                        </Route>

                        {/* AppSettings Routes ===> ModuleCode: TAGIDA_APP_SETTINGS ; ApplicationCode: APP_TAGID_ADMIN */}
                        <Route path="api_keys" element={<Outlet />}>
                            {(hasAccess("TAGIDA_API_KEYS", "edit") || hasAccess("TAGIDA_API_KEYS", "view")) && (
                                <Route
                                    path="edit/:id"
                                    element={
                                        <EditApiKeys
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={false}
                                            permissions={getPermissions("TAGIDA_API_KEYS")}
                                        />
                                    }
                                />
                            )}
                            {hasAccess("TAGIDA_API_KEYS", "add") && (
                                <Route
                                    path="add"
                                    element={
                                        <EditApiKeys
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={true}
                                            permissions={getPermissions("TAGIDA_API_KEYS")}
                                        />
                                    }
                                />
                            )}
                            {hasAccess("TAGIDA_API_KEYS", "view") && (
                                <Route
                                    path=""
                                    element={
                                        <AllApiKeys
                                            selectedOrg={globalReducer.selectedOrg}
                                            permissions={getPermissions("TAGIDA_API_KEYS")}
                                        />
                                    }
                                />
                            )}
                        </Route>

                        {/* Panel Routes ===> ModuleCode: TAGIDA_PANEL ; ApplicationCode: APP_CMS_ADMIN */}
                        <Route path="panel" element={<Outlet />}>
                            {(hasAccess("TAGIDA_PANEL", "edit") || hasAccess("TAGIDA_PANEL", "view")) && (
                                <Route
                                    path="edit/:id"
                                    element={
                                        <EditPanel
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={false}
                                            permissions={getPermissions("TAGIDA_PANEL")}
                                        />
                                    }
                                />
                            )}
                            {hasAccess("TAGIDA_PANEL", "add") && (
                                <Route
                                    path="add"
                                    element={
                                        <EditPanel
                                            selectedOrg={globalReducer.selectedOrg}
                                            isAddNew={true}
                                            permissions={getPermissions("TAGIDA_PANEL")}
                                        />
                                    }
                                />
                            )}
                            {hasAccess("TAGIDA_PANEL", "view") && (
                                <Route
                                    path=""
                                    element={
                                        <AllPanels
                                            selectedOrg={globalReducer.selectedOrg}
                                            permissions={getPermissions("TAGIDA_PANEL")}
                                        />
                                    }
                                />
                            )}
                        </Route>
                    </Route>
                    <Route path="tools" element={<Outlet />}>
                        {hasAccess("TAGIDA_PRINTING", "view") && (
                            <Route
                                path="printing"
                                element={
                                    <Printing
                                        selectedOrg={globalReducer.selectedOrg}
                                        permissions={getPermissions("TAGIDA_PRINTING")}
                                    />
                                }
                            />
                        )}

                        {hasAccess("TAGIDA_FILE_UPLOAD", "view") && (
                            <Route
                                path="file-upload"
                                element={
                                    <FileUpload
                                        selectedOrg={globalReducer.selectedOrg}
                                        permissions={getPermissions("TAGIDA_FILE_UPLOAD")}
                                    />
                                }
                            />
                        )}

                        <Route path="encoding" element={<Outlet />}>
                            <Route path="sessions" element={<Outlet />}>
                                {(hasAccess("TAGIDA_ENCODING", "edit") || hasAccess("TAGIDA_ENCODING", "view")) && (
                                    <Route
                                        path="edit/:id"
                                        element={
                                            <EditEncodingSession
                                                selectedOrg={globalReducer.selectedOrg}
                                                isAddNew={false}
                                                permissions={getPermissions("TAGIDA_ENCODING")}
                                            />
                                        }
                                    />
                                )}
                                {hasAccess("TAGIDA_ENCODING", "add") && (
                                    <Route
                                        path="add"
                                        element={
                                            <EditEncodingSession
                                                selectedOrg={globalReducer.selectedOrg}
                                                isAddNew={true}
                                                permissions={getPermissions("TAGIDA_ENCODING")}
                                            />
                                        }
                                    />
                                )}
                                {hasAccess("TAGIDA_ENCODING", "view") && (
                                    <Route
                                        path=""
                                        element={
                                            <EncodingSessionsList
                                                selectedOrg={globalReducer.selectedOrg}
                                                permissions={getPermissions("TAGIDA_ENCODING")}
                                            />
                                        }
                                    />
                                )}
                            </Route>
                        </Route>

                        <Route path="epc-generation" element={<Outlet />}>
                            <Route path="sessions" element={<Outlet />}>
                                {(hasAccess("TAGIDA_EPC_GENERATION", "edit") || hasAccess("TAGIDA_EPC_GENERATION", "view")) && (
                                    <Route
                                        path="edit/:id"
                                        element={
                                            <EditEPCGenerationSession
                                                selectedOrg={globalReducer.selectedOrg}
                                                isAddNew={false}
                                                permissions={getPermissions("TAGIDA_EPC_GENERATION")}
                                            />
                                        }
                                    />
                                )}
                                {hasAccess("TAGIDA_EPC_GENERATION", "add") && (
                                    <Route
                                        path="add"
                                        element={
                                            <EditEPCGenerationSession
                                                selectedOrg={globalReducer.selectedOrg}
                                                isAddNew={true}
                                                permissions={getPermissions("TAGIDA_EPC_GENERATION")}
                                            />
                                        }
                                    />
                                )}
                                {hasAccess("TAGIDA_EPC_GENERATION", "view") && (
                                    <Route
                                        path=""
                                        element={
                                            <EPCGenerationSessionsList
                                                selectedOrg={globalReducer.selectedOrg}
                                                permissions={getPermissions("TAGIDA_EPC_GENERATION")}
                                            />
                                        }
                                    />
                                )}
                            </Route>
                        </Route>

                        {(hasAccess("TAGIDA_CENTRAL_PRINTING", "edit") || hasAccess("TAGIDA_CENTRAL_PRINTING", "view")) && ( // will be changed once the permission is added
                            <Route
                                path="central-printing"
                                element={<Outlet />}
                            >
                                <Route
                                    path=""
                                    element={
                                        <CentralPrintingList
                                            selectedOrg={globalReducer.selectedOrg}
                                            permissions={getPermissions("TAGIDA_CENTRAL_PRINTING")}
                                        />
                                    }
                                />
                                <Route
                                    path="details"
                                    element={
                                        <CentralPrintingDetails
                                            selectedOrg={globalReducer.selectedOrg}
                                            permissions={getPermissions("TAGIDA_CENTRAL_PRINTING")}
                                        />
                                    }
                                />
                            </Route>
                        )}

                    </Route>
                    <Route path="dashboard" elesment={<Outlet />}>
                        {(hasAccess("TAGIDA_DASHBOARD", "edit") || hasAccess("TAGIDA_DASHBOARD", "view")) && (
                            <Route
                                path="global-reports"
                                element={
                                    <GlobalReports
                                        selectedOrg={globalReducer.selectedOrg}
                                        permissions={getPermissions("TAGIDA_DASHBOARD")}
                                    />
                                }
                            />
                        )}
                        {(hasAccess("TAGIDA_DASHBOARD", "edit") || hasAccess("TAGIDA_DASHBOARD", "view")) && (
                            <Route
                                path="store-reports"
                                element={
                                    <StoreReports
                                        selectedOrg={globalReducer.selectedOrg}
                                        permissions={getPermissions("TAGIDA_DASHBOARD")}
                                    />
                                }
                            />
                        )}
                        {(hasAccess("TAGIDA_DASHBOARD", "edit") || hasAccess("TAGIDA_DASHBOARD", "view")) && (
                            <Route
                                path="date-wise-stock-take"
                                element={
                                    <DateWiseStockTakeReport
                                        selectedOrg={globalReducer.selectedOrg}
                                        permissions={getPermissions("TAGIDA_DASHBOARD")}
                                    />
                                }
                            />
                        )}
                    </Route>
                    <Route path="inventory" element={<Outlet />}>
                        {(hasAccess("TAGIDA_INVENTORY", "edit") || hasAccess("TAGIDA_INVENTORY", "view")) && (
                            <Route path="inward-outward" element={<Outlet />}>
                                <Route
                                    path=":id"
                                    element={
                                        <InwardOutwardReport
                                            selectedOrg={globalReducer.selectedOrg}
                                            permissions={getPermissions("TAGIDA_REPORTS")}
                                        />
                                    }
                                />
                                <Route
                                    path=""
                                    element={
                                        <InwardOutwardList
                                            selectedOrg={globalReducer.selectedOrg}
                                            permissions={getPermissions("TAGIDA_INVENTORY")}
                                        />
                                    }
                                />
                            </Route>
                        )}
                        {(hasAccess("TAGIDA_INVENTORY", "edit") || hasAccess("TAGIDA_INVENTORY", "view")) && ( // will be changed once the permission is added
                            <Route path="stock-take" element={<Outlet />}>
                                <Route path="sessions" element={<Outlet />}>
                                    <Route
                                        path=":id"
                                        element={
                                            <OneSessionReport
                                                selectedOrg={globalReducer.selectedOrg}
                                                permissions={getPermissions("TAGIDA_REPORTS")}
                                            />
                                        }
                                    />
                                    <Route
                                        path=""
                                        element={
                                            <StockSessionsList
                                                selectedOrg={globalReducer.selectedOrg}
                                                permissions={getPermissions("TAGIDA_REPORTS")}
                                            />
                                        }
                                    />
                                </Route>
                                <Route
                                    path="date-wise-stock-take"
                                    element={
                                        <DateWiseStockTakeReport
                                            selectedOrg={globalReducer.selectedOrg}
                                            permissions={getPermissions("TAGIDA_REPORTS")}
                                        />
                                    }
                                />
                            </Route>
                        )}
                        {(hasAccess("TAGIDA_INVENTORY", "edit") || hasAccess("TAGIDA_INVENTORY", "view")) && ( // will be changed once the permission is added
                            <Route
                                path="product-master"
                                element={
                                    <ProductMasterTable
                                        selectedOrg={globalReducer.selectedOrg}
                                        permissions={getPermissions("TAGIDA_REPORTS")}
                                    />
                                }
                            />
                        )}
                        {(hasAccess("TAGIDA_INVENTORY", "edit") || hasAccess("TAGIDA_INVENTORY", "view")) && ( // will be changed once the permission is added
                            <Route
                                path="customer-stock"
                                element={
                                    <CustomerStockTable
                                        selectedOrg={globalReducer.selectedOrg}
                                        permissions={getPermissions("TAGIDA_REPORTS")}
                                    />
                                }
                            />
                        )}
                        {/* Inward Route ===> ModuleCode: TAGIDA_INWARD_OUTWARD ; */}
                        {/* <Route path="inward" element={<Outlet />}>
                            <Route
                                path=""
                                element={
                                    <InwardSessionsList
                                        selectedOrg={globalReducer.selectedOrg}
                                        permissions={getPermissions("TAGIDA_INWARD_OUTWARD")}
                                    />
                                }
                            />
                        </Route> */}
                        {/* Outward Route ===> ModuleCode: TAGIDA_INWARD_OUTWARD ; */}
                        {/* <Route path="outward" element={<Outlet />}>
                            <Route
                                path=""
                                element={
                                    <OutwardSessionsList
                                        selectedOrg={globalReducer.selectedOrg}
                                        permissions={getPermissions("TAGIDA_INWARD_OUTWARD")}
                                    />
                                }
                            />
                        </Route> */}
                    </Route>
                    <Route
                        path="asynchronous_reports"
                        element={
                            <AsynchronousReports
                                selectedOrg={globalReducer.selectedOrg}
                                permissions={getPermissions("TAGIDA_REPORTS")}
                            />
                        }
                    />
                    <Route
                        path="profile"
                        element={
                            <Profile
                                selectedOrg={globalReducer.selectedOrg}
                                permissions={getPermissions("TAGIDA_DASHBOARD")}
                            />
                        }
                    />


                    <Route path="*" element={<Result status="warning" title="Page Not Found" />} />
                </Route>
            </Routes>
        </div>
    );
}

export default App;
