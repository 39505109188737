import { Button, Checkbox, Form, Input, Popconfirm, Select, Space, Spin, Switch, Table, Tabs, Tag, Upload, message } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { InputWithLabel } from '../../Components/Components';
import SiteTreeSelectAuto from '../Components/SiteTreeSelectAuto';
import axios from 'axios';
import { ExportToExcelButton, exportToExcel } from '../../Components/ExportToExcel';
import SearchComponent from '../../Masters/GeneralComponents/SearchComponent';
import { Helmet } from 'react-helmet';
import { HiOutlineUpload } from 'react-icons/hi';
import useFilterOptions from '../../Components/useFilterOptions';
import moment from 'moment';
import enUS from 'antd/lib/calendar/locale/en_US';
import { capitalizeFirstLetter } from '../Components/Functions';
import { ProductCodeComponent } from '../Components/ProductCodeDetailsModal';

const statusColorMap = {
    pending_approval: "#ff9900",
    printed: "#3765d4",
    delivered: "#75a922",
    dispatch: "#75a922",
    received: "#75a922",
    approved: "#22a944",
    created: "#f76020",
    cancel: "#3C5B6F",
};

const removeUnderscore = (text) => {
    if (text == null) return text
    const stringWithSpaces = text.replaceAll("_", " ");
    return stringWithSpaces
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

const CentralPrintingDetails = ({
    selectedOrg = {},
    permissions = {},
    homePath = "/tools/central-printing" }) => {

    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [jobId, setJobId] = useState(searchParams.get("job_id"));
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(searchParams.get('tab') || 'details');
    const [jobStatus, setJobStatus] = useState([]);
    const [isSubmitData, setIsSubmitData] = useState(true);
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const changeActiveTab = (key) => {
        if (activeTab === "product_codes" && !isSubmitData) {
            setIsOpenAlert(true);
            return
        }
        setActiveTab(key);
        searchParams.set('tab', key);
        setSearchParams(searchParams, { replace: true });
    }
    const [errors, setErrors] = useState({});
    const [details, setDetails] = useState(
        {
            job_name: null,
            reference_number: null,
            reference_number2: "",
            reference_number3: "",
            status: null,
            is_approved: 0,
            created_time: null,
            updated_time: null,
            job_type_id: 0,
            type_name: null,
            site_name: null,
            site_id: null,
            username: null,
            job_id: 0,
            print_qty: 0
        }
    );

    const [selectOptions, setSelectOptions] = useState([
        {
            label: "Received",
            value: "received"
        }
    ])

    const setDetailsKey = (key, value) => {
        setDetails(d => ({
            ...d,
            [key]: value,
        }));

    }

    const changePath = () => {
        if (activeTab === "product_codes" && !isSubmitData) {
            setIsOpenAlert(true);
            return
        }
        navigate(homePath)
    }

    async function changeJobStatus() {
        setLoading(true);
        await axios.post(`/admin-api/approve_job?job_id=${jobId}`)
            .then(res => {
                message.success("Job Status Change");
                getDetails();
            })
            .catch(error => {
                if (error.response) {
                    message.error(error.response?.data?.detail);
                } else {
                    console.log(error);
                    message.error(error?.message);
                }
            })
        setLoading(false);
    }

    async function updateJobDetails() {
        setLoading(true);
        await axios.post(`/admin-api/update_printing_jobs`, details)
            .then(res => {
                message.success("Job details update successfully");
                getDetails();
            })
            .catch(error => {
                if (error.response) {
                    message.error(error.response?.data?.detail);
                } else {
                    console.log(error);
                    message.error(error?.message);
                }
            })
        setLoading(false);
    }

    async function cancelSession() {
        setLoading(true);

        await axios.post(`/admin-api/update_job_status`, {
            job_id: details.job_id,
            status: "cancel"
        })
            .then(res => {
                message.success("Job canceled successfully");
                getDetails();
            })
            .catch(error => {
                if (error.response) {
                    message.error(error.response?.data?.detail);
                } else {
                    console.log(error);
                    message.error(error?.message);
                }
            })
        setLoading(false);
    }

    const getDetails = async () => {
        setLoading(true);
        await axios.get(`/admin-api/job_info_by_job_id?job_id=${jobId}`)
            .then(res => {
                const data = res.data.data;
                setJobStatus(res.data?.job_status);
                setDetails(data);
                setSelectOptions((prevOptions) =>
                    prevOptions.some(option => option.value === data.status)
                        ? prevOptions // If status already exists, return previous options
                        : [
                            ...prevOptions,
                            {
                                label: data.status,
                                value: data.status,
                            },
                        ]
                )

            })
            .catch(err => {
                // If error status is 404, then redirect to home
                if (err.response?.status === 404) {
                    message.error(err.response?.data?.detail || 'Job not found');
                    navigate(homePath);
                    return;
                }

                navigate(homePath);
                console.log(err);
                message.error('Error fetching data');
            });
        setLoading(false);
    }

    useEffect(() => {
        getDetails();
    }, [activeTab, details.job_id])


    return (
        <div className='my-form-outer'>
            <div className='my-form-header'>
                <span className='my-form-title'>
                    Central Printing Details
                </span>
            </div>
            <div>
                <Tabs
                    tabBarExtraContent={
                        <div>
                            <Space>
                                <Button type={activeTab === "details" ? "default" : "danger"} onClick={() => changePath()}>
                                    Back
                                </Button>
                                {(activeTab === "details" && permissions?.edit) && <Space>
                                    <Button type='primary' onClick={() => updateJobDetails()}>
                                        Update
                                    </Button>
                                    {details?.status !== "cancel" && <Popconfirm placement='bottomLeft' title="Are you sure to cancel session ?"
                                        onConfirm={() => cancelSession()}>
                                        <Button type='danger'>
                                            Cancel Session
                                        </Button>
                                    </Popconfirm>}
                                </Space>}
                            </Space>
                        </div>
                    }
                    activeKey={activeTab}
                    onChange={changeActiveTab}
                >
                    <TabPane className="my-form-tabpane-outer" tab="Job Details" key="details">
                        <Spin spinning={loading}>
                            <div className="my-form-multiple-inline-input">
                                <InputWithLabel label="Job Name" error={errors.job_name} reqMark={true}>
                                    <Input
                                        value={details.job_name}
                                        onChange={(e) => setDetailsKey('job_name', e.target.value)}
                                        className="my-form-input"
                                        disabled
                                        status={errors?.job_name?.errors?.[0]?.msg && "error"}
                                    />
                                </InputWithLabel>

                                <InputWithLabel label="Job Id">
                                    <Input
                                        value={details.job_id || 0}
                                        disabled
                                        className="my-form-input"
                                    />
                                </InputWithLabel>

                                <InputWithLabel label="Job Type" error={errors.type_name}>
                                    <Select
                                        value={details.type_name}
                                        disabled
                                        onChange={(value) => setDetailsKey('type_name', value)}
                                        className="my-form-input"
                                        status={errors?.type_name?.errors?.[0]?.msg && "error"}
                                    >
                                    </Select>
                                </InputWithLabel>

                            </div>

                            <div className="my-form-multiple-inline-input" style={{ gap: '20px', margin: '10px 0px' }}>


                                <InputWithLabel label="Reference Number" error={errors.reference_number} reqMark={true}>
                                    <Input
                                        value={details.reference_number}
                                        onChange={(e) => setDetailsKey('reference_number', e.target.value)}
                                        className="my-form-input"
                                        disabled
                                        status={errors?.reference_number?.errors?.[0]?.msg && "error"}
                                    />
                                </InputWithLabel>

                                <InputWithLabel label="Reference Number 2" error={errors.reference_number2} reqMark={false}>
                                    <Input
                                        value={details.reference_number2}
                                        onChange={(e) => setDetailsKey('reference_number2', e.target.value)}
                                        className="my-form-input"
                                        disabled={!permissions?.reference2}
                                        status={errors?.reference_number2?.errors?.[0]?.msg && "error"}
                                    />
                                </InputWithLabel>

                                <InputWithLabel label="Reference Number 3" error={errors.reference_number3} reqMark={false}>
                                    <Input
                                        value={details.reference_number3}
                                        onChange={(e) => setDetailsKey('reference_number3', e.target.value)}
                                        className="my-form-input"
                                        disabled={!permissions?.reference3}
                                        status={errors?.reference_number3?.errors?.[0]?.msg && "error"}
                                    />
                                </InputWithLabel>
                            </div>

                            <div className="my-form-multiple-inline-input">
                                <SiteTreeSelectAuto
                                    selectedOrg={selectedOrg}
                                    value={details?.site_id || 0}
                                    setValue={(value) => setDetailsKey('site_id', value)}
                                    inputErrors={errors.site_id}
                                    reqMark={true}
                                    disabled
                                    initialUnselected={true}
                                />
                                <>
                                    <InputWithLabel label="Created By">
                                        <Input
                                            value={details.username}
                                            disabled
                                            className="my-form-input"
                                        />
                                    </InputWithLabel>
                                    <InputWithLabel label="Status">
                                        <Tag
                                            color={statusColorMap[details.status] || "#000000"}
                                            style={{ fontWeight: '700' }}
                                        >
                                            {removeUnderscore(details?.status)}
                                        </Tag>
                                    </InputWithLabel>
                                    {permissions?.approve && <InputWithLabel label="Approval / Revoke">
                                        <Switch
                                            onClick={() => changeJobStatus()}
                                            disabled={details?.status !== "created"}
                                            checked={details.is_approved == 1 ? true : false} />
                                    </InputWithLabel>}

                                    {permissions?.dispatch_status && <InputWithLabel label="Change Status">
                                        <Select
                                            defaultValue={details?.status}
                                            value={details.status}
                                            options={selectOptions}
                                            onChange={(value) => setDetailsKey('status', value)}
                                        />
                                    </InputWithLabel>}

                                </>
                            </div>

                        </Spin>
                    </TabPane>

                    <TabPane className="my-form-tabpane-outer" tab="Product codes" key="product_codes">
                        <ProductCodesTab
                            selectedOrg={selectedOrg}
                            jobDetails={details}
                            permissions={permissions}
                            setIsSubmitData={setIsSubmitData}
                            isOpenAlert={isOpenAlert}
                            setIsOpenAlert={setIsOpenAlert}
                            activeTab={activeTab}
                            jobId={jobId}
                            sessionDetails={details}
                        />
                    </TabPane>

                    <TabPane className="my-form-tabpane-outer" tab="Generated EPCs" key="generated_epc">
                        <EPCsTable
                            selectedOrg={selectedOrg}
                            permissions={permissions}
                            activeTab={activeTab}
                            jobId={jobId}
                            sessionDetails={details}
                        />
                    </TabPane>
                </Tabs>
            </div >
        </div >
    )
}

const ProductCodesTab = ({
    selectedOrg = {},
    permissions = {},
    jobDetails,
    setIsSubmitData,
    setIsOpenAlert,
    isOpenAlert,
    activeTab,
    jobId,
    sessionDetails = {},
}) => {
    const [masterState, setMasterState] = useState([]);

    // The below state is after the search is done
    const [data, setData] = useState([]);
    const [categoryColumns, setCategoryColumns] = useState([]);
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [productCode, setProductCode] = useState(null);

    const getData = async () => {
        setLoading(true);
        await axios.get(`/admin-api/uploaded_product_codes?job_id=${jobId}`)
            .then(res => {
                const data = res.data.data;
                const newColumns = res.data.columns;
                setCategoryColumns(newColumns);
                setMasterState(data);
                setData(data);
                setStatus(data[0]?.status)
                setIsOpenAlert(false);
                setIsSubmitData(true);
            })
            .catch(err => {
                setMasterState([]);
                setData([]);
                setCategoryColumns([]);
                console.log(err);
                message.error('Error fetching data');
            })
        setLoading(false);
    }

    async function generateEPC() {
        setLoading(true);
        await axios.get(`/admin-api/generated_epc_list?job_id=${jobId}`)
            .then(res => {
                message.success("EPC List Generated Successfully");

                const newColumns = res.data.columns;

                const fixedColumns = [
                    {
                        title: 'EPC',
                        dataIndex: 'epc',
                        key: 'epc',
                    },
                    {
                        title: 'BARCODE',
                        dataIndex: 'product_code',
                        key: 'product_code',
                    },
                    {
                        title: 'ITEM_CODE',
                        dataIndex: 'item_code',
                        key: 'item_code',
                    },
                ]

                const columns = [
                    ...fixedColumns,
                    ...newColumns?.map(c => {
                        return {
                            title: c.category_name?.toUpperCase(),
                            dataIndex: c.category_name,
                            key: c.category_name,
                        }
                    })
                ]

                var sheets = [{ sheetName: "Printing EPC List", data: res.data?.data, columns: columns }]
                var fileName = `Printing List- ${sessionDetails?.job_name} ${moment(Date()).format('Do MMM YYYY, HH:mm A')}`
                exportToExcel({ fileName, sheets })

                getData();
            })
            .catch(error => {
                if (error.response) {
                    message.error(error.response?.data?.detail);
                } else {
                    console.log(error);
                    message.error(error?.message);
                }
            })
        setLoading(false);
    }

    const insertProductCode = async () => {

        if (productCode == null || productCode?.length <= 0) {
            message.error("Product Code is required")
            return
        }

        setLoading(true);
        await axios.get(`/admin-api/printing_product_details?product_code=${productCode}`)
            .then(res => {
                setProductCode(null);
                const apiData = res.data.data;
                setIsSubmitData(false);
                // Check if the product already exists in data
                const existingProductIndex = data.findIndex(item => item.product_code === apiData.product_code);
                const newData = [...data];

                // If product exists, update its quantity; otherwise, add it to the data array
                if (existingProductIndex !== -1) {
                    newData[existingProductIndex] = {
                        ...newData[existingProductIndex],
                        qty: newData[existingProductIndex].qty + 1
                    };
                    setData(newData);
                } else {
                    const updateData = {
                        ...apiData,
                        product_code: apiData.product_code,
                        qty: 1,
                        encoded_qty: 0
                    };
                    setData(prev => [...prev, updateData]);
                }
            })
            .catch(error => {
                setProductCode(null);
                if (error.response) {
                    message.error(error.response?.data?.detail);
                } else {
                    console.log(error);
                    message.error(error?.message);
                }
            })
        setLoading(false);
    }

    const removeProductByCode = (productCodeToRemove) => {
        // Filter out the item with the specified product code
        const updatedData = data.filter(item => item.product_code !== productCodeToRemove);
        // Update the state with the filtered data array
        setData(updatedData);
    };

    const submitData = async () => {
        setLoading(true);

        const uploadData = {
            job_id: jobId,
            product_print_items: data
        }

        await axios
            .post(`/admin-api/submit_product_list`, uploadData)
            .then((res) => {
                message.success("Submit data successfully");
                setIsSubmitData(true);
                getData();
            })
            .catch((error) => {
                if (error.response) {
                    message.error(error.response?.data?.detail);
                } else {
                    console.log(error);
                    message.error(error?.message);
                }
            });
        setLoading(false);
    }

    useEffect(() => {
        if (activeTab === "product_codes") {
            getData();
        }
    }, [jobId, activeTab]);


    const fixedColumns = [
        {
            title: 'BARCODE',
            dataIndex: 'product_code',
            key: 'zone_name',
        },
        {
            title: 'Expected QTY',
            dataIndex: 'qty',
            key: 'qty',
        },
        {
            title: 'Encoded QTY',
            dataIndex: 'encoded_qty',
            key: 'encoded_qty',
        },
        {
            title: 'ITEM_CODE',
            dataIndex: 'item_code',
            key: 'item_code',
        },
    ]

    const columns = useMemo(() => [
        ...fixedColumns,
        ...categoryColumns?.map(c => {
            return {
                title: c.category_name?.toUpperCase(),
                dataIndex: c.category_name,
                key: c.category_name,
            }
        }),
        ...[
            {
                title: 'Action',
                dataIndex: 'action',
                hidden: permissions?.remove_btn,
                key: 'action',
                render: (text, record) => <Button onClick={() => removeProductByCode(record?.product_code)} danger>Remove</Button>
            }
        ].filter((item) => {
            if (item?.hidden) {
                return false;
            }
            return true;
        })
    ], [categoryColumns]);
    console.log(permissions?.generate_epc);
    return (
        <div className='my-form-outer'>
            <div>
                {/* using our own ExportToExcel with required column and passing empty data .... Which will download the template file */}
                {!jobDetails?.is_approved && <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    {permissions?.upload && <ExportToExcelButton
                        fileName={`Template - Central Printing`}
                        buttonName={'Download Template'}
                        sheets={[
                            {
                                data: [],
                                columns: [
                                    { title: 'product_code', dataIndex: 'product_code', key: 'product_code' },
                                    { title: 'qty', dataIndex: 'qty', key: 'qty' },
                                ]
                            }
                        ]}
                    />}

                    {permissions?.upload && <UploadExcelFile
                        disabled={status === "printed"}
                        url={`/dashboard/upload_file_for_job_epc_generation?job_id=${jobId}`}
                        style={{ marginLeft: '20px' }}
                        onUploadSuccess={() => { getData() }}
                    />}
                </div>}

                <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10, marginTop: 10, marginBottom: 20 }}>
                    {/* if status is printed then user not able to add product code */}
                    {!jobDetails?.is_approved && <div style={{ display: 'flex' }}>
                        {permissions?.add && <div style={{ display: 'flex' }}>
                            {status === "printed" || <Form disabled={status === "printed"} autoComplete='off'>
                                <Space.Compact>
                                    <Input
                                        className="my-form-input"
                                        value={productCode}
                                        allowClear
                                        placeholder='Add Product Code'
                                        onChange={(e) => setProductCode(e.target.value)}
                                    />
                                    <Button type='primary' htmlType='submit' onClick={insertProductCode}>Add</Button>
                                </Space.Compact>
                            </Form>}

                            {status === "printed" ||
                                <Popconfirm title="Do you want submit the data ?"
                                    cancelText="Clear"
                                    okText="Submit"
                                    onCancel={() => getData()}
                                    open={isOpenAlert}
                                    onConfirm={() => submitData()}
                                >
                                    <Button disabled={status === "printed"}
                                        style={{ marginLeft: '10px', marginRight: '10px' }}
                                        type='danger'
                                        onClick={() => setIsOpenAlert(true)}
                                        loading={loading}>
                                        Submit
                                    </Button>
                                </Popconfirm>
                            }
                        </div>}


                    </div>}
                    {(permissions?.generate_epc && jobDetails?.is_approved) ?
                        <Button disabled={data.length > 0 ? false : true}
                            loading={loading}
                            onClick={() => generateEPC()}>
                            Generate EPC
                        </Button> : ""}
                    <SearchComponent
                        masterState={masterState}
                        state={data}
                        setState={setData}
                        searchOptions={[
                            { keyName: 'product_code', label: 'Product Code' },
                            { keyName: 'sub_department', label: 'Sub Department' },
                            { keyName: 'division', label: 'Division' },
                            { keyName: 'section', label: 'Section' },
                            { keyName: 'department', label: 'Department' },
                            { keyName: 'color', label: 'Color' },
                            { keyName: 'size', label: 'Size' },
                        ]}
                        searchDebounceTime={300}
                    />

                </div>
            </div>

            <Table
                columns={columns}
                dataSource={data}
                loading={loading}
                size="small"
                pagination={{
                    position: ['bottomRight'],
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
                rowClassName={(record, index) => {
                    if (record.status === 'DONE') {
                        return 'encode-status-done';
                    }
                    else if (record.status === 'PENDING') {
                        return 'encode-status-pending';
                    }
                }}
            />
            <Helmet>
                <style>{`
                    .encode-status-done {
                        background-color: #73ee62;
                    }
                    .encode-status-pending {
                        background-color: #ffa39e;
                    }
                `}</style>
            </Helmet>
        </div>
    )
}

const UploadExcelFile = ({
    url,
    style = {},
    onUploadSuccess = () => { },
}) => {
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);

    const uploadFile = async () => {
        const formData = new FormData();
        formData.append('file', fileList[0].originFileObj);

        setUploading(true);
        await axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(res => {
                message.success('File uploaded successfully');
                setFileList([]);
                onUploadSuccess();
            })
            .catch(err => {
                console.log(err);
                try {
                    message.error(err.response.data.detail);
                    setFileList([]);
                } catch (error) {
                    message.error('Error uploading file');
                }
            })
        setUploading(false);
    }

    const props = {
        onRemove: file => {
            setFileList(null);
        },
        beforeUpload: file => {
            setFileList([file]);
            return false;
        },
        fileList,
    };

    return (
        <span style={{ display: 'inline-flex', ...style }}>
            <Upload
                name="xlsx_file"
                accept=".xlsx"
                // maxCount={1}
                fileList={fileList}
                onPreview={(file) => {
                    // console.log("fileUrl", file.url);
                }}
                onChange={({ fileList }) => {
                    setFileList(fileList);
                    console.log("fileList", fileList);
                }}
                beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                style={{ display: 'flex' }}
            // showUploadList={false}
            >
                <Button disabled={fileList.length > 0 || uploading} loading={uploading}
                    icon={<HiOutlineUpload />}
                    // To take width 100% of parent element
                    style={{ borderRadius: '4px 0px 0px 4px', width: '100%', display: 'inline-flex', alignItems: 'center' }}
                >
                    {fileList.length > 0 ? fileList[0].name : 'Add File'}
                </Button>
            </Upload>
            <Button
                onClick={() => { uploadFile() }}
                loading={uploading}
                disabled={fileList.length === 0 || uploading}
                style={{ borderRadius: '0px 4px 4px 0px' }}
                type="primary"
            >
                Upload
            </Button>
        </span>
    )
}

const EPCsTable = ({
    selectedOrg = {},
    permissions = {},
    activeTab,
    jobId,
    sessionDetails = {},
}) => {
    const [masterState, setMasterState] = useState([]);

    // The below state is after the search is done
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [categoryColumns, setCategoryColumns] = useState([]);

    const filterableColumns = useMemo(() => ["status"], []);
    const [filterOptions, setFilterOptions] = useFilterOptions(masterState, filterableColumns);

    const getData = async () => {
        setLoading(true);
        await axios.get(`/admin-api/generated_epcs?job_id=${jobId}`)
            .then(res => {
                const data = res.data.data;
                const newColumns = res.data.columns;

                setMasterState(data);
                setData(data);
                setCategoryColumns(newColumns);
            })
            .catch(err => {
                setMasterState([]);
                setData([]);
                setCategoryColumns([]);
                console.log(err);
                message.error('Epc\'s data not found');
            })
        setLoading(false);
    }

    useEffect(() => {
        if (activeTab === "generated_epc") {
            getData();
        }
    }, [jobId, activeTab]);

    const fixedColumns = [
        {
            title: 'EPC',
            dataIndex: 'epc',
            key: 'epc',
        },
        {
            title: 'BARCODE',
            dataIndex: 'product_code',
            key: 'product_code',
        },
        {
            title: 'ITEM_CODE',
            dataIndex: 'item_code',
            key: 'item_code',
        },
    ];


    const columns = useMemo(() => [
        ...fixedColumns,
        ...categoryColumns.map(c => {
            return {
                title: c.category_name?.toUpperCase(),
                // title: capitalizeFirstLetter(c.category_name),
                dataIndex: c.category_name,
                key: c.category_name,
                filters: filterOptions[c.category_name],
                onFilter: (value, record) => record[c.category_name] == value,
                filterSearch: true,
            }
        }),
    ], [categoryColumns, filterOptions]);


    return (
        <div className='my-form-outer'>
            <div className='my-form-header'>
                <div>
                    {/* <ExportToExcel
                        data={masterState}
                        columns={columns}
                        fileName={`Session ${sessionId} - Raw EPCs`}
                    /> */}
                    <span className='my-form-title' style={{ textDecoration: 'none', fontSize: '16px' }}>
                        Printed Tags
                    </span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                    <SearchComponent
                        masterState={masterState}
                        state={data}
                        setState={setData}
                        searchOptions={[
                            { keyName: 'serial_number', label: 'Serial Number' },
                            { keyName: 'epc', label: 'EPC' },
                            { keyName: 'product_code', label: 'Product Code' },
                        ]}
                        searchDebounceTime={500}
                    />
                    {permissions?.print && <ExportToExcelButton
                        fileName={`Printed Tags List ${moment(Date()).format('Do MMM YYYY, HH:mm A')}`}
                        sheets={[{ sheetName: "Printed Tag List", data: data, columns: columns }]}
                    />}
                </div>
            </div>
            <Table
                columns={columns}
                dataSource={data}
                loading={loading}
                size="small"
                pagination={{
                    position: ['bottomRight'],
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
            />
        </div>
    )


}

export default CentralPrintingDetails