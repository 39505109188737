import { message, Select } from 'antd';
import TreeSelect, { TreeNode } from 'antd/lib/tree-select';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ar_logoutUser, ar_setUserScopes } from '../Redux/Actions/AuthActions';
import { gr_setOrgOptions, gr_setOrgsLoading, gr_setSelectedOrg, gr_setSelectedSite, gr_setSiteOptions, gr_setSitesLoading } from '../Redux/Actions/GlobalActions';
import { InputWithLabel } from './Components';
import MyPortal from './MyPortal';

export default function OrgSelector({
    labelInline=true,
    labelDivStyle={width: '300px'}
}) {
    const globalReducer = useSelector(state => state.globalReducer);
    const dispatch = useDispatch();
    let [searchParams, setSearchParams] = useSearchParams();
    let location = useLocation();

    // Add orgid in params on route change 
    useEffect(() => {
        if(!globalReducer.selectedOrg.orgId) {
            return;
        }
        searchParams.set("orgId", globalReducer.selectedOrg.orgId);
        // searchParams.set("siteId", globalReducer.selectedSite?.site_id);
        setSearchParams(searchParams, {
            replace: true,
        });
    }, [location.pathname, globalReducer.selectedOrg, globalReducer.selectedSite]);

    const handleSelectOrgChange = (value) => {
        var org = JSON.parse(value);
        dispatch(gr_setSelectedOrg(org));
    }

    const changeOrgKeys = (orgs, paramOrgId) => {       // orgs is a list of jsons
        return orgs.map(org => {
            if(org.o_id === paramOrgId){
                // console.log("org.o_id === paramOrgId", org.o_id, paramOrgId);
                dispatch(gr_setSelectedOrg({
                    orgId: org.o_id,
                    orgName: org.o_name,
                    orgCode: org.o_code,
                    orgTypeId: org.o_type_id,
                }));
            }

            return {
                orgId: org.o_id,
                orgName: org.o_name,
                orgCode: org.o_code,
                orgTypeId: org.o_type_id,
                children: org.children ? changeOrgKeys(org.children, paramOrgId) : [],
            }
        });
    }


    const [orgTreeOptions, setOrgTreeOptions] = useState([]);
    const [orgTreeNodeComponent, setOrgTreeNodeComponent] = useState(<></>);

    const createTreeNodes = (orgs) => {
        return orgs.map (org => (
            <TreeNode
                title={`${org.orgCode} / ${org.orgName}`}
                // key={org.orgId} 
                value={JSON.stringify({
                    orgId: org.orgId,
                    orgName: org.orgName,
                    orgCode: org.orgCode,
                    orgTypeId: org.orgTypeId,
                })}
            >
                {/* {org.children.map(c => createTreeNodes(c))} */}
                {org.children && createTreeNodes(org.children)}
            </TreeNode>
        ));
    }

    const getAllOrgs = async() => {
        dispatch(gr_setOrgsLoading(true));
        // dispatch(gr_setSitesLoading(true));

        await axios.get("/admin-api/all_org_tree")
            .then(res => {
                const data = res.data.data;
                
                const userScopes = res.data.user_scopes;
                dispatch(ar_setUserScopes(userScopes));
                
                const paramOrgId = parseInt(searchParams.get('orgId'));
            
                // data is array of orgs
                var myTree = changeOrgKeys(data, paramOrgId);
                // console.log("myTree", myTree);
                setOrgTreeOptions(myTree);
                setOrgTreeNodeComponent(createTreeNodes(myTree));
                
                dispatch(gr_setOrgOptions(myTree));
            })
            .catch(err => {
                console.log(err);
                try {
                    if(err.response.status === 401) {
                        dispatch(ar_logoutUser());
                    }
                } catch (error) {
                    message.error("Something went wrong! Refresh!");
                }
            })

        dispatch(gr_setOrgsLoading(false));
    }

    
    // This useEffect Takes care that the default org is selected when there is no org in the url
    useEffect(() => {
        if(orgTreeOptions.length > 0 && !globalReducer.selectedOrg.orgId) {
            dispatch(gr_setSelectedOrg(
                {
                    orgId: orgTreeOptions[0].orgId,
                    orgName: orgTreeOptions[0].orgName,
                    orgCode: orgTreeOptions[0].orgCode,
                    orgTypeId: orgTreeOptions[0].orgTypeId,
                }
            ));
        }
    } , [orgTreeOptions]);    

    useEffect(() => {
        getAllOrgs();      
    }, []);

    // useEffect(() => {
    //     console.log(orgTreeOptions);
    //     console.log(orgTreeNodeComponent);
    // } , [orgTreeOptions, orgTreeNodeComponent]);


    // const getSiteOptions = async () => {
    //     dispatch(gr_setSitesLoading(true));
    //     await axios.get('/admin-api/all_sites_by_o_id', {
    //         params: {
    //             o_id: globalReducer.selectedOrg.orgId,
    //             give_all_areas_regions: false,
    //         }
    //     })
    //         .then(res => {
    //             const data = res.data.data;

    //             const paramSiteId = parseInt(searchParams.get('siteId'));
    //             dispatch(gr_setSiteOptions(data));
    //             const site = data.find(site => site.site_id === paramSiteId);
    //             if(site) {
    //                 dispatch(gr_setSelectedSite(site));
    //             } else {
    //                 dispatch(gr_setSelectedSite(data[0] || {}));
    //             }
    //         })
    //         .catch(err => {
    //             console.log(err);
    //             message.error("Error in fetching sites");
    //         });
    //     dispatch(gr_setSitesLoading(false));
    // }
    // const handleSiteChange = (siteId) => {
    //     const site = globalReducer.siteOptions.find(site => site.site_id === siteId);
    //     dispatch(gr_setSelectedSite(site));
    // }

    // useEffect(() => {
    //     if(globalReducer.selectedOrg.orgId) {
    //         getSiteOptions();
    //     }
    // }, [globalReducer.selectedOrg]);
    
    const showTreeLine = true;
    const showLeafIcon = false;

    return (
        <>
            <InputWithLabel label="Org" divStyle={labelDivStyle} labelColor="#fff" isInline={labelInline}>
                <TreeSelect 
                    // Disable if the path name includes "add" or "edit"
                    disabled={globalReducer.orgsLoading || location.pathname.includes("add") || location.pathname.includes("edit")}
                    loading={globalReducer.orgsLoading}
                    treeLine={showTreeLine && { showLeafIcon }}
                    // style={{ width: 300}} 
                    style={{ width: "100%", display: "inline-flex", alignItems: "center" }}
                    showSearch={true}
                    value={globalReducer.selectedOrg.orgName}
                    dropdownStyle={{ zIndex: 2000 }}
                    onChange={handleSelectOrgChange}
                    // suffixIcon={<LockOutlinedIcon />}
                    // switcherIcon={<LockOutlinedIcon />}
                    treeDefaultExpandAll={true}
                >
                    {orgTreeNodeComponent}
                </TreeSelect>
            </InputWithLabel>   

            {/* {
                globalReducer.selectedOrg.orgId && globalReducer.showSiteSelector && (
                    <MyPortal id="navbar-selector-portal">
                        <InputWithLabel label="Site" labelColor="#fff" isInline={true}>
                            <Select
                                // style={{ width: 300}}
                                style={{ width: 300, display: "inline-flex", alignItems: "center" }}
                                loading={globalReducer.sitesLoading}
                                value={globalReducer?.selectedSite?.site_name}
                                onChange={handleSiteChange}
                            >
                                {
                                    globalReducer.siteOptions.map(site => (
                                        <Select.Option key={site.site_id} value={site.site_id}>{site?.site_name}</Select.Option>
                                    ))
                                }
                            </Select>
                        </InputWithLabel>
                    </MyPortal>
                )
            }  */}
        </>

    )
}


/*
const changeOrgKeys = (org) => {
    if(org.o_id === paramOrgId){
        // console.log("org.o_id === paramOrgId", org.o_id, paramOrgId);
        dispatch(gr_setSelectedOrg({
            orgId: org.o_id,
            orgName: org.o_name,
            orgCode: org.o_code,
            orgTypeId: org.o_type_id,
        }));
    }

    return {
        orgId: org.o_id,
        orgName: org.o_name,
        orgCode: org.o_code,
        orgTypeId: org.o_type_id,
        children: org.children.map(c => changeOrgKeys(c)),
    }
}

        // await axios.get("/admin-api/all_org")
        //     .then(res => {
        //         var orgs = [];
        //         var selectOrg = {};
        //         var paramOrgId = searchParams.get('orgId');

        //         for(var org of res.data) {
        //             if(paramOrgId && org.o_id == paramOrgId) {
        //                 selectOrg = {
        //                     orgId: org.o_id,
        //                     orgName: org.o_name
        //                 };
        //             }

        //             orgs.push({
        //                 orgId: org.o_id,
        //                 orgName: org.o_name,
        //                 orgCode: org.o_code
        //             });
        //         }
                
        //         if(!selectOrg.orgId) {
        //             selectOrg = orgs[0];
        //         }

        //         dispatch(gr_setOrgOptions(orgs));
        //         // handleSelectOrgChange(JSON.stringify(selectOrg));
        //     })
        //     .catch(err => {
        //         console.log(err);
        //     });


*/